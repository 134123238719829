<template>
    <layout v-bind:currency="currency"
            v-bind:mobile-mode="mobileMode"
            v-bind:scrolled-down="scrolledDown">
        <template slot="header-line">
            <header-line
                    v-if="!!currency"
                    v-bind:currency="currency"
                    v-bind:visible-currency-detail="visibleCurrencyDetail"
                    v-on:close-currency="closeCurrency"
                    v-on:open-currency-detail="openCurrencyDetail"
            ></header-line>
        </template>

        <custom-scroll
                v-if="visibleContentList"
                class="content__col content__col_left"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>
            <content-list
                    ref="contentList"
                    v-bind:items="contentItems"
                    v-bind:mobile-mode="mobileMode"
                    v-bind:active-item="contentItem"
                    v-on:select-item="openContentItem"
                    v-on:load-more="contentListLoadMore"
            ></content-list>
        </custom-scroll>

        <custom-scroll
                v-if="visibleCurrencyDetail || visibleContentItem"
                class="content__col content__col_right"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>
            <currency-detail v-if="visibleCurrencyDetail"
                             v-bind:item="currency"
                             v-bind:mobile-mode="mobileMode"
                             v-bind:has-show-news-button="true"
                             v-on:show-news="openCurrencyNews"
                             v-on:close-currency="closeCurrency"
            ></currency-detail>
            <content-detail v-if="visibleContentItem"
                            v-on:content-close="closeContentItem"
                            v-on:content-update="updateContentItem"
                            v-bind:item="contentItem"
                            v-bind:mobile-mode="mobileMode"
            ></content-detail>
        </custom-scroll>
    </layout>
</template>
<script>
    import {mapActions} from 'vuex'
    import API from './../api'
    import PageMixin from "./../mixins/page"
    import ContentListMixin from "./../mixins/content-list"
    import LoaderMixin from "./../mixins/loader"
    import HeaderLine from "./../components/currency/header-line.vue"
    import CurrencyDetail from "./../components/currency/currency-detail.vue"
    import ContentList from "./../components/content/content-list.vue"
    import ContentDetail from "./../components/content/content-detail.vue"

    export default {
        mixins: [PageMixin, ContentListMixin, LoaderMixin],
        components: {
            HeaderLine,
            CurrencyDetail,
            ContentList,
            ContentDetail
        },
        computed: {
            visibleContentList () {
                return !!this.currency && (!this.mobileMode || (!this.visibleCurrencyDetail && !this.visibleContentItem));
            },
            visibleCurrencyDetail () {
                return !!this.currency && !this.visibleContentItem && (!this.mobileMode || !this.showNews);
            },
            visibleContentItem () {
                return !!this.contentItem
            }
        },
        data() {
            return {
                currency: null,
                showNews: false
            }
        },
        beforeRouteUpdate (to, from, next) {
            if (to.name == 'currency') {
                this.contentItem = null;
                if (to.params.currency != from.params.currency) {
                    this.contentItems = [];
                    this.currencyItemLoad({slug: to.params.currency});
                }
            }
            next();
        },
        methods: {
            ...mapActions('portfolioData', [
                'setTableItems',
            ]),
            pageReady() {
                this.currencyItemLoad({slug: this.$router.currentRoute.params.currency});
                if (this.$router.currentRoute.name == 'currency_content_item') {
                    this.contentItemLoad({slug: this.$router.currentRoute.params.slug});
                }
            },
            currencyItemLoad(params) {
                this.contentListLoading = true;
                this.loadingStart();
                API.currency.item(params).then((response) => {
                    // set currency
                    this.currency = response.data.currency;

                    // set currency news
                    if (response.data.items.length > 0) {
                        this.contentItems = this.contentItems.concat(response.data.items);
                        this.contentLoadMoreLoaded();
                    } else {
                        this.contentLoadMoreComplete();
                    }

                    if (response.data.portfolioTable) {
                        this.setTableItems(response.data.portfolioTable);
                    }
                }).catch(() => {
                    this.contentLoadMoreComplete();
                }).finally(() => {
                    this.loadingStop();
                    this.contentListLoading = false;
                });
            },
            getContentListRequestParams() {
                return {
                    currencies: this.currency ? [this.currency.id] : []
                }
            },
            closeCurrency() {
                this.contentItem = null;
                if (this.routeFrom != null && this.routeFrom.name == 'home') {
                    this.$router.go(-1);
                } else {
                    this.$router.replace({name: 'home'});
                }
            },
            openContentItem(item) {
                this.contentItem = item;
                let to = {
                    name: "currency_content_item",
                    params: {
                        currency: this.currency.slug,
                        slug: this.contentItem.slug
                    }
                };
                if (this.$router.currentRoute.name == 'currency_content_item') {
                    this.$router.replace(to);
                } else {
                    this.$router.push(to);
                }
            },
            closeContentItem() {
                this.contentItem = null;
                if (this.routeFrom != null && this.routeFrom.name == 'currency') {
                    this.$router.go(-1);
                } else {
                    this.$router.replace({
                        name: 'currency',
                        params: {currency: this.currency ? this.currency.slug : null}
                    });
                }
            },
            openCurrencyNews() {
                this.showNews = true;
            },
            openCurrencyDetail() {
                this.showNews = false;
            }
        }
    }
</script>