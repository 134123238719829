export default function () {
    this.content = {
        item: ({slug}) => {
            return this.post({
                to: {name: 'api_content_data', params: {slug: slug}},
                payload: {}
            });
        },
        list: (payload) => {
            return this.post({
                to: {name: 'api_content_list'},
                payload: payload,
            });
        },
        listCommon: (payload) => {
            return this.post({
                to: {name: 'api_content_list_common'},
                payload: payload,
            });
        },
        vote: ({contentId, voteType}) => {
            return this.post({
                to: {name: 'api_content_vote'},
                payload: {
                    content_id: contentId,
                    vote_type: voteType,
                },
            });
        },
    }
}