import ViewCurrency from "./../../views/currency.vue";

export default [
    {
        path: '/currency/:currency',
        name: 'currency',
        component: ViewCurrency,
        children: [
            {
                path: 'post/:slug',
                name: 'currency_content_item',
                component: ViewCurrency,
            },
        ]
    },
    {
        path: '/api/currency/search',
        name: 'api_currency_search',
    },
    {
        path: '/api/currency/:slug',
        name: 'api_currency_data',
    },
    {
        path: '/chart_data/:currency/:from/:to/',
        name: 'api_currency_chart_data',
    },
    {
        path: '/chart_data/:currency/',
        name: 'api_currency_chart_data_all',
    },
];