<template>
    <layout v-bind:mobile-mode="mobileMode">
        <template slot="header-line">
            <header-line></header-line>
        </template>
        <custom-scroll class="content__col content__col_left"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <form-settings ref="formSettings" v-on:logout="onLogout"></form-settings>
        </custom-scroll>
        <custom-scroll class="content__col content__col_right"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <form-providers ref="formProviders"></form-providers>
        </custom-scroll>
    </layout>
</template>
<script>
    import API from './../api'
    import {mapActions} from 'vuex'
    import PageMixin from "./../mixins/page"
    import HeaderLine from "./../components/account/header-line.vue"
    import FormSettings from "./../components/account/form-settings.vue"
    import FormProviders from "./../components/account/form-providers.vue"

    export default {
        mixins: [PageMixin],
        components: {
            HeaderLine,
            FormSettings,
            FormProviders
        },
        methods: {
            ...mapActions([
                'clearStore'
            ]),
            pageReady() {
                this.loadAccountData();
            },
            onLogout() {
                // request logout in server
                API.account.logOut().then(() => {
                    // clear store
                    this.clearStore();
                    // pause and redirect home
                    setTimeout(() => {
                        this.$router.push({name: 'home'});
                    }, 500);
                });
            },
            loadAccountData() {
                API.account.data().then((response) => {
                    if (response.data.user) {
                        let data = {};
                        data.username = response.data.user.name;
                        data.email = response.data.user.email;
                        data.emailVerified = response.data.user.email_verified;
                        data.accounts = response.data.user.accounts;
                        this.$refs.formSettings.setData(data);
                        this.$refs.formProviders.setData(data);
                    }
                });
            }
        }
    }
</script>