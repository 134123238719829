const items = [
    {value: 1, name: 'global.News'},
    {value: 3, name: 'global.Twitter'},
    {value: 4, name: 'global.Reddit'},
    {value: 5, name: 'global.Facebook'},
    {value: 6, name: 'global.Instagram'}
];

export default {
    all() {
        return items;
    }
}