import {
    COUNTRIES_SET,
} from "./../mutation-types";

const state = {
    countries: [],
};

const getters = {

};

const mutations = {
    [COUNTRIES_SET] (state, val) {
        state.countries = val
    },
};

const actions = {
    setCountries (store, val) {
        store.commit(COUNTRIES_SET, val);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}