<template>
    <div class="coins__search">
        <div class="input-text">
            <input type="text" v-model="currencyTableQueryModel"/>

            <div v-if="currencyTableQuery.length > 0"
                 v-on:click="currencyTableResetQuery"
                 class="input-text__clear"></div>

            <svg v-else xmlns:xlink="http://www.w3.org/1999/xlink">
                <use xlink:href="#icon-lens"></use>
            </svg>
        </div>
    </div>
</template>
<script>
    import CurrencyFavoritesMixin from './../../mixins/currency-favorites'
    import CurrencyTableStoreMixin from './../../mixins/currency-table-store'

    export default {
        name: "currency-table-filter",
        mixins: [CurrencyFavoritesMixin, CurrencyTableStoreMixin],
    }
</script>