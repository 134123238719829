<template>
    <filter-select-component
            name="vote-type"
            v-bind:label="label"
            v-bind:items="items"
            v-model="selected"
            v-on:dismiss-all="clearVoteType"
    ></filter-select-component>
</template>
<script>
    import FilterSelectComponent from './filter-select-component.vue'
    import DirectoryVoteTypes from './../../store/directory/vote-types'

    export default {
        name: 'filter-select-vote-type',
        components: {
            FilterSelectComponent,
        },
        props: {
            voteTypeSelected: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                items: DirectoryVoteTypes.all(),
            }
        },
        computed: {
            selected: {
                get() {
                    return this.voteTypeSelected;
                },
                set(val) {
                    this.setVoteType(val);
                }
            },
            label () {
                return this.$t('global.NewsVoteType');
            }
        },
        methods: {
            setVoteType (val) {
                this.$emit('select', val);
            },
            clearVoteType () {
                this.$emit('clear');
            }
        }
    }
</script>