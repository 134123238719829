import {mapState, mapActions} from 'vuex'

export default {
    computed: {
        ...mapState('currencyTable', [
            'currencyTableShowFavorite',
            'currencyTableQuery',
            'currencyTableOrder',
            'currencyTableDesk'
        ]),
        currencyTableQueryModel: {
            get () {
                return this.currencyTableQuery
            },
            set (value) {
                this.currencyTableSetQuery(value);
            }
        },
        currencyTableOrderModel: {
            get () {
                return this.currencyTableOrder
            },
            set (value) {
                this.currencyTableSetOrder(value);
            }
        },
        currencyTableDeskModel: {
            get () {
                return this.currencyTableDesk
            },
            set (value) {
                this.currencyTableSetDesk(value);
            }
        },
    },
    watch: {
        currencyTableShowFavorite () {
            this.$emit('update');
        },
        currencyTableQuery () {
            this.$emit('update');
        },
        currencyTableOrder () {
            this.$emit('update');
        },
        currencyTableDesk () {
            this.$emit('update');
        }
    },
    methods: {
        ...mapActions('currencyTable', [
            'currencyTableToggleShowFavorite',
            'currencyTableSetQuery',
            'currencyTableSetOrder',
            'currencyTableSetDesk',
        ]),
        currencyTableResetQuery() {
            this.currencyTableSetQuery("");
        }
    }
}