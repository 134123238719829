<template>
    <layout v-bind:mobile-mode="mobileMode">
        <custom-scroll class="content__col content__col_wide"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <div class="field-set">
                <div class="field-set__row">
                    <div class="field-set__title">{{ statusMessage }}</div>
                </div>
            </div>
        </custom-scroll>
    </layout>
</template>
<script>
    import PageMixin from "./../mixins/page"

    export default {
        mixins: [PageMixin],
        data() {
            return {
                status: null
            }
        },
        computed: {
            statusMessage() {
                return (this.status == "success")
                    ? this.$t("auth.MessageVerifySuccess")
                    : this.$t("errors.SystemError");
            }
        },
        methods: {
            pageReady() {
                this.status = (this.$router.currentRoute.path == '/auth/verify/success') ?
                    'success' : 'failed';
            }
        }
    }
</script>