import {
    PORTFOLIO_DATA_SET_TABLE_ITEMS,
    PORTFOLIO_DATA_SET_HISTORY_ITEMS,
} from "./../../store/mutation-types";

const state = {
    tableItems: [],
    historyItems: [],
};

const getters = {};

const mutations = {
    [PORTFOLIO_DATA_SET_TABLE_ITEMS] (state, value) {
        state.tableItems = value;
    },
    [PORTFOLIO_DATA_SET_HISTORY_ITEMS] (state, value) {
        state.historyItems = value;
    }
};

const actions = {
    setTableItems (store, value) {
        let _items = [];
        if (value) {
            for (let i = 0; i < value.length; i++) {
                let item = value[i];
                _items.push({
                    amount: Number(item.amount),
                    change24hBTC: Number(item.change24hBTC),
                    change24hUSD: Number(item.change24hUSD),
                    currencyName: item.currencyName,
                    currencySymbol: item.currencySymbol,
                    currencyID: Number(item.currencyID),
                    id: Number(item.id),
                    priceBTC: Number(item.priceBTC),
                    priceUSD: Number(item.priceUSD),
                    title: item.title,
                    valueBTC: Number(item.valueBTC),
                    valueUSD: Number(item.valueUSD),
                });
            }
        }

        store.commit(PORTFOLIO_DATA_SET_TABLE_ITEMS, _items);
    },
    setHistoryItems (store, value) {
        store.commit(PORTFOLIO_DATA_SET_HISTORY_ITEMS, value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}