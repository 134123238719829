<template>
    <div class="content__col content__col_right" data-custom_scroll_y>
        <div class="field-set">
            <div class="field-set__row">
                <div class="field-set__title">{{ $t('auth.TitleCreateAccount') }}</div>
                <a class="button" href="#" v-on:click.prevent="onShowFormLogin">{{ $t('auth.LinkLogin') }}</a>
            </div>
            <div class="field-set__text">{{ $t('auth.NoteSignup') }}</div>
            <form class="field-set-form" action="#" method="post">

                <field-email
                        server-field-name="Email"
                        v-model="$v.email.$model"
                        v-on:input="formTouch"
                        v-bind:label="$t('auth.LabelEmail')"
                        v-bind:vuelidate-field="$v.email"
                        v-bind:form-errors="formErrors"
                        v-bind:form-dirty="formDirty"
                ></field-email>

                <field-password
                        server-field-name="Password"
                        v-model="$v.password.$model"
                        v-on:input="formTouch"
                        v-bind:label="$t('auth.LabelPassword')"
                        v-bind:vuelidate-field="$v.password"
                        v-bind:form-errors="formErrors"
                        v-bind:form-dirty="formDirty"
                ></field-password>

                <field-password
                        server-field-name="PasswordConfirm"
                        v-model="$v.passwordConfirm.$model"
                        v-on:input="formTouch"
                        v-bind:label="$t('auth.LabelPasswordConfirm')"
                        v-bind:vuelidate-field="$v.passwordConfirm"
                        v-bind:form-errors="formErrors"
                        v-bind:form-dirty="formDirty"
                ></field-password>

                <field-recaptcha
                        ref="formRecaptcha"
                        server-field-name="Recaptcha"
                        v-bind:vuelidate-field="$v.recaptcha"
                        v-bind:form-errors="formErrors"
                        v-on:verify="formRecaptchaOnVerify"
                        v-bind:form-dirty="formDirty"
                ></field-recaptcha>

                <button v-on:click.prevent="formSubmit" class="button">{{ $t('auth.SubmitCreateAccount') }}</button>
            </form>
            <div class="field-set-social">
                <div class="field-set__title">{{ $t('auth.TitleLoginWithSocialAccount') }}</div>
                <social-login-block></social-login-block>
            </div>
        </div>
    </div>
</template>
<script>
    import API from './../../api'
    import {mapGetters, mapActions} from 'vuex'
    import FormMixin from './../../mixins/form'
    import FieldEmail from './../ui/field-email.vue'
    import FieldPassword from './../ui/field-password.vue'
    import FieldRecaptcha from './../ui/field-recaptcha.vue'
    import SocialLoginBlock from './social-login-block.vue'
    import {required, email, minLength, sameAs} from 'vuelidate/lib/validators'

    export default {
        name: 'form-create-account',
        mixins: [FormMixin],
        components: {
            FieldEmail,
            FieldPassword,
            FieldRecaptcha,
            SocialLoginBlock
        },
        validations: {
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(5)
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            },
            recaptcha: {
            }
        },
        props: {
            mobileMode: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                email: '',
                password: '',
                passwordConfirm: '',
            }
        },
        computed: {
            ...mapGetters('locale', [
                'currentLocale'
            ]),
            formData() {
                return {
                    email: this.email,
                    password: this.password,
                    password_confirm: this.passwordConfirm,
                    recaptcha: this.recaptcha,
                    locale: this.currentLocale ? this.currentLocale.id : null
                }
            },
        },
        methods: {
            ...mapActions('auth', [
                'logIn'
            ]),
            formRequest () {
                return API.account.signUp(this.formData);
            },
            formProcessSuccessResponse() {
                this.logIn();
                this.$router.push({name: 'home'});
            },
            formProcessErrorResponse(error) {
                const response = error.response;
                if (response && response.status == 400) {
                    this.formErrors = response.data.errors;
                    this.notifyError(this.$t('errors.InvalidRequest'));
                } else {
                    this.notifyError(this.$t('errors.SystemError'));
                }
            },
            onShowFormLogin() {
                this.$emit('show-form-login');
            }
        }
    }
</script>