<template>
    <div class="news-list-item" v-bind:data-active="active">
        <a class="news-list-item__link"
           v-on:click.prevent="select"
           v-bind:href="item.original_url"></a>

        <time-ago v-bind:since="item.created_at"
                  v-bind:time-ago="item.time_ago"></time-ago>

        <svg v-if="item.is_trend"
             class="news-list-item__icon-trend"
             xmlns:xlink="http://www.w3.org/1999/xlink">
            <use xlink:href="#icon-trend"></use>
        </svg>

        <div class="news-list-item__body">

            <div class="news-list-item__title">
                <a v-if="country"
                   v-bind:href="country.link"
                   class="news-list-item__country"
                ><img v-bind:src="country.icon" /></a>

                {{ item.title }}
            </div>
            <div class="news-list-item__row">
                <div class="news-list-item__source">
                    <a :href="item.original_url"
                       v-bind:target="mobileMode ? '_self':'_blank'">{{ item.source.name | sourceLink | lowercase }}</a>
                </div>

                <vote-info class="user-rating user-rating_up"
                           v-if="item.votes.positive_count > 0"
                           v-bind:value-count="item.votes.positive_count"
                           v-bind:value-average="item.votes.positive_average"></vote-info>

                <vote-info class="user-rating user-rating_down"
                           v-if="item.votes.negative_count > 0"
                           v-bind:value-count="item.votes.negativeh_count"
                           v-bind:value-average="item.votes.negative_average"></vote-info>

                <vote-info class="user-rating user-rating_important"
                           v-if="item.votes.important_count > 0"
                           v-bind:value-count="item.votes.important_count"
                           v-bind:value-average="item.votes.important_average"></vote-info>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex'
    import RouterMixin from './../../mixins/router'
    import TimeAgo from './time-ago.vue'
    import VoteInfo from './vote-info.vue'

    export default {
        name: 'content-list-item',
        mixins: [RouterMixin],
        components: {
            TimeAgo,
            VoteInfo,
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            activeItem: {
                required: true
            },
            mobileMode: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            ...mapGetters('locale', [
                'currentLocale'
            ]),
            link () {
                return this.routeHref({name: 'content_item', params: {slug: this.item.slug}})
            },
            active () {
                return !!this.activeItem && this.activeItem.id == this.item.id;
            },
            country () {
                return this.item.country
                    ? {
                        icon: '/media/img/country/' + this.item.country.slug + '.png',
                        link: this.routeHref({name: 'country', params: {country: this.item.country.slug}})
                    }
                    : false;
            }
        },
        methods: {
            select() {
                this.$emit('select', this.item);
            }
        }
    }
</script>