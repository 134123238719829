<template>
    <div class="portfolio">
        <div class="portfolio__title">{{ title }}</div>

        <template v-if="items.length > 0">
            <portfolio-table-settings></portfolio-table-settings>

            <div class="table__wrap">

                <custom-scroll class="table__scroll"
                    v-bind:mobile-mode="mobileMode"
                    scroll-horizontal>
                    <table class="table">
                        <tr class="table__nowrap">
                            <portfolio-table-header-column>{{ $t('portfolio.Currency') }}</portfolio-table-header-column>

                            <portfolio-table-header-column
                                    v-if="tableVisibleAmount"
                                    v-bind:sort-column-name="tableSortColumnName"
                                    v-bind:sort-column-desc="tableSortColumnDesc"
                                    v-on:toggle-sort="toggleSort('amount')"
                                    column-name="amount">{{ $t('portfolio.Amount') }}
                            </portfolio-table-header-column>

                            <portfolio-table-header-column
                                    v-if="tableVisibleValueUsd"
                                    v-bind:sort-column-name="tableSortColumnName"
                                    v-bind:sort-column-desc="tableSortColumnDesc"
                                    v-on:toggle-sort="toggleSort('valueUSD')"
                                    column-name="valueUSD">{{ $t('portfolio.ValueUSD') }}
                            </portfolio-table-header-column>

                            <portfolio-table-header-column
                                    v-if="tableVisiblePriceUsd"
                                    v-bind:sort-column-name="tableSortColumnName"
                                    v-bind:sort-column-desc="tableSortColumnDesc"
                                    v-on:toggle-sort="toggleSort('priceUSD')"
                                    column-name="priceUSD">{{ $t('portfolio.PriceUSD') }}
                            </portfolio-table-header-column>

                            <portfolio-table-header-column
                                    v-if="tableVisibleChange24hUsd"
                                    v-bind:sort-column-name="tableSortColumnName"
                                    v-bind:sort-column-desc="tableSortColumnDesc"
                                    v-on:toggle-sort="toggleSort('change24hUSD')"
                                    column-name="change24hUSD">{{ $t('portfolio.Change24hUSD') }}
                            </portfolio-table-header-column>

                            <portfolio-table-header-column
                                    v-if="tableVisibleValueBtc"
                                    v-bind:sort-column-name="tableSortColumnName"
                                    v-bind:sort-column-desc="tableSortColumnDesc"
                                    v-on:toggle-sort="toggleSort('valueBTC')"
                                    column-name="valueBTC">{{ $t('portfolio.ValueBTC') }}
                            </portfolio-table-header-column>

                            <portfolio-table-header-column
                                    v-if="tableVisiblePriceBtc"
                                    v-bind:sort-column-name="tableSortColumnName"
                                    v-bind:sort-column-desc="tableSortColumnDesc"
                                    v-on:toggle-sort="toggleSort('priceBTC')"
                                    column-name="priceBTC">{{ $t('portfolio.PriceBTC') }}
                            </portfolio-table-header-column>

                            <portfolio-table-header-column
                                    v-if="tableVisibleChange24hBtc"
                                    v-bind:sort-column-name="tableSortColumnName"
                                    v-bind:sort-column-desc="tableSortColumnDesc"
                                    v-on:toggle-sort="toggleSort('change24hBTC')"
                                    column-name="change24hBTC">{{ $t('portfolio.Change24hBTC') }}
                            </portfolio-table-header-column>

                            <portfolio-table-header-column>{{ $t('portfolio.Label') }}</portfolio-table-header-column>
                        </tr>

                        <tr v-for="(item, index) in sortedItems" v-bind:key="index">
                            <portfolio-table-cell
                                    v-bind:active="isActive(item) && filterCurrency == null"
                                    v-bind:value="isActive(item) ? currencyFullName(editItem) : currencyFullName(item)"
                                    v-bind:input-value.sync="currencySearchQuery"
                                    v-bind:table-input-list-styles.sync="tableInputListStyles"
                                    v-bind:with-action-buttons="item.id > 0"
                                    v-on:delete="deletePortfolioItem(item.id)"
                                    v-on:edit="editPortfolioItem(item)"
                                    class="table__drag"
                            ></portfolio-table-cell>
                            <portfolio-table-cell
                                    v-if="tableVisibleAmount"
                                    v-bind:value="isActive(item) ? editCurrencyAmount : item.amount"
                                    v-bind:input-value.sync="editCurrencyAmount"
                                    v-bind:active="isActive(item)"
                            ></portfolio-table-cell>
                            <td v-if="tableVisibleValueUsd">{{ item.valueUSD | toCurrencySmart }}</td>
                            <td v-if="tableVisiblePriceUsd">{{ item.priceUSD | toCurrencySmart }}</td>
                            <td v-if="tableVisibleChange24hUsd"
                                v-bind:class="[{'table__color-green': item.change24hUSD > 0 }, {'table__color-red': item.change24hUSD < 0 }]">
                                {{ item.change24hUSD | toPercent | withSign }} %
                            </td>
                            <td v-if="tableVisibleValueBtc">{{ item.valueBTC | toCurrencySmart }}</td>
                            <td v-if="tableVisiblePriceBtc">{{ item.priceBTC | toCurrencySmart }}</td>
                            <td v-if="tableVisibleChange24hBtc"
                                v-bind:class="[{'table__color-green': item.change24hBTC > 0 }, {'table__color-red': item.change24hBTC < 0 }]">
                                {{ item.change24hBTC | toPercent | withSign }} %
                            </td>
                            <portfolio-table-cell
                                    v-bind:active="isActive(item)"
                                    v-bind:value="isActive(item) ? editCurrencyLabel : item.title"
                                    v-bind:input-value.sync="editCurrencyLabel"
                            ></portfolio-table-cell>
                        </tr>
                    </table>
                </custom-scroll>


                <div class="table-input-list"
                     v-bind:style="tableInputListStyles"
                     v-bind:data-active="currencySearchResult.length > 0">
                    <div v-for="(currency, index) in currencySearchResult"
                         v-bind:key="index"
                         class="table-input-list__item">
                        <a href="#" v-on:click.prevent="selectCurrency(currency.id)">
                            {{ currency.name + " / " + currency.symbol }}
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="table-settings">
                {{ $t('portfolio.NoRows') }}
            </div>
        </template>
        <div class="portfolio__row">
            <div class="portfolio__row-buttons">
                <template v-if="editItem == null">
                    <a v-on:click.prevent="addPortfolioItem()" class="button button_add" href="#add"><div class="button__icon"></div>{{ $t('portfolio.AddNewRow') }}</a>
                    <a v-if="filterCurrency == null"
                       v-on:click.prevent="onShowChart"
                       class="button"
                       href="#chart">{{ $t('portfolio.LinkShowChart') }}</a>
                </template>
                <template v-else>
                    <a v-on:click.prevent="savePortfolioItem"
                       class="button button_save"
                       href="#save">{{ $t('portfolio.SaveChanges') }}</a>
                    <a v-on:click.prevent="cancelPortfolioItem"
                       class="button button_cancel"
                       href="#save">{{ $t('portfolio.Cancel') }}</a>
                </template>
            </div>
            <portfolio-summary
                    v-if="visiblePortfolioSummary"
                    v-bind:items="items"></portfolio-summary>
        </div>
    </div>
</template>
<script>
    import API from './../../api'
    import {mapState, mapActions} from 'vuex'
    import loClone from "lodash/clone"
    import loFilter from "lodash/filter"
    import NotifyMixin from "./../../mixins/notify"
    import LoaderMixin from "./../../mixins/loader"
    import CustomScroll from "./../ui/custom-scroll.vue"
    import PortfolioTableHeaderColumn from "./portfolio-table-header-colum.vue"
    import PortfolioTableSettings from "./portfolio-table-settings.vue"
    import PortfolioTableCell from "./portfolio-table-cell.vue"
    import PortfolioSummary from "./portfolio-summary.vue"

    export default {
        name: 'portfolio-widget',
        mixins: [NotifyMixin, LoaderMixin],
        components: {
            PortfolioTableSettings,
            PortfolioTableHeaderColumn,
            PortfolioTableCell,
            PortfolioSummary,
            CustomScroll
        },
        props: {
            filterCurrency: {
                type: Object
            },
            mobileMode: {
                type: Boolean,
                required: true
            },
        },
        computed: {
            ...mapState('portfolioTable', [
                'tableVisibleAmount',
                'tableVisibleValueUsd',
                'tableVisiblePriceUsd',
                'tableVisibleChange24hUsd',
                'tableVisibleValueBtc',
                'tableVisiblePriceBtc',
                'tableVisibleChange24hBtc',
                'tableSortColumnName',
                'tableSortColumnDesc',
            ]),
            ...mapState('portfolioData', [
                'tableItems',
            ]),
            items () {
                return this.filterCurrency == null
                    ? this.tableItems
                    : loFilter(this.tableItems, ["currencyID", this.filterCurrency.id]);
            },
            visiblePortfolioSummary () {
                return this.items.length > 0 && this.filterCurrency == null;
            },
            title () {
                return this.filterCurrency != null
                    ? this.filterCurrency.symbol + " " + this.$t('portfolio.Portfolio')
                    : this.$t('portfolio.MyCryptoPortfolio');
            },
            sortedItems () {
                let sortColumnName = this.tableSortColumnName.length == ""
                    ? "id" : this.tableSortColumnName;
                let compareFn = this.tableSortColumnDesc
                    ? this.compareDescBy(sortColumnName)
                    : this.compareBy(sortColumnName);
                let res = this.items.slice();
                res.sort(compareFn);
                return res;
            },
            editCurrencyLabel: {
                get () {
                    return this.editItem != null ? this.editItem.title : "";
                },
                set (val) {
                    if (this.editItem != null) {
                        this.editItem.title = val;
                    }
                }
            },
            editCurrencyAmount: {
                get () {
                    return this.editItem != null ? this.editItem.amount : "";
                },
                set (val) {
                    if (this.editItem != null) {
                        this.editItem.amount = val;
                    }
                }
            }
        },
        data() {
            return {
                editItem: null,
                currencySearchQuery: "",
                currencySearchResult: [],
                tableInputListStyles: {
                    top: "0px",
                    left: "0px",
                    width: "100px"
                }
            }
        },
        watch: {
            currencySearchQuery (val) {
                // @todo: debounce
                this.searchCurrency(val);
            }
        },
        methods: {
            ...mapActions('portfolio', [
                'setTotalValueUSD',
                'setTotalPercentChange24h',
            ]),
            ...mapActions('portfolioTable', [
                'setTableSortColumnName',
                'setTableSortColumnDesc',
            ]),
            ...mapActions('portfolioData', [
                'setTableItems'
            ]),
            setData (data) {

                // set portfolio table data
                if (data.portfolioTable) {
                    this.setTableItems(data.portfolioTable);
                }

                // set portfolio
                if (data.portfolio) {
                    this.setTotalValueUSD(data.portfolio.totalValueUSD);
                    this.setTotalPercentChange24h(data.portfolio.totalPercentChange24h);
                }
            },
            createPortfolioItem () {
                API.portfolio.create({
                    currencyId: this.editItem.currencyID,
                    amount: this.editItem.amount,
                    title: this.editItem.title,
                }).then((response) => {
                    this.editItem = null;
                    this.setData(response.data);
                    this.notifySuccess(this.$t('global.MessageChangeSaved'));
                }).catch(() => {
                    this.notifyError(this.$t('global.RequestFailed'));
                });
            },
            updatePortfolioItem () {
                API.portfolio.update({
                    id: this.editItem.id,
                    currencyId: this.editItem.currencyID,
                    amount: this.editItem.amount,
                    title: this.editItem.title,
                }).then((response) => {
                    this.editItem = null;
                    this.setData(response.data);
                    this.notifySuccess(this.$t('global.MessageChangeSaved'));
                }).catch(() => {
                    this.notifyError(this.$t('global.RequestFailed'));
                });
            },
            deletePortfolioItem (portfolioId) {
                if (confirm(this.$t('portfolio.DeleteConfirm'))) {
                    this.editItem = null;
                    API.portfolio.delete({id: portfolioId,}).then((response) => {
                        this.setData(response.data);
                        this.notifySuccess(this.$t('global.MessageChangeSaved'));
                    }).catch(() => {
                        this.notifyError(this.$t('global.RequestFailed'));
                    });
                }
            },
            loadTableData () {
                API.portfolio.table().then((response) => {
                    this.setData(response.data);
                }).catch(() => {
                    this.notifyError(this.$t('global.RequestFailed'));
                });
            },
            toggleSort (columnName) {
                // disable sort of column
                if (columnName == this.tableSortColumnName && this.tableSortColumnDesc === true) {
                    this.setTableSortColumnName("");
                    this.setTableSortColumnDesc(false);
                }
                // set desc sort
                else if (columnName == this.tableSortColumnName) {
                    this.setTableSortColumnDesc(true);
                }
                // set new column asc sort
                else {
                    this.setTableSortColumnName(columnName);
                    this.setTableSortColumnDesc(false);
                }
            },
            compareBy (propName) {
                return function (a, b) {
                    if (a["id"] == null) return 1;
                    if (a[propName] < b[propName]) return -1;
                    if (a[propName] > b[propName]) return 1;
                    return 0;
                }
            },
            compareDescBy (propName) {
                return function (a, b) {
                    if (a["id"] == null) return 1;
                    if (a[propName] > b[propName]) return -1;
                    if (a[propName] < b[propName]) return 1;
                    return 0;
                }
            },
            isActive (item) {
                return this.editItem != null && this.editItem.id == item.id;
            },
            editPortfolioItem (portfolioItem) {
                this.currencySearchQuery = "";
                this.editItem = loClone(portfolioItem);
            },
            addPortfolioItem () {
                let item = {
                    id: null,
                    currencyID: 0,
                    currencyName: "",
                    currencySymbol: "",
                    title: "",
                    amount: 0,
                    priceUSD: 0,
                    valueUSD: 0,
                    change24hUSD: 0,
                    priceBTC: 0,
                    valueBTC: 0,
                    change24hBTC: 0,
                };

                if (this.filterCurrency != null) {
                    item.currencyID = this.filterCurrency.id;
                    item.currencyName = this.filterCurrency.name;
                    item.currencySymbol=  this.filterCurrency.symbol;
                }

                this.items.push(item);

                this.editPortfolioItem(this.items[this.items.length - 1]);
            },
            savePortfolioItem () {
                if (this.editItem.id) {
                    this.updatePortfolioItem();
                } else {
                    this.createPortfolioItem();
                }
            },
            cancelPortfolioItem () {
                this.editItem = null;
                this.loadTableData();
            },
            searchCurrency (query) {
                API.currency.search({query}).then((response) => {
                    this.currencySearchResult = response.data.items ? response.data.items : [];
                });
            },
            selectCurrency (currencyId) {
                this.currencySearchQuery = "";
                for (let i=0; i<this.currencySearchResult.length; i++) {
                    let currency = this.currencySearchResult[i];
                    if (currency.id == currencyId) {
                        this.editItem.currencyID = currency.id;
                        this.editItem.currencyName = currency.name;
                        this.editItem.currencySymbol = currency.symbol;
                    }
                }
            },
            currencyFullName(portfolioItem) {
                return portfolioItem.currencyName.length > 0
                    ? portfolioItem.currencyName + " / " + portfolioItem.currencySymbol
                    : portfolioItem.currencySymbol;
            },
            onShowChart() {
                this.$emit('show-chart');
            }
        }
    }
</script>