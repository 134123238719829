<template>
    <layout v-bind:mobile-mode="mobileMode">
        <custom-scroll class="content__col content__col_wide"
                       v-bind:mobile-mode="mobileMode"
                       scroll-vertical>
            <div class="field-set">
                <div class="field-set__row">
                    <div class="field-set__title">The website intwt.com is on sale</div>
                </div>
                <div class="field-set__row">
                    <div class="news-detail__text">
                        <div>This is a news aggregator platform with all media and cryptocurrency blogs.</div>
                        <br/>
                        <div>Main features:</div>
                        <ul>
                            <li>Unique domain name "intwt.com" - in token we trust</li>
                            <li>Analyze 3,371 news sources in English, Russian and Chinese</li>
                            <li>More than 3 thousand new materials daily</li>
                            <li>Total number of materials has already exceeded one million</li>
                            <li>Monitors indicators for 2,716 cryptocurrencies using data from coinmarketcap.com</li>
                            <li>Track new cryptocurrencies on the market</li>
                            <li>Each material is analyzed for the mention of cryptocurrency and popularity in social networks</li>
                            <li>Users can filter news by special interface and save options of filter on self account</li>
                            <li>Users can stream news from self feed to telegram channel</li>
                            <li>List of cryptocurrency allows users to observe the leaders of growth and decline in the market</li>
                            <li>Users can create a cryptocurrency portfolio and track its dynamics on a chart</li>
                            <li>Website interface adapted for mobile devices and translated into three languages ( English, Russian, Chinese )</li>
                            <li>Used the following main technologies: Docker, Nginx, GoLang, Beanstalkd, PostgreSQL, Redis, Memcached, VueJS, Browserless Chrome, Tor & HaProxy, Prometheus, Grafana, php7 & Laravel, GitLab CI</li>
                        </ul>
                        <br/>
                        <div>Contacts</div>
                        <div>Email: atdima@gmail.com</div>
                        <div>Telegram: @atdima</div>
                    </div>
                </div>


            </div>
        </custom-scroll>
    </layout>
</template>
<script>
    import {mapGetters} from 'vuex'
    import PageMixin from "./../mixins/page"

    export default {
        mixins: [PageMixin],
        computed: {
            ...mapGetters('locale', ['currentLocale']),
        }
    }
</script>