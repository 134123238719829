export default [
    {
        path: '/api/bot/data',
        name: 'api_bot_data'
    },
    {
        path: '/api/bot/data/telegram',
        name: 'api_bot_data_telegram'
    },
    {
        path: '/api/bot/create/telegram',
        name: 'api_bot_create_telegram'
    },
    {
        path: '/api/bot/update',
        name: 'api_bot_update'
    }
]