import {LOADING_SET} from "@/store/mutation-types";

const state = {
    loading: false,
};

const getters = {
    isLoading (state) {
        return state.loading;
    }
};

const mutations = {
    [LOADING_SET] (state, value) {
        state.loading = value === true;
    },
};

const actions = {
    loadingStart (store) {
        store.commit(LOADING_SET, true);
    },
    loadingStop (store) {
        store.commit(LOADING_SET, false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}