<template>
    <filter-search-component
            v-on:search="search"
            v-on:dismiss-all="dismissAll"
            v-bind:user-feed="userFeed"
            v-bind:label="label"
            v-bind:label-selected-all="labelSelectedAll"
            v-bind:label-selected="labelSelected"
            v-bind:label-top="labelTop"
            v-bind:placeholder-search="placeholderSearch"
            v-bind:message-selected-empty="messageSelectedEmpty"
            v-bind:message-search-empty="messageSearchEmpty"
            v-bind:items-selected="itemsSelected"
            v-bind:items-top="itemsTop"
            v-bind:items-search="itemsSearch"
            v-bind:with-top="true"
            v-model="selected">
    </filter-search-component>
</template>
<script>
    import API from './../../api'
    import FilterSearchComponent from './filter-search-component.vue'
    import uniqBy from 'lodash/uniqBy'

    export default {
        name: 'filter-search-tag',
        components: {
            FilterSearchComponent
        },
        props: {
            userFeed: {
                type: Boolean,
                required: true
            },
            tagTop: {
                type: Array,
                required: true
            },
            tagListSelected: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                tagListSearch: [],
            }
        },
        computed: {
            /**
             * Список Id выбранных валют, интерфейс синхронизации в store
             */
            selected: {
                get() {
                    return this.tagListSelected.map(item => item.id);
                },
                set(val) {

                    // основной список отмеченных валют
                    let items = this.tagListSelected.filter((item) => {
                        return val.indexOf(item.id) != -1
                    });

                    // добавляем все что выбрано в поиске
                    this.tagListSearch.forEach((item) => {
                        if (val.indexOf(item.id) != -1) {
                            items.push(item);
                        }
                    });

                    // добавялем все что выбрано в топе
                    this.tagTop.forEach((item) => {
                        if (val.indexOf(item.id) != -1) {
                            items.push(item);
                        }
                    });

                    // удаляем дубли
                    items = uniqBy(items, 'id');

                    // сохраняем список выбранныхх валют
                    this.setTag(items);
                }
            },
            /**
             * Список выбранных валют
             */
            itemsSelected () {
                return this.tagListSelected.map((item) => {
                    return {
                        value: item.id,
                        name: this.formatItemText(item),
                    }
                });
            },
            /**
             * Список топ валют
             */
            itemsTop () {
                return this.tagTop.map((item) => {
                    return {
                        value: item.id,
                        name: this.formatItemText(item),
                    }
                });
            },
            /**
             * Список найденных валют
             */
            itemsSearch () {
                return this.tagListSearch.map((item) => {
                    return {
                        value: item.id,
                        name: this.formatItemText(item),
                    }
                });
            },
            label () {
                return this.$t('global.Filter');
            },
            labelSelected () {
                return this.$t('global.Trends');
            },
            labelSelectedAll () {
                return this.$t('global.TrendsAll');
            },
            labelTop () {
                return this.$t('global.ShowTop') + ' 20';
            },
            messageSelectedEmpty () {
                return this.$t('global.SelectedEmpty');
            },
            messageSearchEmpty () {
                return this.$t('global.SearchEmpty');
            },
            placeholderSearch () {
                return this.$t('global.SearchTagPlaceholder');
            }
        },
        methods: {
            setTag (items) {
                this.$emit('select', items);
            },
            clearTag () {
                this.$emit('clear');
            },
            formatItemText (item) {
                return item.name;
            },
            dismissAll () {
                this.clearTag();
            },
            search (q) {
                API.tag.search({query: q}).then(response => {
                    if (response.data.items) {
                        this.tagListSearch = response.data.items;
                    } else {
                        this.tagListSearch = [];
                    }
                })
            }
        }
    }
</script>