import {mapState, mapActions} from 'vuex'

export default {
    computed: {
        ...mapState('tagTable', [
            'tagTableShowFavorite',
            'tagTableQuery',
            'tagTableOrder',
            'tagTableDesk'
        ]),
        tagTableQueryModel: {
            get () {
                return this.tagTableQuery
            },
            set (value) {
                this.tagTableSetQuery(value);
            }
        },
        tagTableOrderModel: {
            get () {
                return this.tagTableOrder
            },
            set (value) {
                this.tagTableSetOrder(value);
            }
        },
        tagTableDeskModel: {
            get () {
                return this.tagTableDesk
            },
            set (value) {
                this.tagTableSetDesk(value);
            }
        },
    },
    watch: {
        tagTableShowFavorite () {
            this.$emit('update');
        },
        tagTableQuery () {
            this.$emit('update');
        },
        tagTableOrder () {
            this.$emit('update');
        },
        tagTableDesk () {
            this.$emit('update');
        }
    },
    methods: {
        ...mapActions('tagTable', [
            'tagTableToggleShowFavorite',
            'tagTableSetQuery',
            'tagTableSetOrder',
            'tagTableSetDesk',
        ]),
        tagTableResetQuery() {
            this.tagTableSetQuery("");
        }
    }
}