import ViewCurrencies from "./../../views/currencies.vue";

export default [
    {
        path: '/currencies',
        name: 'currencies',
        component: ViewCurrencies,
        children: [
            {
                path: ':currency',
                name: 'currencies_item',
                component: ViewCurrencies,
                children: [
                    {
                        path: 'post/:slug',
                        name: 'currencies_content_item',
                        component: ViewCurrencies,
                    }
                ]
            }
        ]
    },
    {
        path: '/api/currency/list',
        name: 'api_currency_list',
    },
    {
        path: '/api/currency/list/common',
        name: 'api_currency_list_common',
    },
];