let numbro = require('numbro');

export default function (Vue) {

    Vue.filter('toPercent', function (value) {
        return numbro(value).format({mantissa: 2, trimMantissa: true});
    });

    Vue.filter('toCurrencySmart', function (value) {
        return value > 1
            ? numbro(value).format({thousandSeparated: true, mantissa: 2, trimMantissa: true})
            : numbro(value).format({thousandSeparated: true, mantissa: 7, trimMantissa: true});
    });

    Vue.filter('toCurrency', function (value) {
        return numbro(value).format({thousandSeparated: true, mantissa: 10, trimMantissa: true});
    });

    Vue.filter('toCurrencyShort', function (value) {
        return numbro(value).format({thousandSeparated: true, mantissa: 1, average: true});
    });

    Vue.filter('toCurrencyAbbreviation', function (value) {
        return numbro(value).format({thousandSeparated: true, mantissa: 1, average: true});
    });

    Vue.filter('withSign', function (value) {
        return (value > 0 ? '+' : '') + value
    });

    Vue.filter('sourceLink', function (value) {
        let filterRegex = /^http[s]?:\/\/(www\.)?(.+?)[/]{0,1}$/;
        let matches = value.match(filterRegex);
        return matches ? matches[2] : value;
    });

    Vue.filter('lowercase', function (value) {
        return value.toLowerCase();
    });
}