<template>
    <tr v-on:click="onSelectTag" v-bind:data-active="isTagActive">
        <td align="left">
            <star-icon v-bind:active="isTagFavorite" v-on:click.prevent.stop="onToggleTagFavorite"></star-icon>
        </td>
        <td>{{ tag.name }}</td>
    </tr>
</template>
<script>
    import {mapState} from 'vuex'
    import NotifyMixin from "./../../mixins/notify"
    import TagFavoritesMixin from './../../mixins/tag-favorites'
    import StarIcon from './../icons/star.vue'

    export default {
        name: "tag-table-item",
        mixins: [TagFavoritesMixin, NotifyMixin],
        components: {
            StarIcon
        },
        props: {
            tag: {
                type: Object,
                required: true
            },
            activeTag: {
                required: true
            }
        },
        computed: {
            ...mapState('auth', [
                'loggedIn',
            ]),
            isTagFavorite () {
                return this.isFavorite(this.tag.id);
            },
            isTagActive () {
                return !!this.activeTag && this.activeTag.id == this.tag.id;
            },
        },
        methods: {
            onToggleTagFavorite () {
                this.toggleTagFavorite(this.tag.id);
            },
            onSelectTag () {
                this.$emit('select-tag');
            }
        }
    }
</script>