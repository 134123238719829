import {
    PORTFOLIO_SET_TOTAL_VALUE_USD,
    PORTFOLIO_SET_TOTAL_PERCENT_CHANGE_24H,
    PORTFOLIO_CLEAR,
} from "./../../store/mutation-types";

const state = {
    totalValueUSD: 0,
    totalPercentChange24h: 0
};

const getters = {};

const mutations = {
    [PORTFOLIO_SET_TOTAL_VALUE_USD] (state, value) {
        state.totalValueUSD = value
    },
    [PORTFOLIO_SET_TOTAL_PERCENT_CHANGE_24H] (state, value) {
        state.totalPercentChange24h = value
    },
    [PORTFOLIO_CLEAR] (state) {
        state.totalValueUSD = 0;
        state.totalPercentChange24h = 0;
    }
};

const actions = {
    setTotalValueUSD (store, value) {
        store.commit(PORTFOLIO_SET_TOTAL_VALUE_USD, value);
    },
    setTotalPercentChange24h (store, value) {
        store.commit(PORTFOLIO_SET_TOTAL_PERCENT_CHANGE_24H, value);
    },
    clear (store) {
        store.commit(PORTFOLIO_CLEAR);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}