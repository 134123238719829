export default {
    data() {
        return {
            windowWidth: 0,
            windowHeight: 0
        }
    },
    methods: {
        listenWindowResize() {
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
            });

            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        }
    }
}