<template>
    <div class="news-list">
        <slot name="title"></slot>
        <content-list-item v-for="item in items"
                           v-bind:key="item.id"
                           v-on:select="selectItem"
                           v-bind:item="item"
                           v-bind:active-item="activeItem"
                           v-bind:mobile-mode="mobileMode"
        ></content-list-item>
        <load-more ref="loadMore"
                   v-bind:mobile-mode="mobileMode"
                   v-on:load-more="loadMoreHandler"></load-more>
    </div>
</template>
<script>
    import ContentListItem from './content-list-item.vue'
    import LoadMore from './../load-more.vue';
    import LoadMoreMixin from './../../mixins/load-more';

    export default {
        name: 'content-list',
        mixins: [LoadMoreMixin],
        components: {
            ContentListItem,
            LoadMore
        },
        props: {
            items: {
                type: Array,
                required: true
            },
            activeItem: {
                required: true
            },
            mobileMode: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            selectItem(item) {
                this.$emit('select-item', item);
            }
        }
    }
</script>