import moduleAuth from "./modules/auth.js";
import moduleLocale from "./modules/locale.js";
import moduleTag from "./modules/tag.js";
import moduleTagTable from "./modules/tag-table.js";
import moduleCurrency from "./modules/currency.js";
import moduleCurrencyTable from "./modules/currency-table.js";
import modulePage from "./modules/page.js";
import moduleFilter from "./modules/filter.js";
import moduleFilterFeed from "./modules/filter-feed.js";
import modulePortfolio from "./modules/portfolio.js";
import modulePortfolioTable from "./modules/portfolio-table.js";
import modulePortfolioData from "./modules/portfolio-data.js";
import moduleCountry from "./modules/country.js";
import vuexLocal from "./plugins/vuex-local"
import pluginLogger from "./plugins/logger";
import Vue from "vue"
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: false,
    modules: {
        auth: moduleAuth,
        locale: moduleLocale,
        tag: moduleTag,
        tagTable: moduleTagTable,
        currency: moduleCurrency,
        currencyTable: moduleCurrencyTable,
        filter: moduleFilter,
        filterFeed: moduleFilterFeed,
        page: modulePage,
        portfolio: modulePortfolio,
        portfolioTable: modulePortfolioTable,
        portfolioData: modulePortfolioData,
        country: moduleCountry,
    },
    state: {},
    mutations: {
        RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION
    },
    actions: {
        clearStore (store) {
            store.dispatch('auth/clear');
            store.dispatch('filter/clear');
            store.dispatch('filterFeed/clear');
            store.dispatch('tag/clear');
            store.dispatch('currency/clear');
            store.dispatch('portfolio/clear');
        },
        setCommonData (store, commonData) {

            // set loggedIn status
            if (commonData.logged_in) {
                store.dispatch('auth/logIn');
            } else {
                store.dispatch('auth/logOut');
            }

            // set tag top/favorites
            if (commonData.tag) {
                if (commonData.tag.top) {
                    store.dispatch('tag/setTagTop', commonData.tag.top);
                }
                if (commonData.tag.favorites) {
                    store.dispatch('tag/setTagFavorites', commonData.tag.favorites);
                }
            }

            // set currency top/favorites
            if (commonData.currency) {
                if (commonData.currency.top) {
                    store.dispatch('currency/setCurrencyTop', commonData.currency.top);
                }
                if (commonData.currency.favorites) {
                    store.dispatch('currency/setCurrencyFavorites', commonData.currency.favorites);
                }
            }

            // set filter settings
            if (commonData.filter) {
                store.dispatch('filterFeed/clear');
                store.dispatch('filterFeed/populate', commonData.filter);
                store.dispatch('filterFeed/signInitial');
            }

            // set portfolio
            if (commonData.portfolio) {
                store.dispatch('portfolio/clear');
                store.dispatch('portfolio/setTotalValueUSD', commonData.portfolio.totalValueUSD);
                store.dispatch('portfolio/setTotalPercentChange24h', commonData.portfolio.totalPercentChange24h);
            }

            // set portfolio table
            if (commonData.portfolioTable) {
                store.dispatch('portfolioTable/setTableVisibleAmount', commonData.portfolioTable.tableVisibleAmount);
                store.dispatch('portfolioTable/setTableVisibleValueUsd', commonData.portfolioTable.tableVisibleValueUsd);
                store.dispatch('portfolioTable/setTableVisiblePriceUsd', commonData.portfolioTable.tableVisiblePriceUsd);
                store.dispatch('portfolioTable/setTableVisibleChange24hUsd', commonData.portfolioTable.tableVisibleChange24hUsd);
                store.dispatch('portfolioTable/setTableVisibleValueBtc', commonData.portfolioTable.tableVisibleValueBtc);
                store.dispatch('portfolioTable/setTableVisiblePriceBtc', commonData.portfolioTable.tableVisiblePriceBtc);
                store.dispatch('portfolioTable/setTableVisibleChange24hBtc', commonData.portfolioTable.tableVisibleChange24hBtc);
                store.dispatch('portfolioTable/setTableSortColumnName', commonData.portfolioTable.tableSortColumnName);
                store.dispatch('portfolioTable/setTableSortColumnDesc', commonData.portfolioTable.tableSortColumnDesc);
            }

            // set list active countries
            if (commonData.countries) {
                store.dispatch('country/setCountries', commonData.countries);
            }
        }
    },
    plugins: [vuexLocal.plugin, pluginLogger]
});

export default store;