<template>
    <div class="news-detail-share">
        <div class="news-detail-share__title">{{ $t('global.Share') }}</div>
        <div class="news-detail-share__item">
            <svg class="news-detail-share__icon" xmlns:xlink="http://www.w3.org/1999/xlink" width="8" height="14">
                <use xlink:href="#icon-fb"></use>
            </svg>
            <a class="news-detail-share__link"
               v-on:click.prevent="openWindow"
               v-bind:href="createSharingUrl('facebook')"></a>
        </div>
        <div class="news-detail-share__item">
            <svg class="news-detail-share__icon" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="9">
                <use xlink:href="#icon-vk"></use>
            </svg>
            <a class="news-detail-share__link"
               v-on:click.prevent="openWindow"
               v-bind:href="createSharingUrl('vk')"></a>
        </div>
        <div class="news-detail-share__item">
            <svg class="news-detail-share__icon" xmlns:xlink="http://www.w3.org/1999/xlink" width="13" height="11">
                <use xlink:href="#icon-tw"></use>
            </svg>
            <a class="news-detail-share__link"
               v-on:click.prevent="openWindow"
               v-bind:href="createSharingUrl('twitter')"></a>
        </div>
    </div>
</template>
<script>
    // @see https://github.com/nicolasbeauvais/vue-social-sharing/blob/master/src/networks.json
    const networks = {
        "facebook" : "https://www.facebook.com/sharer/sharer.php?u=@url&title=@title&description=@description&quote=@quote",
        "twitter" : "https://twitter.com/intent/tweet?text=@title&url=@url&hashtags=@hashtags@twitteruser",
        "vk" : "https://vk.com/share.php?url=@url&title=@title&description=@description&image=@media&noparse=true",
    };

    import RouterMixin from './../../mixins/router';

    export default {
        name: "share",
        mixins: [RouterMixin],
        props: {
            item: {
                type: Object,
                required: true
            },

            /**
             * Twitter hashtags
             * @var string
             */
            hashtags: {
                type: String,
                default: 'intwt'
            },

            /**
             * Twitter user.
             * @var string
             */
            twitterUser: {
                type: String,
                default: 'intwt_com'
            },

            mobileMode: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            url () {
                return this.routeURL({
                    name: "content_item",
                    params: {
                        slug: this.item.slug
                    }
                });
            },
            title () {
                return this.item.title
            },
            description () {
                return this.item.descritpion;
            }
        },
        methods: {
            createSharingUrl (network) {
                return networks[network]
                    .replace(/@url/g, encodeURIComponent(this.url))
                    .replace(/@title/g, encodeURIComponent(this.title))
                    .replace(/@description/g, encodeURIComponent(this.description))
                    .replace(/@hashtags/g, this.hashtags)
                    .replace(/@twitteruser/g, this.twitterUser ? '&via=' + this.twitterUser : '');
            },
            openWindow (event) {
                if (this.mobileMode) {
                    window.open(event.target.href, '_self')
                } else {
                    const options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600';
                    window.open(event.target.href, '', options)
                }
            }
        }
    }
</script>