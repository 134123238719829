import RouterMixin from "./router";
import NotifyMixin from "./notify";
import LoaderMixin from "./loader";

const touchMap = new WeakMap();

export default {
    mixins: [RouterMixin, NotifyMixin, LoaderMixin],
    data() {
        return {
            formDirty: false,
            formProgress: false,
            formErrors: [],
            recaptcha: ''
        }
    },
    methods: {
        delayTouch($v) {
            this.formClearServerErrors();
            $v.$reset();
            if (touchMap.has($v)) {
                clearTimeout(touchMap.get($v));
            }
            touchMap.set($v, setTimeout($v.$touch, 500));
        },
        formTouch() {
            this.formDirty = false;
            this.formClearServerErrors();
        },
        formSubmit() {
            if (this.formProgress) {
                return false;
            }

            this.formDirty = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }

            this.formProgressStart();

            this.formRequest().then((response) => {
                this.formTouch();
                this.formProcessSuccessResponse(response);
            }).catch((response) => {
                this.formProcessErrorResponse(response);
            }).finally(() => {
                this.formRecaptchaReset();
                this.formProgressFinish();
            });
        },
        formRequest() {
            throw new Error('You have to implement the method [formRequest]!');
        },
        formClearServerErrors() {
            this.formErrors = [];
        },
        formProcessSuccessResponse() {
            this.notifySuccess(this.$t('global.MessageChangeSaved'));
        },
        formProcessErrorResponse(error) {
            const response = error.response;
            if (response && response.status == 400) {
                this.formErrors = response.data.errors;
                this.notifyError(this.$t('errors.InvalidRequest'));
            } else {
                this.notifyError(this.$t('errors.SystemError'));
            }
        },
        formProgressStart() {
            this.formClearServerErrors();
            this.formProgress = true;
            this.loadingStart();
        },
        formProgressFinish() {
            this.formProgress = false;
            this.loadingStop();
        },
        formRecaptchaReset () {
            this.recaptcha = '';
            if (this.$refs.formRecaptcha) {
                this.$refs.formRecaptcha.reset();
            }
        },
        formRecaptchaOnVerify (response) {
            this.formTouch();
            this.recaptcha = typeof response == "string" ? response : '';
        }
    }
}