<template>
    <component v-if="embedComponent"
               v-bind:is="embedComponent"
               v-bind:item="item"
               v-on:scroll="onScroll"
    ></component>
</template>
<script>
    import EmbedContentDefault from "./embed-content-default.vue"
    import EmbedContentReddit from "./embed-content-reddit.vue"
    import EmbedContentTwitter from "./embed-content-twitter.vue"

    export default {
        name: "embed-content",
        components: {
            EmbedContentDefault,
            EmbedContentReddit,
            EmbedContentTwitter
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            url () {
                return this.item.original_url;
            },
            isReddit () {
                return this.url.match(/^https:\/\/(www.)?reddit.com\//) != null;
            },
            isTwitter () {
                return this.url.match(/^https:\/\/twitter.com\//) != null;
            },
            embedComponent () {
                if (this.isReddit) {
                    return "embed-content-reddit";
                } else if (this.isTwitter) {
                    return "embed-content-twitter";
                }
                return "embed-content-default";
            }
        },
        methods: {
            onScroll (delta) {
                this.$emit('scroll', delta);
            }
        }
    }
</script>