export default {
    methods: {
        routeHref(params) {
            let href = '';
            let route = this.$router.resolve(params);
            if (route) {
                href = route.href;
                if (href && href[0] == '#') {
                    href = href.substring(1)
                }
            }
            return href;
        },
        routeURL(params) {
            let url = '';
            let href = this.routeHref(params);
            if (href) {
                url = window.location.origin + href;
            }
            return url;
        }
    }
}