const items = [
    {value: 1, name: 'filter.Positive'},
    {value: 2, name: 'filter.Negative'},
    {value: 3, name: 'filter.Important'},
    {value: 4, name: 'filter.Trend'},
];

export default {
    all() {
        return items;
    }
}