import API from './../api'

const PAGINATOR_PERPAGE = 40;

export default {
    data() {
        return {
            contentListLoading: false,
            contentItem: null,
            contentItems: [],
        }
    },
    computed: {
        hasContentItem () {
            return this.contentItem != null;
        },
    },
    watch: {
        mobileMode: function () {
            this.contentLoadMoreForceReset();
        },
    },
    methods: {
        onChangeContentFilter: function () {
            this.contentListLoad();
        },
        // обновить данные выбранного материала и в списке
        updateContentItem(item) {
            for (let i=0; i < this.contentItems.length; i++) {
                if (this.contentItems[i].id == item.id) {
                    this.$set(this.contentItems, i, item);
                    break;
                }
            }
            if (this.contentItem.id == item.id) {
                this.contentItem = item;
            }
        },
        // получить параметры запроса для получения списка материалов
        getContentListRequestParams() {
            return {
            }
        },
        // получить последний contentID из списка материалов
        getLastContentId() {
            return this.contentItems.length > 0
                ? this.contentItems[this.contentItems.length - 1].id
                : 0;
        },
        // загрузить данные для выбранного материала
        contentItemLoad (params) {
            API.content.item(params).then((response) => {
                this.contentItem = response.data.item;
            });
        },
        contentListLoad () {
            if (this.contentListLoading) { return; }
            else { this.contentListLoading = true; }
            let params = this.getContentListRequestParams();
            API.content.list(params).then((response) => {
                this.contentItems = response.data.items;
                this.contentLoadMoreReset();
                this.contentLoadMoreLoaded();
                if (this.contentItems.length < PAGINATOR_PERPAGE) {
                    this.contentLoadMoreComplete();
                }
            }).catch(() => {
                this.contentLoadMoreComplete();
            }).finally(() => {
                this.contentListLoading = false;
            });
        },
        // параметры фильтра не менялись, нужно подгрузить больше материалов
        contentListLoadMore () {
            if (this.contentListLoading) { return; }
            else { this.contentListLoading = true; }
            let params = this.getContentListRequestParams();
            params.last_id = this.getLastContentId();
            API.content.list(params).then((response) => {
                if (response.data.items.length > 0) {
                    this.contentItems = this.contentItems.concat(response.data.items);
                }
                this.contentLoadMoreLoaded();
                if (response.data.items.length < PAGINATOR_PERPAGE) {
                    this.contentLoadMoreComplete();
                }
            }).catch(() => {
                this.contentLoadMoreComplete();
            }).finally(() => {
                this.contentListLoading = false;
            });
        },
        contentLoadMoreComplete() {
            if (this.$refs.contentList) {
                this.$refs.contentList.loadMoreComplete();
            }
        },
        contentLoadMoreLoaded() {
            if (this.$refs.contentList) {
                this.$refs.contentList.loadMoreLoaded();
            }
        },
        contentLoadMoreReset() {
            if (this.$refs.contentList) {
                this.$refs.contentList.loadMoreReset();
            }
        },
        contentLoadMoreForceReset() {
            if (this.$refs.contentList) {
                this.$refs.contentList.loadMoreForceReset();
            }
        }
    }
}