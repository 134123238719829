<template>
    <td>
        <template v-if="withActionButtons">
            <div v-on:click.prevent="onDelete" class="table__remove"></div>
            <svg v-on:click.prevent="onEdit" class="table__edit" xmlns:xlink="http://www.w3.org/1999/xlink">
                <use xlink:href="#icon-pencil"></use>
            </svg>
        </template>

        <div class="table__input-wrap" ref="inputWrap">
            <input type="text"
                   v-on:focus="onFocus"
                   v-on:blur="onBlur"
                   v-model="modelValue"
                   v-bind:disabled="!active"
                   v-bind:data-mark="active">
        </div>
    </td>
</template>
<script>
    export default {
        name: "portfolio-table-cell",
        data () {
            return {
                focused: false
            }
        },
        props: {
            value: {
                required: true
            },
            inputValue: {
                default: ""
            },
            active: {
                type: Boolean,
                default: false
            },
            tableInputListStyles: {
                type: Object,
                default () {
                    return {}
                }
            },
            withActionButtons: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            modelValue: {
                get () {
                    return this.focused
                        ? this.inputValue : this.value;
                },
                set (newVal) {
                    this.$emit("update:inputValue", newVal);
                }
            },
        },
        methods: {
            onFocus () {
                this.focused = true;
                this.$emit("update:tableInputListStyles", this.calcTableInputListStyles())
            },
            onBlur () {
                this.focused = false;
            },
            onDelete () {
                this.$emit("delete");
            },
            onEdit () {
                this.$emit("edit")
            },
            calcTableInputListStyles () {
                return {
                    top: this.$refs.inputWrap.parentNode.parentNode.offsetTop + this.$refs.inputWrap.parentNode.clientHeight + "px",
                    left: this.$refs.inputWrap.offsetLeft + "px",
                    width: this.$refs.inputWrap.clientWidth + "px",
                };
            },

        }
    }
</script>