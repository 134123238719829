import { render, staticRenderFns } from "./star-small.vue?vue&type=template&id=2110d184&"
import script from "./star-small.vue?vue&type=script&lang=js&"
export * from "./star-small.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "star-small.vue"
export default component.exports