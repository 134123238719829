export default {
    methods: {
        notifySuccess(message) {
            return this.$snotify.success(message, {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: 'rightBottom'
            });
        },
        notifyError(message) {
            return this.$snotify.error(message, {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: 'rightBottom'
            });
        },
    }
}