<template>
    <div class="news-detail__text"></div>
</template>
<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "embed-content-twitter",
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                twitterLangs: {
                    'en': 'en',
                    'ru': 'ru',
                    'cn': 'zh-cn'
                }
            }
        },
        computed: {
            ...mapGetters('locale', ['currentLocale']),
            url () {
                return this.item.original_url;
            },
            tweetLangCode () {
                return (this.currentLocale && this.twitterLangs[this.currentLocale.name]) || 'en';
            }
        },
        created () {
            this.$nextTick(() => {
                this.tryEmbedTweet(this.url);
            });
        },
        watch: {
            url (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.tryEmbedTweet(newVal);
                }
            }
        },
        methods: {
            tryEmbedTweet (url) {
                this.$el.innerHTML = "";
                let tweetId = this.parseId(url);
                if (tweetId != null) {
                    this.embedTweet(tweetId, this.$el);
                }
            },
            parseId (url) {
                let matches = url.match(/^https:\/\/twitter.com\/.*?\/([\d]+)\n?$/);
                return matches ? matches[1] : null;
            },
            embedTweet (tweetId, targetEl) {
                let embedOptions = {
                    'theme': 'light',
                    'show-replies': false,
                    'lang': this.tweetLangCode,
                };

                if (window.twttr && window.twttr.widgets) {
                    window.twttr.widgets.createTweet(tweetId, targetEl, embedOptions);
                } else {
                    // если API твиттера еще не успел инициализироваться,
                    // пробуем повторно через 500 милисекунд
                    setTimeout(() => {
                        this.embedTweet(tweetId, targetEl);
                    }, 500);
                }
            }
        }
    }
</script>