<template>
    <custom-scroll class="currency-widget"
        v-bind:mobile-mode="mobileMode"
        scroll-vertical>

        <div class="currency-widget-search">
            <div class="input-text">
                <input type="text" v-model="searchQuery" v-bind:data-clear="searchQuery.length > 0">

                <div v-if="searchQuery.length > 0"
                     v-on:click="searchQuery = ''"
                     class="input-text__clear"></div>

                <svg xmlns:xlink="http://www.w3.org/1999/xlink">
                    <use xlink:href="#icon-lens"></use>
                </svg>
            </div>
            <star-icon v-bind:active="showFavorites" v-on:click="toggleFavoritesMode"></star-icon>
        </div>

        <tag-item v-for="(tag, index) in listTags"
                       v-bind:key="index"
                       v-bind:tag="tag"
                       v-bind:favorite="isFavorite(tag.id)"
                       v-bind:active="isActive(tag.id)"
                       v-on:toggle-tag-favorite="toggleTagFavorite"
        ></tag-item>
    </custom-scroll>
</template>
<script>
    import API from './../api'
    import NotifyMixin from "./../mixins/notify"
    import TagFavoritesMixin from "./../mixins/tag-favorites"
    import StarIcon from './icons/star.vue'
    import TagItem from './sidebar/tag-item.vue'
    import {mapState} from 'vuex'

    const MODE_TOP = 'top';
    const MODE_SEARCH = 'search';
    const MODE_FAVORITES = 'favorites';

    export default {
        name: 'sidebar-view',
        mixins: [NotifyMixin, TagFavoritesMixin],
        components: {
            StarIcon,
            TagItem,
        },
        props: {
            activeTag: {
                type: Object
            },
            portfolioVisible: {
                type: Boolean,
                required: true
            },
            mobileMode: {
                type: Boolean,
                required: true
            }
        },
        data () {
            return {
                mode: MODE_TOP,
                searchQuery: '',
                listTags: [],
                listSearchResult: [],
            }
        },
        created () {
            this.onChange();
        },
        computed: {
            ...mapState('auth', [
                'loggedIn'
            ]),
            searchMode () {
                return this.searchQuery.length > 0;
            }
        },
        watch: {
            searchQuery (val) {
                if (val.length > 0) {
                    this.search(val);
                }
            },
            searchMode () {
                this.onChange();
            },
            listSearchResult () {
                this.onChange();
            },
            showFavorites () {
                this.onChange();
            },
            loggedIn () {
                this.onChange();
            },
            tagTop () {
                this.onChange();
            },
            favoritesMap () {
                this.onChange();
            }
        },
        methods: {
            selectTag(item) {
                this.$router.push({name: 'tag', params: {tag: item.slug}})
            },
            search (query) {
                API.tag.search({query}).then((response) => {
                    this.listSearchResult = response.data.items ? response.data.items : [];
                });
            },
            onChange() {
                this.updateMode();
                this.updateListTags();
            },
            updateMode() {
                this.mode = this.getMode();
            },
            updateListTags() {
                this.listTags = this.getTags();
            },
            getMode() {
                let res = MODE_TOP;
                if (this.searchQuery.length > 0) {
                    res = MODE_SEARCH;
                } else if (this.loggedIn && this.showFavorites) {
                    res = MODE_FAVORITES;
                }
                return res;
            },
            getTags() {
                let res = [];
                switch (this.mode) {
                    case MODE_SEARCH:
                        res = this.getTagsSearchResult();
                        break;
                    case MODE_FAVORITES:
                        res = this.getTagsFavorites();
                        break;
                    case MODE_TOP:
                        res = this.getTagsTop();
                        break;
                }
                return res;
            },
            getTagsSearchResult() {
                return this.listSearchResult.slice();
            },
            getTagsTop() {
                return this.tagTop.slice();
            },
            getTagsFavorites() {
                // все что есть в избранном, добавляем в начало списка валют
                let res = this.tagFavorites.slice();
                // добавляем из ТОПа недостающее количество валют, которых нет в избранном
                for (let i = 0; i < this.tagTop.length; i++) {
                    if (this.tagFavorites.length == this.tagTop.length) break;
                    if (this.favoritesMap[this.tagTop[i].id] !== true) {
                        res.push(this.tagTop[i]);
                    }
                }
                return res;
            },
            isActive (tagId) {
                return !!this.activeTag && this.activeTag.id == tagId;
            }
        }
    }
</script>