import {LOCALE_SET} from "@/store/mutation-types";
import {findLocale, hasLocale} from "@/store/directory/locales"
// import api from "@/api"

const DEFAULT_LOCALE_ID = 1;

const state = {
    localeId: DEFAULT_LOCALE_ID,
};

const getters = {
    currentLocale (state) {
        let matchedLocale = findLocale(state.localeId);
        if (matchedLocale) {
            return matchedLocale;
        }
        return findLocale(DEFAULT_LOCALE_ID);
    },
};

const mutations = {
    [LOCALE_SET] (state, value) {
        let localeId = Number.parseInt(value);
        if (hasLocale(localeId)) {
            state.localeId = localeId;
        }
    },
};

const actions = {
    selectLocale (store, localeId) {
        store.commit(LOCALE_SET, localeId);
    },
    setLocale (store, localeId) {
        store.commit(LOCALE_SET, localeId);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}