<template>
    <div class="field-set">
        <div class="field-set__row">
            <div class="field-set__title">{{ $t('auth.TitleLogin') }}</div>
            <a class="button" href="#" v-on:click.prevent="showFormCreateAccount">{{ $t('auth.LinkCreateAccount') }}</a>
        </div>

        <form class="field-set-form">

            <field-email
                server-field-name="Email"
                v-model="$v.email.$model"
                v-on:input="formTouch"
                v-bind:label="$t('auth.LabelEmail')"
                v-bind:vuelidate-field="$v.email"
                v-bind:form-errors="formErrors"
                v-bind:form-dirty="formDirty"
            ></field-email>

            <field-password
                server-field-name="Password"
                v-model="$v.password.$model"
                v-on:input="formTouch"
                v-bind:label="$t('auth.LabelPassword')"
                v-bind:vuelidate-field="$v.password"
                v-bind:form-errors="formErrors"
                v-bind:form-dirty="formDirty"
            ></field-password>

            <div class="field-set__row">
                <button class="button" v-on:click.prevent="formSubmit">{{ $t('auth.SubmitLogin') }}</button>
                <a class="field-set-form__link" href="#" v-on:click.prevent="showFormRestore">{{ $t("auth.LinkForgotPassword") }}</a>
            </div>
        </form>
        <div class="field-set-social">
            <div class="field-set__title">{{ $t('auth.TitleLoginWithSocialAccount') }}</div>
            <social-login-block></social-login-block>
        </div>
    </div>
</template>
<script>
    import API from './../../api'
    import {mapActions} from 'vuex'
    import FormMixin from './../../mixins/form'
    import FieldEmail from './../ui/field-email.vue'
    import FieldPassword from './../ui/field-password.vue'
    import SocialLoginBlock from './social-login-block.vue'
    import {required, email, minLength} from 'vuelidate/lib/validators'

    export default {
        name: 'form-login',
        mixins: [FormMixin],
        components: {
            FieldEmail,
            FieldPassword,
            SocialLoginBlock
        },
        validations: {
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(5)
            }
        },
        props: {
            mobileMode: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                email: '',
                password: '',
            }
        },
        methods: {
            ...mapActions('auth', [
                'logIn'
            ]),
            formRequest() {
                return API.account.logIn({
                    email: this.email,
                    password: this.password,
                });
            },
            formProcessSuccessResponse() {
                this.logIn();
                this.$router.push({name: 'home'});
            },
            showFormCreateAccount () {
                this.$emit('show-form-create-account');
            },
            showFormRestore () {
                this.$emit('show-form-restore');
            }
        }
    }
</script>