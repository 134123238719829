<template>
    <svg v-on:click="click"
         v-bind:data-star="active"
         class="currency-widget-search__star"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <use xlink:href="#icon-star"></use>
    </svg>
</template>
<script>
    export default {
        name: 'star-icon',
        props: {
            active: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            click (event) {
                this.$emit('click', event);
            }
        }
    }
</script>