<template>
    <div class="field-set-social__item" style="background: #50abf1">
        <svg class="field-set-social__icon" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="19">
            <use xlink:href="#icon-tw"></use>
        </svg>
        <a class="field-set-social__link" v-bind:href="link"></a>
    </div>
</template>
<script>
    export default {
        name: 'oauth-twitter',
        props: {
            link: {
                type: String,
                require: true
            }
        }
    }
</script>