export default {
    ranges: {
        "day": "d",
        "month": "m",
        "year": "y",
        "all": "All",
    },
    weekdays: {
        "Sunday": "Sunday",
        "Monday": "Monday",
        "Tuesday": "Tuesday",
        "Wednesday": "Wednesday",
        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday"
    },
    shortWeekdays: {
        "Sunday": "Su",
        "Monday": "Mo",
        "Tuesday": "Tu",
        "Wednesday": "We",
        "Thursday": "Th",
        "Friday": "Fr",
        "Saturday": "Sa"
    },
    months: {
        "January": "January",
        "February": "February",
        "March": "March",
        "April": "April",
        "May": "May",
        "June": "June",
        "July": "July",
        "August": "August",
        "September": "September",
        "October": "October",
        "November": "November",
        "December": "December"
    },
    shortMonths: {
        "Jan": "Jan",
        "Feb": "Feb",
        "Mar": "Mar",
        "Apr": "Apr",
        "May": "May",
        "Jun": "Jun",
        "Jul": "Jul",
        "Aug": "Aug",
        "Sep": "Sep",
        "Oct": "Oct",
        "Nov": "Nov",
        "Dec": "Dec"
    },
    menu: {
        "News": "News",
        "Coins": "Coins",
        "Trends": "Trends",
        "About": "About",
        "Profile": "Profile",
        "Login": "Login",
        "Signup": "Signup",
    },
    global: {
        "Share": "Share",
        "Currency": "Currency",
        "Country": "Country",
        "Countries": "Countries",
        "CountriesAll": "All countries",
        "Tag": "Tag",
        "Trends": "Trends",
        "TrendsAll": "All trends",
        "Filter": "Filter",
        "NewsFilter": "News filter",
        "NewsLanguage": "News language",
        "NewsVoteType": "News type",
        "NewsSourceType": "Source type",
        "Selected": "Selected",
        "SelectedEmpty": "No selected records",
        "SearchEmpty": "No results found",
        "Currencies": "Currencies",
        "CurrenciesAll": "All currencies",
        "Sources": "Sources",
        "SourcesAll": "All sources",
        "Search": "Search",
        "Clear": "Clear",
        "DismissAll": "Dismiss all",
        "Back": "Back",
        "Ok": "Ok",
        "Types": "Types",
        "All": "All",
        "News": "News",
        "Social": "Social",
        "Twitter": "Twitter",
        "Reddit": "Reddit",
        "Facebook": "Facebook",
        "Instagram": "Instagram",
        "Langs": "Langs",
        "English": "English",
        "Russian": "Russian",
        "Chinese": "Chinese",
        "Top": "Top",
        "ShowTop": "Show Top",
        "SearchTagPlaceholder": "To find more use search",
        "SearchCurrencyPlaceholder": "To find more use search",
        "SearchSourcePlaceholder": "To find more use search",
        "SearchCountryPlaceholder": "To find more use search",
        "PriceUsd": "Price USD",
        "PriceBtc": "Price BTC",
        "VolumeUsd24h": "Volume USD 24h",
        "LoadingData": "Loading Data ...",
        "DataSaved": "Data Saved",
        "MessageChangeSaved": "Change Saved",
        "Save": "Save",
        "Logout": "Logout",
        "RequestFailed": "Request failed",
    },
    loadMore: {
        "NoResults": "No content found by specified filters",
        "NoMore": "You've reached the end!",
    },
    filter: {
        "MyFeed": "My feed",
        "MustLoginToUserMyFeed": "You must login to use feed",
        "Bullish": "Bullish",
        "Bearish": "Bearish",
        "Positive": "Positive",
        "Negative": "Negative",
        "Important": "Important",
        "Trend": "Trend",
        "SaveToMyFeed": "Save changes",
        "Saved": "Saved",
    },
    account: {
        "MenuSettings": "Settings",
        "MenuMyFeed": "My feed",
        "MenuPortfolio": "Portfolio",
        "MenuConnectedAccounts": "Connected Accounts",
        "TitleAccountSettings": "Account Settings",
        "TitleAccountConnections": "Account Connections",
        "TitleAddNewAccounts": "Add new accounts",
        "TitleTelegramBotSettings": "Telegram bot Settings for automatically publish news from your feed into your telegram channel.",
        "TitleTelegramBotManual": "Manual",
        "NoteYouCanUseSocialAccountForLogin": "You can sign in to your account using any of the following third party accounts",
        "LabelEmailIsNotConfirmed": "Email is not confirmed!",
        "ButtonResendConfirmation": "Resend confirmation",
        "MessageResendConfirmationSuccess": "We have sent you an e-mail for confirmation",
        "MessageUserAccountRemoved": "User account removed",
        "MessageUserAccountRemoveFailed": "Failed to delete account",
        "Remove": "Remove",
        "BotEnabled": "Enabled",
        "BotManualStep1": '1. Invite bot <a href="https://t.me/IntwtComBot" target="_blank">@IntwtComBot</a> as admin with "Post Messages" rights into your channel.',
        "BotManualStep2": '2. Use <code>/intwt id</code> command in your channel to find out exact ID to use for <code>Telegram ID</code> field. Chat ID format will be something like: -100500.',
    },
    content: {
        "Source": "Source",
        "Tags": "Tags",
        "WTF": "What do you think is this news like?",
        "Bullish": "Bullish",
        "Bearish": "Bearish",
        "Positive": "Positive",
        "Negative": "Negative",
        "Important": "Important",
        "Trend": "Trend",
        "MustLoginToVote": "You must login first to vote",
    },
    tag: {
        "MustLoginToSaveFavorites": "You must login to save favorites",
        "SearchPlaceholder": "Search currency",
        "TableColumnName": "Name",
        "ShowNews": "Show news",
        "Close": "Close",
    },
    currency: {
        "MustLoginToSaveFavorites": "You must login to save favorites",
        "SearchPlaceholder": "Search currency",
        "TableColumnCoin": "Coin",
        "TableColumnPrice": "Price $",
        "TableColumnPriceBTC": "Price BTC",
        "TableColumnChange24h": "Change (24h)",
        "TableColumnMarketCap": "Market Cap",
        "TableColumnVolume24h": "Volume (24h)",
        "ShowNews": "Show news",
        "Close": "Close",
        "LabelMarketCap": "Market Cap",
        "LabelVolume24h": "Volume (24h)",
        "LabelCirculatingSupply": "Circulating Supply",
        "LabelTotalSupply": "Total Supply",
        "LabelMaxSupply": "Max Supply",
    },
    auth: {
        "TitleNewPassword": "Change Password",
        "TitleLogin": "Login",
        "TitleRestore": "Password Reset",
        "TitleCreateAccount": "Create Account",
        "TitleVerify": "Email confirmation",
        "TitleLoginWithSocialAccount": "Or login with your social network account",
        "NoteSignup": "Sign up with email or using one of your social network account",
        "LinkLogin": "Login",
        "LinkCreateAccount": "Create account",
        "LinkForgotPassword": "Forgot password?",
        "LabelName": "Name",
        "LabelEmail": "E-mail",
        "LabelPassword": "Password",
        "LabelPasswordConfirm": "Confirm Password",
        "LabelNewPassword": "New Password",
        "LabelNewPasswordConfirm": "New Password (confirm)",
        "SubmitLogin": "Login",
        "SubmitPasswordReset": "Reset password",
        "SubmitChangePassword": "Change password",
        "SubmitCreateAccount": "Sign up",
        "MessageChangePasswordSuccess": "New password saved",
        "MessagePasswordResetSuccess": "We have sent you an email with the information to reset your password",
        "MessageVerifySuccess": "Your email verified!",
    },
    errors: {
        "InvalidRequest": "Invalid request",
        "SystemError": "Request failed, try later",
        "Required": "This field is required",
        "EmailInvalid": "Email is invalid",
        "EmailNotFound": "Email not found",
        "EmailAlreadyExist": "Email already exist",
        "PasswordLength": "The minimum password length is 5 characters",
        "PasswordConfirm": "You must type the same password each time",
        "InvalidEmailOrPassword": "Invalid Email or Password",
        "EmailNotVerified": "Your account is not confirmed! Please check your incoming emails and verify account.",
        "TokenInvalid": "Token invalid",
    },
    portfolio: {
        "MustLoginToUsePortfolio": "You must login to use portfolio",
        "MyCryptoPortfolio": "My crypto portfolio",
        "Portfolio": "Potrfolio",
        "Currency": "Currency",
        "Amount": "Amount",
        "ValueUSD": "Value USD",
        "PriceUSD": "Price USD",
        "Change24hUSD": "Change USD % 24h",
        "ValueBTC": "Value BTC",
        "PriceBTC": "Price BTC",
        "Change24hBTC": "Change BTC % 24h",
        "Label": "Label",
        "NoRows": "There are no currencies in your portfolio. To add currencies to the portfolio, click «Add new row»",
        "AddNewRow": "Add new row",
        "SaveChanges": "Save changes",
        "Cancel": "Cancel",
        "Chart": "Chart",
        "LinkShowChart": "Show chart",
        "LinkShowTable": "Show table",
        "DeleteConfirm": "Delete entity?",
        "HistoryEmpty": "History is empty",
        "LinkClearHistory": "Clear history",
        "HistoryClearConfirm": "Clear history confirm?"
    },
    about: {
        "MainText": '<p>Presented in the form of timelines, NWS.One service will show information received from One.Platform in an easy and understandable form.</p>\
<p>Using Machine Learning, the platform will be capable of self-learning and analyzing information flows, which will help to show hidden connections between obtained facts and historical background for their change. Also, based on that analysis, the platform will be able to create predictions for interesting events and data.</p>\
<p>Those algorithms, joined with modern technologies, will allow to transparently filter obtained information from false facts, thus becoming a base to create an everyday tool for reading news, understand them, discuss and gain access for expert analysis and independent observers opinion.</p>',
    },
}