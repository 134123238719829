<template>
    <filter-select-component
            name="lang"
            v-bind:label="label"
            v-bind:items="items"
            v-model="selected"
            v-on:dismiss-all="clearLang"
    ></filter-select-component>
</template>
<script>
    import FilterSelectComponent from './filter-select-component.vue'
    import DirectoryContentLangs from './../../store/directory/content-langs'

    export default {
        name: 'filter-select-lang',
        components: {
            FilterSelectComponent,
        },
        props: {
            langSelected: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                items: DirectoryContentLangs.all()
            }
        },
        computed: {
            selected: {
                get() {
                    return this.langSelected;
                },
                set(val) {
                    this.setLang(val);
                }
            },
            label () {
                return this.$t('global.NewsLanguage');
            }
        },
        methods: {
            setLang (val) {
                this.$emit('select', val);
            },
            clearLang () {
                this.$emit('clear');
            }
        }
    }
</script>