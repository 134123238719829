<template>
    <div class="field-set field-set_second">

        <div class="field-set__title">{{ $t('account.TitleAccountConnections') }}</div>
        <div class="field-set__text">{{ $t('account.NoteYouCanUseSocialAccountForLogin') }}</div>

        <div v-if="itemsConnected.length > 0" class="field-set-connect">
            <div v-for="(item, index) in itemsConnected"
                 v-bind:key="index"
                 class="field-set-connect__item">
                <icon-oauth v-bind:icon="item.provider.type"
                            v-bind:link="item.provider.link"></icon-oauth>
                <div class="field-set-connect__name">{{ filterAccountName(item.account) }}</div>
                <a class="button" href="#"
                   v-on:click="onRemoveAccount(item.account)">{{ $t('account.Remove') }}</a>
            </div>
        </div>

        <div v-if="itemsNotConnected.length > 0" class="field-set-social">
            <div class="field-set__title">{{ $t('account.TitleAddNewAccounts') }}</div>
            <div class="field-set-social__list">
                <icon-oauth v-for="(item, index) in itemsNotConnected"
                            v-bind:key="index"
                            v-bind:icon="item.provider.type"
                            v-bind:link="item.provider.link"></icon-oauth>
            </div>
        </div>
    </div>
</template>
<script>
    import API from './../../api'
    import FormMixin from './../../mixins/form'
    import IconOauth from './../icons/oauth.vue'
    import accountProviders from "./../../store/directory/account-providers"
    import loFindIndex from 'lodash/findIndex'
    import loFilter from 'lodash/filter'

    export default {
        name: 'form-providers',
        mixins: [FormMixin],
        components: {
            IconOauth
        },
        data() {
            return {
                providers: accountProviders,
                accounts: [],
                itemsConnected: [],
                itemsNotConnected: [],
            }
        },
        watch: {
            accounts () {
                let items = this.prepareItems(this.providers, this.accounts);
                this.itemsConnected = this.prepareItemsConnected(items);
                this.itemsNotConnected = this.prepareItemsNotConnected(items);
            }
        },
        methods: {
            setData (data) {
                this.accounts = data.accounts;
            },
            formLoadData () {
                API.account.data().then((response) => {
                    this.accounts = response.data.user.accounts;
                });
            },
            onRemoveAccount (account) {
                API.account.oauthRemove({userAccountId: account.id}).then(() => {
                    this.notifySuccess(this.$t('account.MessageUserAccountRemoved'));
                    this.formLoadData();
                }).catch(() => {
                    this.notifyError(this.$t('account.MessageUserAccountRemoveFailed'))
                });
            },
            findUserAccount (provider, accounts) {
                let found = loFindIndex(accounts, function (account) {
                    return account.provider == provider.type
                });
                return found > -1 ? accounts[found] : null;
            },
            filterAccountName (account) {
                let accountName = account.name;
                if (accountName == "") {
                    accountName = account.first_name;
                    if (accountName == "") {
                        accountName = account.last_name;
                    } else {
                        accountName += " " + account.last_name;
                    }
                }
                return accountName
            },
            prepareItems (providers, accounts) {
                return providers.map((provider) => {
                    return {
                        provider: provider,
                        account: this.findUserAccount(provider, accounts)
                    }
                });
            },
            prepareItemsConnected (items) {
                return loFilter(items, (item) => null !== item.account);
            },
            prepareItemsNotConnected (items) {
                return loFilter(items, (item) => null === item.account);
            }
        }
    }
</script>