<template>
    <div class="content">
        <icons></icons>
        <header-view v-bind:scrolled-down="scrolledDown"></header-view>

        <slot name="header-line"></slot>

        <div class="content__body">
            <sidebar-view v-if="sidebarVisible"
                          v-bind:mobileMode="mobileMode"
                          v-bind:portfolio-visible="sidebarPortfolioVisible"
                          v-bind:active-currency="currency"></sidebar-view>
            <slot></slot>
        </div>

        <vue-snotify></vue-snotify>
        <loader-view></loader-view>
    </div>
</template>
<script>
    import Icons from './icons.vue'
    import HeaderView from './header.vue'
    import SidebarView from './sidebar.vue'
    import LoaderView from './../components/ui/loader.vue'

    export default {
        name: 'layout',
        components: {
            Icons,
            HeaderView,
            SidebarView,
            LoaderView
        },
        props: {
            mobileMode: {
                type: Boolean,
                default: false
            },
            scrolledDown: {
                type: Boolean,
                default: false
            },
            sidebarVisible: {
                type: Boolean,
                default: true
            },
            sidebarPortfolioVisible: {
                type: Boolean,
                default: true
            },
            currency: {
                type: Object
            }
        }
    }
</script>