<template>
    <div class="currency-detail__wrap">
        <div class="currency-detail">

            <div class="currency-detail__row">
                <div class="currency-detail__col">
                    <div class="currency-detail-title__wrap">
                        <div class="currency-detail-title">{{ currencyFullName }}</div>
                        <div v-if="visibleCloseButton"
                             v-on:click.prevent="closeCurrency"
                             class="currency-detail-title__close"></div>
                        <a v-if="hasShowNewsButton"
                           v-on:click.prevent="showNews"
                           class="button"
                           href="#news">{{ $t('currency.ShowNews') }} {{ item.symbol }}</a>
                    </div>
                    <div class="currency-detail__cap">#{{ item.rank }} / <a
                            class="currency-detail__cap_link"
                            v-bind:target="mobileMode ? '_self':'_blank'"
                            v-bind:href="coinmarketcapLink">coinmarketcap.com</a></div>
                </div>
                <table class="currency-detail-price">
                    <currency-detail-price v-bind:percent="item.percent_change_24h_usd" big>${{ item.price_usd | toCurrency }}</currency-detail-price>
                    <currency-detail-price v-bind:percent="item.percent_change_24h_btc">{{ item.price_btc | toCurrency }} BTC</currency-detail-price>
                </table>
            </div>

            <currency-detail-indicators v-bind:currency="item"></currency-detail-indicators>
        </div>

        <portfolio-widget
            v-bind:filter-currency="item"
            v-bind:mobile-mode="mobileMode"
            class="currency-detail portfolio"></portfolio-widget>

        <div class="currency-detail" style="padding-top: 0;">
            <currency-detail-chart v-bind:item="item"></currency-detail-chart>
        </div>

        <slot name="news"></slot>
    </div>
</template>
<script>
    import CurrencyDetailPrice from './currency-detail-price.vue'
    import CurrencyDetailIndicators from './currency-detail-indicators.vue'
    import CurrencyDetailChart from './currency-detail-chart.vue'
    import PortfolioWidget from './../portfolio/portfolio-widget.vue'

    export default {
        name: 'currency-detail',
        components: {
            CurrencyDetailPrice,
            CurrencyDetailIndicators,
            CurrencyDetailChart,
            PortfolioWidget
        },
        props: {
            item: {
                type: Object,
                require: true
            },
            mobileMode: {
                type: Boolean,
                required: true
            },
            hasShowNewsButton: {
                type: Boolean,
                default: false
            },
            hasCloseButton: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            coinmarketcapLink () {
                return 'https://coinmarketcap.com/currencies/' + this.item.slug + '/';
            },
            currencyFullName() {
                return this.item.symbol + '/' + this.item.name;
            },
            visibleCloseButton() {
                return this.hasCloseButton && this.mobileMode;
            }
        },
        methods: {
            showNews() {
                this.$emit('show-news')
            },
            closeCurrency () {
                this.$emit('close-currency');
            },
        }
    }
</script>