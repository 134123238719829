<template>
    <div class="field-set">
        <div class="field-set__row">
            <div class="field-set__title">{{ $t('auth.TitleRestore') }}</div>
            <a class="button" href="#" v-on:click.prevent="showFormCreateAccount">{{ $t('auth.LinkCreateAccount') }}</a>
        </div>

        <form class="field-set-form">

            <field-email
                    server-field-name="Email"
                    v-model="$v.email.$model"
                    v-on:input="formTouch"
                    v-bind:label="$t('auth.LabelEmail')"
                    v-bind:vuelidate-field="$v.email"
                    v-bind:form-errors="formErrors"
                    v-bind:form-dirty="formDirty"
            ></field-email>

            <field-recaptcha
                    ref="formRecaptcha"
                    server-field-name="Recaptcha"
                    v-bind:vuelidate-field="$v.recaptcha"
                    v-bind:form-errors="formErrors"
                    v-on:verify="formRecaptchaOnVerify"
                    v-bind:form-dirty="formDirty"
            ></field-recaptcha>

            <div class="field-set__row">
                <button class="button" v-on:click.prevent="formSubmit">{{ $t('auth.SubmitPasswordReset') }}</button>
                <a class="field-set-form__link" href="#" v-on:click.prevent="showFormLogin">{{ $t("auth.LinkLogin") }}</a>
            </div>
        </form>
    </div>
</template>
<script>
    import API from './../../api'
    import FormMixin from './../../mixins/form'
    import FieldEmail from './../ui/field-email.vue'
    import FieldRecaptcha from './../ui/field-recaptcha.vue'
    import {required, email} from 'vuelidate/lib/validators'

    export default {
        name: 'form-restore',
        mixins: [FormMixin],
        components: {
            FieldEmail,
            FieldRecaptcha
        },
        validations: {
            email: {
                required,
                email
            },
            recaptcha: {
            }
        },
        data() {
            return {
                email: ''
            }
        },
        methods: {
            showFormCreateAccount () {
                this.$emit('show-form-create-account');
            },
            showFormLogin () {
                this.$emit('show-form-login');
            },
            formRequest() {
                return API.account.restore({
                    email: this.email,
                    recaptcha: this.recaptcha
                });
            },
            formProcessSuccessResponse() {
                this.notifySuccess(this.$t('auth.MessagePasswordResetSuccess'));
            }
        }
    }
</script>