import ViewHome from "./../../views/home.vue";

export default [
    {
        path: '/',
        name: 'home',
        component: ViewHome,
        children: [
            {
                path: '/post/:slug',
                name: 'content_item',
                component: ViewHome,
            },
        ]
    },
];