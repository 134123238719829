<template>
    <nav class="header-menu">
        <nav-item v-for="(item, index) in elements"
                   v-bind:key="index"
                   v-bind:title="item.title"
                   v-bind:icon="item.icon"
                   v-bind:to="item.to"
                   v-bind:matches="item.matches"
        >{{ $t(item.title) }}</nav-item>
    </nav>
</template>
<script>
    import {mapState} from 'vuex'
    import NavItem from './nav-item.vue'

    export default {
        name: 'nav-view',
        components: {
            NavItem,
        },
        data() {
            return {
                items: [
                    {
                        title: 'menu.News',
                        to: {name: 'home'},
                        matches: ['home', 'content_item', 'currency', 'currency_content_item'],
                        icon: 'nav-news'
                    },
                    {
                        title: 'menu.Trends',
                        to: {name: 'tags'},
                        matches: ['tags', 'tags_item', 'tags_content_item'],
                        icon: 'nav-coins'
                    },
                    {
                        title: 'menu.About',
                        to: {name: 'about'},
                        matches: ['about'],
                        icon: 'nav-about'
                    },
                    {
                        title: 'menu.Profile',
                        to: {name: 'account'},
                        matches: ['account', 'account_feed', 'account_portfolio'],
                        icon: 'nav-login',
                        loginRequire: true
                    },
                    {
                        title: 'menu.Login',
                        to: {name: 'auth'},
                        matches: ['auth'],
                        icon: 'nav-login',
                        logoutRequire: true
                    },
                ]
            }
        },
        computed: {
            ...mapState('auth', [
                'loggedIn'
            ]),
            elements () {
                let _items = [];

                for (let i = 0; i < this.items.length; i++) {
                    let item = this.items[i];
                    if ((item.loginRequire === true && !this.loggedIn) || (item.logoutRequire === true && this.loggedIn)) {
                        continue;
                    }
                    _items.push(item);
                }

                return _items;
            }
        },
    }
</script>