<template>
    <div class="field-set">
        <div class="field-set__row">
            <div class="field-set__title">{{ $t('account.TitleAccountSettings') }}</div>
            <a href="#logout" class="field-set__title field-set-form__link" v-on:click.prevent="onLogout">{{ $t('global.Logout') }}</a>
        </div>
        <form class="field-set-form" action="#">
            <field-input
                    server-field-name="Name"
                    v-model="$v.username.$model"
                    v-on:input="formTouch"
                    v-bind:label="$t('auth.LabelName')"
                    v-bind:vuelidate-field="$v.username"
                    v-bind:form-errors="formErrors"
                    v-bind:form-dirty="formDirty"
            ></field-input>

            <field-email
                server-field-name="Email"
                v-model="$v.email.$model"
                v-on:input="formTouch"
                v-bind:label="$t('auth.LabelEmail')"
                v-bind:vuelidate-field="$v.email"
                v-bind:form-errors="formErrors"
                v-bind:form-dirty="formDirty">
                <template slot="notes">
                    <div class="field__notes"
                         v-show="!emailVerified" style="display: block;">{{ $t('account.LabelEmailIsNotConfirmed') }}
                    </div>
                </template>
            </field-email>

            <field-password
                server-field-name="Password"
                v-model="$v.password.$model"
                v-on:input="formTouch"
                v-bind:label="$t('auth.LabelNewPassword')"
                v-bind:vuelidate-field="$v.password"
                v-bind:form-errors="formErrors"
                v-bind:form-dirty="formDirty"
            ></field-password>

            <field-password
                server-field-name="PasswordConfirm"
                v-model="$v.passwordConfirm.$model"
                v-on:input="formTouch"
                v-bind:label="$t('auth.LabelNewPasswordConfirm')"
                v-bind:vuelidate-field="$v.passwordConfirm"
                v-bind:form-errors="formErrors"
                v-bind:form-dirty="formDirty"
            ></field-password>

            <div class="field-set__row">
                <button class="button" v-on:click.prevent="formSubmit">{{ $t('global.Save') }}</button>

                <a v-if="!emailVerified"
                   v-on:click.prevent="onResendConfirmation"
                   class="field-set-form__link"
                   href="#">{{ $t('account.ButtonResendConfirmation') }}</a>
            </div>
        </form>
    </div>
</template>
<script>
    import API from './../../api'
    import FormMixin from './../../mixins/form'
    import FieldInput from './../ui/field-input.vue'
    import FieldPassword from './../ui/field-password.vue'
    import FieldEmail from './../ui/field-email.vue'
    import {email, minLength, sameAs} from 'vuelidate/lib/validators'

    export default {
        name: 'form-settings',
        mixins: [FormMixin],
        components: {
            FieldInput,
            FieldEmail,
            FieldPassword,
        },
        validations: {
            username: {},
            email: {
                email
            },
            password: {
                minLength: minLength(5)
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            }
        },
        data() {
            return {
                username: '',
                email: '',
                emailVerified: true,
                password: '',
                passwordConfirm: ''
            }
        },
        computed: {
            formData() {
                return {
                    name: this.username,
                    email: this.email,
                    password: this.password,
                    password_confirm: this.passwordConfirm
                }
            }
        },
        methods: {
            setData (data) {
                this.username = data.username;
                this.email = data.email;
                this.emailVerified = data.emailVerified;
            },
            formRequest () {
                return API.account.update(this.formData);
            },
            formLoadData () {
                this.password = '';
                this.passwordConfirm = '';
                API.account.data().then((response) => {
                    if (response.data.user) {
                        this.username = response.data.user.name;
                        this.email = response.data.user.email;
                        this.emailVerified = response.data.user.email_verified;
                    }
                });
            },
            formProcessSuccessResponse() {
                this.formLoadData();
                this.notifySuccess(this.$t('global.MessageChangeSaved'));
            },
            onLogout() {
                this.$emit('logout');
            },
            onResendConfirmation() {
                API.account.resendConfirmation().then(() => {
                    this.notifySuccess(this.$t('account.MessageResendConfirmationSuccess'));
                }).catch(() => {
                    this.notifyError(this.$t('errors.SystemError'));
                });
            }
        }
    }
</script>