import numbro from "numbro";

require('numbro/languages/ru-RU');
require('numbro/languages/zh-CN');

function setNumbroLocale(locale) {
    switch (locale) {
        case 'ru':
            numbro.setLanguage('ru-RU');
            break;
        case 'cn':
            numbro.setLanguage('zh-CN');
            break;
        case 'en':
            numbro.setLanguage('en-US');
            break;
    }
}

export { setNumbroLocale }