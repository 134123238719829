<template>
    <filter-select-component
            name="source-type"
            v-bind:label="label"
            v-bind:items="items"
            v-model="selected"
            v-on:dismiss-all="clearSourceType"
    ></filter-select-component>
</template>
<script>
    import FilterSelectComponent from './filter-select-component.vue'
    import DirectorySourceTypes from './../../store/directory/source-types'

    export default {
        components: {
            FilterSelectComponent
        },
        props: {
            sourceTypeSelected: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                items: DirectorySourceTypes.all(),
            }
        },
        computed: {
            selected: {
                get() {
                    return this.sourceTypeSelected;
                },
                set(val) {
                    this.setSourceType(val);
                }
            },
            label () {
                return this.$t('global.NewsSourceType');
            }
        },
        methods: {
            setSourceType (val) {
                this.$emit('select', val);
            },
            clearSourceType () {
                this.$emit('clear');
            },
        }
    }
</script>