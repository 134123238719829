import {
    PORTFOLIO_SET_TABLE_VISIBLE_AMOUNT,
    PORTFOLIO_SET_TABLE_VISIBLE_VALUE_USD,
    PORTFOLIO_SET_TABLE_VISIBLE_PRICE_USD,
    PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_USD,
    PORTFOLIO_SET_TABLE_VISIBLE_VALUE_BTC,
    PORTFOLIO_SET_TABLE_VISIBLE_PRICE_BTC,
    PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_BTC,
    PORTFOLIO_SET_TABLE_SORT_COLUMN_NAME,
    PORTFOLIO_SET_TABLE_SORT_COLUMN_DESC,
} from "./../../store/mutation-types";
import parseBoolean from "./../../utils/parse-boolean"

const state = {
    tableVisibleAmount: true,
    tableVisibleValueUsd: true,
    tableVisiblePriceUsd: true,
    tableVisibleChange24hUsd: true,
    tableVisibleValueBtc: true,
    tableVisiblePriceBtc: true,
    tableVisibleChange24hBtc: true,
    tableSortColumnName: "",
    tableSortColumnDesc: false,
};

const getters = {};

const mutations = {
    [PORTFOLIO_SET_TABLE_VISIBLE_AMOUNT] (state, value) {
        state.tableVisibleAmount = value;
    },
    [PORTFOLIO_SET_TABLE_VISIBLE_VALUE_USD] (state, value) {
        state.tableVisibleValueUsd = value;
    },
    [PORTFOLIO_SET_TABLE_VISIBLE_PRICE_USD] (state, value) {
        state.tableVisiblePriceUsd = value;
    },
    [PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_USD] (state, value) {
        state.tableVisibleChange24hUsd = value;
    },
    [PORTFOLIO_SET_TABLE_VISIBLE_VALUE_BTC] (state, value) {
        state.tableVisibleValueBtc = value;
    },
    [PORTFOLIO_SET_TABLE_VISIBLE_PRICE_BTC] (state, value) {
        state.tableVisiblePriceBtc = value;
    },
    [PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_BTC] (state, value) {
        state.tableVisibleChange24hBtc = value;
    },
    [PORTFOLIO_SET_TABLE_SORT_COLUMN_NAME] (state, value) {
        state.tableSortColumnName = value;
    },
    [PORTFOLIO_SET_TABLE_SORT_COLUMN_DESC] (state, value) {
        state.tableSortColumnDesc = parseBoolean(value);
    },
};

const actions = {
    setTableVisibleAmount (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_VISIBLE_AMOUNT, value);
    },
    setTableVisibleValueUsd (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_VISIBLE_VALUE_USD, value);
    },
    setTableVisiblePriceUsd (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_VISIBLE_PRICE_USD, value);
    },
    setTableVisibleChange24hUsd (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_USD, value);
    },
    setTableVisibleValueBtc (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_VISIBLE_VALUE_BTC, value);
    },
    setTableVisiblePriceBtc (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_VISIBLE_PRICE_BTC, value);
    },
    setTableVisibleChange24hBtc (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_BTC, value);
    },
    setTableSortColumnName (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_SORT_COLUMN_NAME, value);
    },
    setTableSortColumnDesc (store, value) {
        store.commit(PORTFOLIO_SET_TABLE_SORT_COLUMN_DESC, value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}