<template>
    <div class="settings-item">
        <div class="settings-item__header">
            <div class="settings-item__row">
                <div class="settings-item__title">{{ label }}</div>
                <div v-if="selected.length > 0"
                     v-on:click.prevent="dismissAll"
                     class="dismiss-all">
                    {{ $t('global.DismissAll') }}
                    <div class="dismiss-all__button"></div>
                </div>
            </div>
        </div>

        <checkbox-list
                v-if="items.length > 0"
                v-model="selected"
                v-bind:name="name"
                v-bind:items="items"
                class="settings-item__row"
                item-class="settings-item__col"
                data-expand
        ></checkbox-list>
    </div>
</template>
<script>
    import CheckboxList from './../ui/checkbox-list.vue'

    export default {
        name: 'filter-select-component',
        components: {
            CheckboxList
        },
        props: {
            name: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                required: true
            },
            value: {
                type: Array,
                default: () => []
            },
        },
        computed: {
            selected: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val);
                }
            },
        },
        methods: {
            dismissAll() {
                this.$emit('dismiss-all');
            }
        }
    }
</script>