<template>
    <th v-bind:class="columnClass">
        <a v-bind:href="columnHref" v-on:click.prevent="toggleOrder"><slot></slot></a>
    </th>
</template>
<script>
    import CurrencyTableStoreMixin from "./../../mixins/currency-table-store"

    export default {
        name: "currency-table-head-item",
        mixins: [CurrencyTableStoreMixin],
        props: {
            columnName: {
                type: String,
                required: true
            },
        },
        computed: {
            columnHref () {
                return '#' + this.columnName
            },
            columnClass () {
                if (this.columnName == this.currencyTableOrder) {
                    return this.currencyTableDesk == 1
                        ? ['table__sort', 'table__sort-down']
                        : ['table__sort', 'table__sort-up']
                }
                return ['table__sort'];
            },
        },
        methods: {
            toggleOrder () {
                if (this.currencyTableOrder == this.columnName) {
                    // 3 click
                    if (this.currencyTableDesk == 1) {
                        this.currencyTableSetOrder("");
                        this.currencyTableSetDesk(0);
                    }
                    // 2 click
                    else {
                        this.currencyTableSetDesk(1);
                    }
                }
                // 1 click
                else {
                    this.currencyTableSetOrder(this.columnName);
                    this.currencyTableSetDesk(0);
                }
            }
        }
    }
</script>