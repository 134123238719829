<template>
    <div class="portfolio">
        <div class="portfolio__title">{{ $t('portfolio.Chart') }}</div>

        <div v-if="this.historyItems.length > 0"
             class="chart-container" ref="portfolioChart"
             style="height: 400px; min-width: 310px;"></div>

        <div v-else class="table-settings">{{ $t('portfolio.HistoryEmpty') }}</div>

        <div class="portfolio__row">
            <div class="portfolio__row-buttons">
                <a v-on:click.prevent="onShowTable"
                   class="button button_cancel"
                   href="#table">{{ $t('portfolio.LinkShowTable') }}</a>

                <a v-if="this.historyItems.length > 0"
                   v-on:click.prevent="clearHistory"
                   class="button button_cancel"
                   href="#clear">{{ $t('portfolio.LinkClearHistory') }}</a>
            </div>
        </div>
    </div>
</template>
<script>
    /* global Highcharts */
    import API from './../../api'
    import {mapState, mapActions} from 'vuex'

    const KEY_TIMESTAMP = 'created';
    const KEY_VOLUME_USD = 'volumeUSD';
    // const KEY_VOLUME_BTC = 'volumeBTC';

    export default {
        name: "portfolio-chart",
        data() {
            return {
                chart: null
            }
        },
        created () {
            this.$nextTick(() => {
                this.refreshChart(this.historyItems);
            })
        },
        destroyed() {
            this.chart && this.chart.destroy();
            this.chart = null;
        },
        computed: {
            ...mapState('portfolioData', [
                'historyItems',
            ]),
        },
        methods: {
            ...mapActions('portfolioData', [
                'setHistoryItems'
            ]),
            onShowTable() {
                this.$emit('show-table');
            },
            clearHistory () {
                if (confirm(this.$t('portfolio.HistoryClearConfirm'))) {
                    API.portfolio.historyClear().then(() => {
                        this.chart && this.chart.destroy();
                        this.setHistoryItems([]);
                    }).catch(() => {
                        this.notifyError(this.$t('global.RequestFailed'));
                    });
                }
            },
            refreshChart(rawData) {
                this.chart && this.chart.destroy();
                this.chart = null;
                this.createChart(rawData)
            },
            createChart(rawData) {
                if (this.chart == null && this.historyItems.length > 0) {
                    let seriesOptions = this.prepareSeriesOptions(rawData);

                    Highcharts.setOptions({
                        time: {
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                        lang: {
                            months: [
                                this.$t('months.January'),
                                this.$t('months.February'),
                                this.$t('months.March'),
                                this.$t('months.April'),
                                this.$t('months.May'),
                                this.$t('months.June'),
                                this.$t('months.July'),
                                this.$t('months.August'),
                                this.$t('months.September'),
                                this.$t('months.October'),
                                this.$t('months.November'),
                                this.$t('months.December')
                            ],
                            shortMonths: [
                                this.$t('shortMonths.Jan'),
                                this.$t('shortMonths.Feb'),
                                this.$t('shortMonths.Mar'),
                                this.$t('shortMonths.Apr'),
                                this.$t('shortMonths.May'),
                                this.$t('shortMonths.Jun'),
                                this.$t('shortMonths.Jul'),
                                this.$t('shortMonths.Aug'),
                                this.$t('shortMonths.Sep'),
                                this.$t('shortMonths.Oct'),
                                this.$t('shortMonths.Nov'),
                                this.$t('shortMonths.Dec')
                            ],
                            weekdays: [
                                this.$t('weekdays.Sunday'),
                                this.$t('weekdays.Monday'),
                                this.$t('weekdays.Tuesday'),
                                this.$t('weekdays.Wednesday'),
                                this.$t('weekdays.Thursday'),
                                this.$t('weekdays.Friday'),
                                this.$t('weekdays.Saturday'),
                            ],
                            shortWeekdays: [
                                this.$t('shortWeekdays.Sunday'),
                                this.$t('shortWeekdays.Monday'),
                                this.$t('shortWeekdays.Tuesday'),
                                this.$t('shortWeekdays.Wednesday'),
                                this.$t('shortWeekdays.Thursday'),
                                this.$t('shortWeekdays.Friday'),
                                this.$t('shortWeekdays.Saturday'),
                            ],
                            rangeSelectorZoom: ''
                        }
                    });

                    this.chart = Highcharts.stockChart(this.$refs.portfolioChart, {
                        chart: {
                            type: "area",
                        },
                        series: seriesOptions,
                        legend: {
                            enabled: true
                        },
                        tooltip: {
                            shared: true,
                            split: false,
                            crosshairs: true,
                            shadow: false,
                            padding: 5,
                            borderWidth: 0,
                            borderRadius: 0,
                            snap: "1/2",
                            valueSuffix: ' USD',

                        },
                        scrollbar: {
                            enabled: false
                        },
                        navigator: {
                            adaptToUpdatedData: false,
                        },
                        rangeSelector: {
                            enabled: true,
                            inputEnabled: false,
                            verticalAlign: "top",
                            y: 12,
                            x: -20,
                            buttonPosition: {
                                align: "right"
                            },
                            buttons: [{
                                type: "day",
                                count: 1,
                                text: '1' + this.$t('ranges.day')
                            }, {
                                type: "day",
                                count: 7,
                                text: '7' + this.$t('ranges.day')
                            }, {
                                type: "month",
                                count: 1,
                                text: '1' + this.$t('ranges.month')
                            }, {
                                type: "month",
                                count: 3,
                                text: '3' + this.$t('ranges.month')
                            }, {
                                type: "month",
                                count: 6,
                                text: '6' + this.$t('ranges.month')
                            }, {
                                type: "year",
                                count: 1,
                                text: '1' + this.$t('ranges.year')
                            }, {
                                type: "all",
                                text: this.$t('ranges.all')
                            }],
                        },
                        plotOptions: {
                            series: {
                                compare: 'percent',
                                animation: false,
                                threshold: null,
                                lineOpacity: .2,
                                fillOpacity: .2,
                                lineWidth: 1.5,
                                states: {
                                    hover: {
                                        enabled: false
                                    }
                                },
                                dataGrouping: {
                                    enabled: true
                                }
                            },
                            area: {
                                boostThreshold: 0,
                                turboThreshold: 0,
                                getExtremesFromAll: true
                            },
                            column: {
                                grouping: false
                            }
                        },
                        xAxis: {
                            gridLineWidth: 1,
                            minRange: 864e5
                        },
                        yAxis: [
                            {
                                reversedStacks: false,
                                maxPadding: 0,
                                labels: false
                            }
                        ],
                    });
                }
            },
            prepareSeriesOptions(rawData) {
                let series = [];

                for (let i = 0; i < rawData.length; i++) {
                    series.push({
                        name: rawData[i].currencySymbol,
                        showInLegend: true,
                        // stacking: 'normal',
                        data: this.parseData(rawData[i].items, KEY_TIMESTAMP, KEY_VOLUME_USD),
                    })
                }

                return series;
            },
            parseData(rawData, timeCol, valCol) {
                let data = [];
                for (let i = 0; i < rawData.length; i++) {
                    let ms = parseInt(rawData[i][timeCol]) * 1000;
                    let val = parseFloat(rawData[i][valCol]);
                    data.push([ms, val]);
                }
                return data;
            },
        }
    }
</script>