<template>
    <tr v-on:click="onSelectCurrency" v-bind:data-active="isCurrencyActive">
        <td align="center">
            <star-icon v-bind:active="isCurrencyFavorite" v-on:click.prevent.stop="onToggleCurrencyFavorite"></star-icon>
        </td>
        <td>{{ currency.symbol }}</td>
        <td>${{ currency.price_usd | toCurrencySmart }}</td>
        <td>{{ currency.price_btc | toCurrencySmart }}</td>
        <td v-bind:class="percentColorClass">{{ currency.percent_change_24h_usd | withSign }}%</td>
        <td>${{ currency.market_cap_usd | toCurrencyAbbreviation }}</td>
        <td>${{ currency.volume_24h_usd | toCurrencyAbbreviation }}</td>
    </tr>
</template>
<script>
    import {mapState} from 'vuex'
    import NotifyMixin from "./../../mixins/notify"
    import CurrencyFavoritesMixin from './../../mixins/currency-favorites'
    import StarIcon from './../icons/star.vue'

    export default {
        name: "currency-table-item",
        mixins: [CurrencyFavoritesMixin, NotifyMixin],
        components: {
            StarIcon
        },
        props: {
            currency: {
                type: Object,
                required: true
            },
            activeCurrency: {
                required: true
            }
        },
        computed: {
            ...mapState('auth', [
                'loggedIn',
            ]),
            isCurrencyFavorite () {
                return this.isFavorite(this.currency.id);
            },
            isCurrencyActive () {
                return !!this.activeCurrency && this.activeCurrency.id == this.currency.id;
            },
            percentColorClass () {
                return [
                    {'table__color-green': this.currency.percent_change_24h_usd >= 0},
                    {'table__color-red': this.currency.percent_change_24h_usd < 0}
                ];
            }
        },
        methods: {
            onToggleCurrencyFavorite () {
                this.toggleCurrencyFavorite(this.currency.id);
            },
            onSelectCurrency () {
                this.$emit('select-currency');
            }
        }
    }
</script>