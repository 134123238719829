<template>
    <div class="header-language" ref="dropdownMenu" v-bind:data-expand="visible">
        <div v-if="currentLocale"
             class="header-language__current"
             v-on:click.stop="toggle"
             v-bind:data-short="currentLocale.shortName">{{ currentLocale.fullName }}
        </div>

        <ul class="header-language__list">
            <li v-for="(item, index) in items"
                v-bind:key="index"
                v-bind:data-short="item.shortName"
                v-on:click.prevent="selectLocale(item.id)"
            >{{ item.fullName }}
            </li>
        </ul>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex'
    import locales from "@/store/directory/locales"
    import DropDownMixin from "./../../mixins/dropdown"

    export default {
        name: 'locale-view',
        mixins: [DropDownMixin],
        computed: {
            ...mapGetters('locale', [
                'currentLocale',
            ]),
            items () {
                let _items = [];
                for (let i = 0; i < locales.length; i++) {
                    if (this.currentLocale == null || locales[i].id != this.currentLocale.id) {
                        _items.push(locales[i]);
                    }
                }
                return _items;
            }
        },
        methods: {
            ...mapActions('locale', [
                'setLocale'
            ]),
            selectLocale(localeId) {
                this.hide();
                this.setLocale(localeId);
            }
        }
    }
</script>