<template>
    <div class="header-line">
        <div class="header-line__col">
            <div class="header-line-active-currency">{{ $t('global.Country') }}: {{ country.title }}
                <div class="header-line-active-currency__close" v-on:click.prevent="closeCountry"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'header-line',
        props: {
            country: {
                type: Object,
                required: true
            },
        },
        methods: {
            closeCountry () {
                this.$emit('close-country');
            }
        }
    }
</script>
<style>
    .header-line-active-currency {
        margin-left: 0;
    }
</style>