export default function () {
    this.account = {
        logIn: ({email, password}) => {
            return this.post({
                to: {name: 'api_auth_login'},
                payload: {
                    email: email,
                    password: password,
                }
            });
        },
        logOut: () => {
            return this.post({
                to: {name: 'api_auth_logout'},
                payload: {}
            });
        },
        signUp: ({email, password, password_confirm, locale, recaptcha}) => {
            return this.post({
                to: {name: 'api_auth_signup'},
                payload: {
                    email: email,
                    password: password,
                    password_confirm: password_confirm,
                    locale: locale,
                    recaptcha: recaptcha
                }
            });
        },
        restore: ({email, recaptcha}) => {
            return this.post({
                to: {name: 'api_auth_restore'},
                payload: {
                    email: email,
                    recaptcha: recaptcha,
                }
            });
        },
        resendConfirmation: () => {
            return this.post({
                to: {name: 'api_account_resend_confirmation'},
            });
        },
        changePassword: ({token, password, passwordConfirm}) => {
            return this.post({
                to: {name: 'api_auth_change_password'},
                payload: {
                    token: token,
                    password: password,
                    passwordConfirm: passwordConfirm
                }
            });
        },
        update: ({name, email, password, passwordConfirm}) => {
            return this.post({
                to: {name: 'api_account_update'},
                payload: {
                    name: name,
                    email: email,
                    password: password,
                    passwordConfirm: passwordConfirm
                }
            });
        },
        localeSave: (localeId) => {
            return this.post({
                to: {name: 'api_account_locale_save'},
                payload: {
                    locale_id: localeId
                }
            });
        },
        currencyToggle: (currencyId) => {
            return this.post({
                to: {name: 'api_account_currency_toggle'},
                payload: {
                    currency_id: currencyId
                }
            });
        },
        tagToggle: (tagId) => {
            return this.post({
                to: {name: 'api_account_tag_toggle'},
                payload: {
                    tag_id: tagId
                }
            });
        },
        data: () => {
            return this.post({
                to: {name: 'api_account_data'},
                payload: {}
            });
        },
        oauthRemove: ({userAccountId}) => {
            return this.post({
                to: {name: 'api_oauth_remove'},
                payload: {
                    user_account_id: userAccountId
                }
            });
        }
    }
}