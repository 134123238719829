<template>
    <tr class="table__nowrap">
        <th align="left">
            <star-icon v-bind:active="tagTableShowFavorite == 1"
                       v-on:click.prevent.stop="tagTableToggleShowFavorite"></star-icon>
        </th>
        <th>{{ $t('tag.TableColumnName') }}</th>
    </tr>
</template>
<script>
    import TagTableStoreMixin from "./../../mixins/tag-table-store"
    import StarIcon from './../../components/icons/star.vue'
    
    export default {
        name: "tag-table-head",
        mixins: [TagTableStoreMixin],
        components: {
            StarIcon
        },
    }
</script>