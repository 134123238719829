<template>
    <div v-bind:infinite-wrapper="!mobileMode"><slot></slot></div>
</template>
<script>
    import PerfectScrollbar from 'perfect-scrollbar'

    export default {
        name: 'custom-scroll',
        props: {
            mobileMode: {
                type: Boolean,
                default: false
            },
            scrollVertical: {
                type: Boolean,
                default: false
            },
            scrollHorizontal: {
                type: Boolean,
                default: false
            },
            fixScroll: {
                type: Boolean,
                default: false
            }
        },
        mounted () {
            this.$nextTick(() => {
                this.attachScroll();
            });
        },
        updated () {
            this.updateScroll();
        },
        beforeDestroy () {
            this.detachScroll();
        },
        methods: {
            moveScroll(delta) {
                let min = 0,
                    max = this.$el.Scroll.contentHeight - this.$el.Scroll.containerHeight,
                    i = this.$el.scrollTop;

                if(delta > 0) {
                    i += 40;
                    if(i > max) i = max;
                }

                if(delta < 0) {
                    i -= 40;
                    if(i < min) i = min;
                }

                this.$el.scrollTop = i;
            },
            updateScroll () {
                if (this.mobileMode) {
                    this.detachScroll();
                }
                else if (this.$el.Scroll) {
                    this.$el.Scroll.update();
                }
            },
            detachScroll () {
                if (this.$el.Scroll) {
                    this.$el.Scroll.destroy();
                    this.$el.Scroll = undefined;
                    window.removeEventListener('resize', this.updateScroll);

                    if (this.fixScroll) {
                        this.unwatchContainerHeight();
                    }
                }
            },
            attachScroll () {
                if (!this.$el.Scroll && !this.mobileMode) {
                    this.$el.Scroll = new PerfectScrollbar(this.$el, {
                        minScrollbarLength: 20,
                        suppressScrollX: !this.scrollHorizontal,
                        suppressScrollY: !this.scrollVertical
                    });
                    window.addEventListener('resize', this.updateScroll);

                    if (this.fixScroll) {
                        this.watchContainerHeight();
                    }
                }
            },
            watchContainerHeight () {
                this.$el.addEventListener("DOMSubtreeModified", this.updateScroll, false);
            },
            unwatchContainerHeight () {
                this.$el.removeEventListener("DOMSubtreeModified", this.updateScroll, false);
            }
        }
    }
</script>