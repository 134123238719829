
// module auth
export const AUTH_LOGIN = 'ACCOUNT_LOGIN';
export const AUTH_LOGOUT = 'ACCOUNT_LOGOUT';

// module locale
export const LOCALE_SET = 'LOCALE_SET';

// module tag
export const TAG_TOGGLE_SHOW_FAVORITES = 'TAG_TOGGLE_SHOW_FAVORITES';
export const TAG_TOP_SET = 'TAG_TOP_SET';
export const TAG_FAVORITES_SET = 'TAG_FAVORITES_SET';
export const TAG_CLEAR = 'TAG_CLEAR';

// module tag-table
export const TAG_TABLE_SET_QUERY = 'TAG_TABLE_SET_QUERY';
export const TAG_TABLE_SET_ORDER = 'TAG_TABLE_SET_ORDER';
export const TAG_TABLE_SET_DESK = 'TAG_TABLE_SET_DESK';
export const TAG_TABLE_TOGGLE_SHOW_FAVORITE = 'TAG_TABLE_TOGGLE_SHOW_FAVORITE';

// module currency
export const CURRENCY_TOGGLE_SHOW_FAVORITES = 'CURRENCY_TOGGLE_SHOW_FAVORITES';
export const CURRENCY_TOP_SET = 'CURRENCY_TOP_SET';
export const CURRENCY_FAVORITES_SET = 'CURRENCY_FAVORITES_SET';
export const CURRENCY_CLEAR = 'CURRENCY_CLEAR';

// module currency-table
export const CURRENCY_TABLE_SET_QUERY = 'CURRENCY_TABLE_SET_QUERY';
export const CURRENCY_TABLE_SET_ORDER = 'CURRENCY_TABLE_SET_ORDER';
export const CURRENCY_TABLE_SET_DESK = 'CURRENCY_TABLE_SET_DESK';
export const CURRENCY_TABLE_TOGGLE_SHOW_FAVORITE = 'CURRENCY_TABLE_TOGGLE_SHOW_FAVORITE';

// module filter
export const FILTER_SET_SOURCE = 'FILTER_SET_SOURCE';
export const FILTER_SELECT_SOURCE = 'FILTER_SELECT_SOURCE';
export const FILTER_UNSELECT_SOURCE = 'FILTER_UNSELECT_SOURCE';
export const FILTER_CLEAR_SOURCE = 'FILTER_CLEAR_SOURCE';

export const FILTER_SET_SOURCE_TYPE = 'FILTER_SET_SOURCE_TYPE';
export const FILTER_SELECT_SOURCE_TYPE = 'FILTER_SELECT_SOURCE_TYPE';
export const FILTER_UNSELECT_SOURCE_TYPE = 'FILTER_UNSELECT_SOURCE_TYPE';
export const FILTER_CLEAR_SOURCE_TYPE = 'FILTER_CLEAR_SOURCE_TYPE';

export const FILTER_SET_VOTE_TYPE = 'FILTER_SET_VOTE_TYPE';
export const FILTER_SELECT_VOTE_TYPE = 'FILTER_SELECT_VOTE_TYPE';
export const FILTER_UNSELECT_VOTE_TYPE = 'FILTER_UNSELECT_VOTE_TYPE';
export const FILTER_CLEAR_VOTE_TYPE = 'FILTER_CLEAR_VOTE_TYPE';

export const FILTER_SET_CURRENCY = 'FILTER_SET_CURRENCY';
export const FILTER_SELECT_CURRENCY = 'FILTER_SELECT_CURRENCY';
export const FILTER_UNSELECT_CURRENCY = 'FILTER_UNSELECT_CURRENCY';
export const FILTER_CLEAR_CURRENCY = 'FILTER_CLEAR_CURRENCY';

export const FILTER_SET_TAG = 'FILTER_SET_TAG';
export const FILTER_SELECT_TAG = 'FILTER_SELECT_TAG';
export const FILTER_UNSELECT_TAG = 'FILTER_UNSELECT_TAG';
export const FILTER_CLEAR_TAG = 'FILTER_CLEAR_TAG';

export const FILTER_SET_COUNTRY = 'FILTER_SET_COUNTRY';
export const FILTER_SELECT_COUNTRY = 'FILTER_SELECT_COUNTRY';
export const FILTER_UNSELECT_COUNTRY = 'FILTER_UNSELECT_COUNTRY';
export const FILTER_CLEAR_COUNTRY = 'FILTER_CLEAR_COUNTRY';

export const FILTER_SET_LANG = 'FILTER_SET_LANG';
export const FILTER_SELECT_LANG = 'FILTER_SELECT_LANG';
export const FILTER_UNSELECT_LANG = 'FILTER_UNSELECT_LANG';
export const FILTER_CLEAR_LANG = 'FILTER_CLEAR_LANG';

export const FILTER_TOGGLE_USER_FEED = 'FILTER_TOGGLE_USER_FEED';
export const FILTER_SET_SHOW_USER_FEED = 'FILTER_SET_SHOW_USER_FEED';
export const FILTER_CLEAR = 'FILTER_CLEAR';
export const FILTER_SIGN_INITIAL = 'FILTER_SIGN_INITIAL';

export const PORTFOLIO_SET_TOTAL_VALUE_USD = 'PORTFOLIO_SET_TOTAL_VALUE_USD';
export const PORTFOLIO_SET_TOTAL_PERCENT_CHANGE_24H = 'PORTFOLIO_SET_TOTAL_PERCENT_CHANGE_24H';
export const PORTFOLIO_CLEAR = 'PORTFOLIO_CLEAR';
export const PORTFOLIO_SET_TABLE_VISIBLE_AMOUNT = 'PORTFOLIO_SET_TABLE_VISIBLE_AMOUNT';
export const PORTFOLIO_SET_TABLE_VISIBLE_VALUE_USD = 'PORTFOLIO_SET_TABLE_VISIBLE_VALUE_USD';
export const PORTFOLIO_SET_TABLE_VISIBLE_PRICE_USD = 'PORTFOLIO_SET_TABLE_VISIBLE_PRICE_USD';
export const PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_USD = 'PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_USD';
export const PORTFOLIO_SET_TABLE_VISIBLE_VALUE_BTC = 'PORTFOLIO_SET_TABLE_VISIBLE_VALUE_BTC';
export const PORTFOLIO_SET_TABLE_VISIBLE_PRICE_BTC = 'PORTFOLIO_SET_TABLE_VISIBLE_PRICE_BTC';
export const PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_BTC = 'PORTFOLIO_SET_TABLE_VISIBLE_CHANGE24H_BTC';
export const PORTFOLIO_SET_TABLE_SORT_COLUMN_NAME = 'PORTFOLIO_SET_TABLE_SORT_COLUMN_NAME';
export const PORTFOLIO_SET_TABLE_SORT_COLUMN_DESC = 'PORTFOLIO_SET_TABLE_SORT_COLUMN_DESC';

// portfolio data
export const PORTFOLIO_DATA_SET_TABLE_ITEMS = 'PORTFOLIO_DATA_SET_TABLE_ITEMS';
export const PORTFOLIO_DATA_SET_HISTORY_ITEMS = 'PORTFOLIO_DATA_SET_HISTORY_ITEMS';

// module page
export const LOADING_SET = 'LOADING_SET';

// module countries
export const COUNTRIES_SET = 'COUNTRIES_SET';