import {
    CURRENCY_TABLE_SET_QUERY,
    CURRENCY_TABLE_SET_ORDER,
    CURRENCY_TABLE_SET_DESK,
    CURRENCY_TABLE_TOGGLE_SHOW_FAVORITE,
} from "./../mutation-types";

const state = {
    currencyTableQuery: "",
    currencyTableOrder: "",
    currencyTableDesk: 1,
    currencyTableShowFavorite: 0,
};

const getters = {

};

const mutations = {
    [CURRENCY_TABLE_TOGGLE_SHOW_FAVORITE] (state) {
        state.currencyTableShowFavorite = !state.currencyTableShowFavorite;
    },
    [CURRENCY_TABLE_SET_QUERY] (state, value) {
        state.currencyTableQuery = value
    },
    [CURRENCY_TABLE_SET_ORDER] (state, value) {
        state.currencyTableOrder = value
    },
    [CURRENCY_TABLE_SET_DESK] (state, value) {
        state.currencyTableDesk = value
    },
};

const actions = {
    currencyTableToggleShowFavorite (store) {
        store.commit(CURRENCY_TABLE_TOGGLE_SHOW_FAVORITE);
    },
    currencyTableSetQuery (store, value) {
        store.commit(CURRENCY_TABLE_SET_QUERY, value);
    },
    currencyTableSetOrder (store, value) {
        store.commit(CURRENCY_TABLE_SET_ORDER, value);
    },
    currencyTableSetDesk (store, value) {
        store.commit(CURRENCY_TABLE_SET_DESK, value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}