import httpAxios from "./../utils/http";
import router from "./../router"
import common from "./common"
import account from "./account"
import bot from "./bot"
import content from "./content"
import tag from "./tag"
import currency from "./currency"
import country from "./country"
import source from "./source"
import portfolio from "./portfolio"
import filter from "./filter"

let modules = [
    common,
    account,
    bot,
    content,
    tag,
    currency,
    country,
    source,
    filter,
    portfolio,
];

let api = {
    get ({to, payload}) {
        return this.request({method: 'get', to, payload})
    },
    post ({to, payload}) {
        return this.request({method: 'post', to, payload})
    },
    put ({to, payload}) {
        return this.request({method: 'put', to, payload})
    },
    delete ({to, payload}) {
        return this.request({method: 'delete', to, payload})
    },
    request ({method, to, payload}) {
        let endpoint = typeof to == 'object'
            ? router.resolve(to).href
            : to;
        return httpAxios[method](endpoint, payload);
    },
    init () {
        modules.forEach((useModule) => {
            useModule.call(this);
        });
    }
};

api.init();

export default api;