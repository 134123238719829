export default {
    methods: {
        loadMoreForceReset() {
            this.$refs.loadMore.forceReset();
        },
        loadMoreComplete() {
            this.$refs.loadMore.complete();
        },
        loadMoreLoaded() {
            this.$refs.loadMore.loaded();
        },
        loadMoreReset() {
            this.$refs.loadMore.reset();
        },
        loadMoreHandler() {
            this.$emit('load-more');
        }
    }
}