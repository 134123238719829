import ViewTags from "./../../views/tags.vue";

export default [
    {
        path: '/tags',
        name: 'tags',
        component: ViewTags,
        children: [
            {
                path: ':tag',
                name: 'tags_item',
                component: ViewTags,
                children: [
                    {
                        path: 'post/:slug',
                        name: 'tags_content_item',
                        component: ViewTags,
                    }
                ]
            }
        ]
    },
    {
        path: '/api/tag/list',
        name: 'api_tag_list',
    },
    {
        path: '/api/tag/list/common',
        name: 'api_tag_list_common',
    },
];