<template>
    <div>
        <div v-for="(n, index) in rate"
             v-bind:key="index"
             class="user-rating__arrow"></div>
        <div class="user-rating__num">{{ valueCount }}</div>
    </div>
</template>
<script>
    export default {
        name: 'vote-info',
        props: {
            valueCount: {
                type: Number,
                required: true
            },
            valueAverage: {
                type: Number,
                required: true
            },
        },
        computed: {
            rate () {
                return this.calcRate(this.valueAverage, this.valueCount)
            },
        },
        methods: {
            calcRate(voteCountAverage, voteCount) {
                let part = voteCountAverage * 2 / 3; // одна третья, от среднего количества
                let parts = voteCount / part; // сравниваем кол-во голосов с средним значением
                if (parts > 2) {
                    return 3;
                } else if (parts > 1) {
                    return 2;
                }
                return 1;
            }
        }
    }
</script>