<template>
    <layout v-bind:scrolled-down="scrolledDown"
            v-bind:mobile-mode="mobileMode">
        <template slot="header-line">
            <header-line v-on:toggle-detail-filter="toggleDetailFilter"></header-line>
        </template>

        <custom-scroll
                v-if="visibleContentList"
                class="content__col content__col_left"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>
            <content-list
                    ref="contentList"
                    v-bind:items="contentItems"
                    v-bind:mobile-mode="mobileMode"
                    v-bind:active-item="contentItem"
                    v-on:select-item="openContentItem"
                    v-on:load-more="contentListLoadMore"
            ></content-list>
        </custom-scroll>

        <custom-scroll
                v-if="visibleFilterForm || visibleContentItem"
                ref="rightCustomScroll"
                class="content__col content__col_right"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>

            <filter-form v-if="visibleFilterForm"
                         v-on:update:user-feed="userFeed = $event"
                         v-bind:user-feed="userFeed"
                         v-bind:mobile-mode="mobileMode"></filter-form>

            <div v-if="visibleFilterForm && mobileMode" class="content__button-mobile">
                <a class="button" v-on:click.prevent="hideDetailFilter">{{ $t('global.Ok') }}</a>
            </div>

            <content-detail v-if="visibleContentItem"
                            v-on:content-close="closeContentItem"
                            v-on:content-update="updateContentItem"
                            v-on:scroll="onScrollContent"
                            v-bind:item="contentItem"
                            v-bind:mobile-mode="mobileMode"
            ></content-detail>
        </custom-scroll>
    </layout>
</template>
<script>
    import PageMixin from "./../mixins/page"
    import HeaderLine from "./../components/content/header-line.vue"
    import FilterForm from "./../components/filter/filter-form.vue"
    import ContentDetail from "./../components/content/content-detail.vue"
    import ContentList from "./../components/content/content-list.vue"
    import ContentListMixin from "./../mixins/content-list"
    import {mapState, mapGetters, mapActions} from 'vuex'

    export default {
        mixins: [PageMixin, ContentListMixin],
        components: {
            HeaderLine,
            FilterForm,
            ContentDetail,
            ContentList,
        },
        data () {
            return {
                detailFilter: false
            }
        },
        computed: {
            ...mapState('filter', [
                'showUserFeed'
            ]),
            ...mapGetters('filter', {
                filterSignature: 'signature',
                filterParams: 'params'
            }),
            ...mapGetters('filterFeed', {
                filterFeedSignature: 'signature',
                filterFeedParams: 'params'
            }),
            userFeed: {
                get () {
                    return this.showUserFeed
                },
                set (val) {
                    this.setShowUserFeed(val);
                }
            },
            visibleContentList () {
                return !this.mobileMode || (!this.visibleFilterForm && !this.visibleContentItem);
            },
            visibleFilterForm () {
                return !this.visibleContentItem && (!this.mobileMode || this.detailFilter);
            },
            visibleContentItem () {
                return !!this.contentItem
            }
        },
        watch: {
            userFeed () {
                this.onChangeContentFilter();
            },
            filterSignature () {
                this.onChangeContentFilter();
            },
            filterFeedSignature () {
                this.onChangeContentFilter();
            },
        },
        beforeRouteUpdate (to, from, next) {
            if (to.name == 'home') {
                this.contentItem = null;
            }
            next();
        },
        methods: {
            ...mapActions('filter', ['setShowUserFeed']),
            pageReady() {
                if (this.$router.currentRoute.name == 'content_item') {
                    this.contentItemLoad({slug: this.$router.currentRoute.params.slug});
                }
                this.contentListLoad();
            },
            getContentListRequestParams() {
                return this.userFeed ? this.filterFeedParams : this.filterParams;
            },
            openContentItem(item) {
                this.contentItem = item;
                let to = {
                    name: "content_item",
                    params: {
                        slug: this.contentItem.slug
                    }
                };
                if (this.$router.currentRoute.name == 'content_item') {
                    this.$router.replace(to);
                } else {
                    this.$router.push(to);
                }
            },
            closeContentItem() {
                this.contentItem = null;
                if (this.routeFrom != null && this.routeFrom.name == 'home') {
                    this.$router.go(-1);
                } else {
                    this.$router.replace({name: 'home',});
                }
            },
            // переключить детальный фильтр
            toggleDetailFilter() {
                this.detailFilter = !this.detailFilter;
            },
            // скрыть детальный фильтр в мобильной версии
            hideDetailFilter() {
                this.detailFilter = false;
            },
            onScrollContent (delta) {
                if (this.$refs.rightCustomScroll) {
                    this.$refs.rightCustomScroll.moveScroll(delta);
                }
            }
        }
    }
</script>