import ViewTag from "./../../views/tag.vue";

export default [
    {
        path: '/tag/:tag',
        name: 'tag',
        component: ViewTag,
        children: [
            {
                path: 'post/:slug',
                name: 'tag_content_item',
                component: ViewTag,
            },
        ]
    },
    {
        path: '/api/tag/search',
        name: 'api_tag_search',
    },
    {
        path: '/api/tag/:slug',
        name: 'api_tag_data',
    },
];