<template>
    <div class="news-detail__text"></div>
</template>
<script>
    export default {
        name: 'embed-content-reddit',
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                touchIframeTimeout: null
            }
        },
        computed: {
            title () {
                return this.item.title;
            },
            url () {
                return this.item.original_url;
            }
        },
        created() {
            this.$nextTick(() => {
                this.tryEmbedReddit(this.url, this.title);
            });
        },
        destroyed() {
            clearTimeout(this.touchIframeTimeout);
        },
        watch: {
            url (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.tryEmbedReddit(newVal, this.title);
                }
            }
        },
        methods: {
            tryEmbedReddit(url, title) {
                if (this.isRedditUrl(url)) {
                    this.embedReddit(url, title);
                } else {
                    this.clear();
                }
            },
            isRedditUrl(url) {
                return url.match(/^https:\/\/www.reddit.com\//) != null;
            },
            embedReddit(url, title) {
                if (typeof window.redditEmbed == "function") {
                    this.prepareEmbedHtml(url, title);
                    window.redditEmbed();
                    this.touchIframe();
                } else {
                    // если API reddit еще не успел инициализироваться,
                    // пробуем повторно через 500 милисекунд
                    setTimeout(() => {
                        this.embedReddit();
                    }, 500);
                }
            },
            prepareEmbedHtml(url, title) {
                this.$el.innerHTML = `<blockquote class="reddit-card"><a href="` + url + `">` + title + `</a></blockquote>`;
            },
            clear() {
                this.$el.innerHtml = '';
            },
            touchIframe() {
                let elements = document.getElementsByClassName('embedly-card');
                for (let i = 0; i < elements.length; i++) {
                    if (elements[i].tagName.toLowerCase() == 'iframe') {
                        this.propogateScrollEvent(elements[i]);
                        return;
                    }
                }
                this.touchIframeTimeout = setTimeout(() => { this.touchIframe(); }, 500);
            },
            propogateScrollEvent(iframe) {
                iframe.contentWindow.document.body.addEventListener('mousewheel', (e) => {
                    this.$emit('scroll', e.deltaY);
                });
            }
        }
    }
</script>