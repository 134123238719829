<template>
    <div>
        <div class="input-text">
            <label class="input-checkbox">
                <input type="checkbox"
                       v-bind:name="fieldName"
                       v-bind:value="1"
                       v-model="fieldValue"/>
                <div class="input-checkbox__check"></div>
                <div class="input-checkbox__text">{{ label }}</div>
            </label>
            <slot name="notes"></slot>
            <vuelidate-errors
                    v-if="formDirty"
                    v-bind:field="vuelidateField"
                    v-bind:server-errors="formErrors"
                    v-bind:server-field="serverFieldName">
                <slot name="errors">
                    <vuelidate-message rule="required">{{ $t('errors.Required') }}</vuelidate-message>
                </slot>
            </vuelidate-errors>
        </div>
    </div>
</template>
<script>
    import FieldBaseMixin from './field-base'

    export default {
        name: 'field-checkbox',
        mixins: [FieldBaseMixin]
    }
</script>