<template>
    <th v-bind:class="[{'table__sort-up': sortUp}, {'table__sort-down': sortDown}]">
        <div v-if="withMenu"
             v-on:click.prevent="toggleMenu"
             class="table__menu-handler">
            <div></div>
        </div>
        <a v-on:click.prevent="toggleSort" href="#sort"><slot></slot></a>
    </th>
</template>
<script>
    export default {
        name: "portfolio-table-header-column",
        props: {
            columnName: {
                type: String,
                default: ""
            },
            sortColumnName: {
                type: String,
                default: ""
            },
            sortColumnDesc: {
                type: Boolean,
                default: false
            },
            withMenu: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            sortUp () {
                return this.sortColumnName != ""
                    && this.columnName == this.sortColumnName
                    && this.sortColumnDesc
            },
            sortDown () {
                return this.sortColumnName != ""
                    && this.columnName == this.sortColumnName
                    && !this.sortColumnDesc
            }
        },
        methods: {
            toggleSort() {
                this.$emit("toggle-sort");
            },
            toggleMenu() {
                this.$emit("toggle-menu");
            },
        }
    }
</script>