<template>
    <table class="currency-detail__indicators">
        <tr v-for="(group, index) in groups"
             v-bind:key="index">
            <td>
            <currency-detail-indicator
                    v-bind:indicator-symbol="group.first.indicatorSymbol"
                    v-bind:indicator-value="group.first.indicatorValue"
                    v-bind:indicator-second-symbol="group.first.indicatorSecondSymbol"
                    v-bind:indicator-second-value="group.first.indicatorSecondValue"
            >{{ group.first.label }}</currency-detail-indicator>
            </td>
            <td>
            <currency-detail-indicator
                    v-if="group.second"
                    v-bind:indicator-symbol="group.second.indicatorSymbol"
                    v-bind:indicator-value="group.second.indicatorValue"
                    v-bind:indicator-second-symbol="group.second.indicatorSecondSymbol"
                    v-bind:indicator-second-value="group.second.indicatorSecondValue"
            >{{ group.second.label }}</currency-detail-indicator>
            </td>
        </tr>
    </table>
</template>
<script>
    import CurrencyDetailIndicator from './currency-detail-indicator.vue'

    export default {
        name: "currency-detail-indicators",
        components: {
            CurrencyDetailIndicator
        },
        props: {
            currency: {
                type: Object,
                required: true
            }
        },
        computed: {
            indicatorMarketCap () {
                return this.prepareIndicatorData(
                    this.$t("currency.LabelMarketCap"),
                    "USD",
                    this.currency.market_cap_usd,
                    "BTC",
                    this.currency.market_cap_btc
                );
            },
            indicatorVolume24h () {
                return this.prepareIndicatorData(
                    this.$t("currency.LabelVolume24h"),
                    "USD",
                    this.currency.volume_24h_usd,
                    "BTC",
                    this.currency.volume_24h_btc
                );
            },
            indicatorCirculatingSupply () {
                return this.currency.available_supply > 0 ? this.prepareIndicatorData(
                    this.$t("currency.LabelCirculatingSupply"),
                    this.currency.symbol,
                    this.currency.available_supply) : null;
            },
            indicatorTotalSupply () {
                return this.currency.param_total_supply > 0 ? this.prepareIndicatorData(
                    this.$t("currency.LabelTotalSupply"),
                    this.currency.symbol,
                    this.currency.param_total_supply) : null;
            },
            indicatorMaxSupply () {
                return this.currency.param_max_supply > 0 ? this.prepareIndicatorData(
                    this.$t("currency.LabelMaxSupply"),
                    this.currency.symbol,
                    this.currency.param_max_supply) : null;
            },
            indicators () {
                let res = [];

                if (this.indicatorMarketCap != null) {
                    res.push(this.indicatorMarketCap);
                }

                if (this.indicatorVolume24h != null) {
                    res.push(this.indicatorVolume24h);
                }

                if (this.indicatorCirculatingSupply != null) {
                    res.push(this.indicatorCirculatingSupply);
                }

                if (this.indicatorTotalSupply != null) {
                    res.push(this.indicatorTotalSupply);
                }

                if (this.indicatorMaxSupply != null) {
                    res.push(this.indicatorMaxSupply);
                }

                return res;
            },
            groups () {
                let groups = [];

                for (let i = 0; i < this.indicators.length; i += 2) {
                    groups.push(
                        {
                            first: this.indicators[i],
                            second: this.indicators[i + 1] ? this.indicators[i + 1] : null,
                        }
                    )
                }

                return groups;
            }
        },
        methods: {
            prepareIndicatorData(
                label,
                indicatorSymbol,
                indicatorValue,
                indicatorSecondSymbol,
                indicatorSecondValue,
            ) {
                return {
                    "label": label,
                    "indicatorSymbol": indicatorSymbol,
                    "indicatorValue": indicatorValue,
                    "indicatorSecondSymbol": indicatorSecondSymbol,
                    "indicatorSecondValue": indicatorSecondValue,
                }
            }
        }
    }
</script>