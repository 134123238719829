<template>
    <div>

        <div class="content__close" v-on:click.prevent="contentClose"></div>
        <div class="news-detail">
            <div class="news-detail__body">
                <div class="news-detail__title">{{ item.title }}</div>
                <embed-content
                        v-bind:item="item"
                        v-bind:mobile-mode="mobileMode"
                        v-on:scroll="onScroll"
                ></embed-content>
            </div>
            <div class="news-detail__info">
                <div class="news-detail__row">

                    <div>

                        <!-- source -->
                        <div class="news-detail__source">{{ $t('content.Source') }}: <a v-bind:href="item.original_url" v-bind:target="mobileMode ? '_self':'_blank'">{{ item.source.name }}</a></div>

                    </div>

                    <!-- rating -->
                    <div class="news-detail__user-rating">
                        <vote-info class="user-rating user-rating_up"
                                   v-if="item.votes.positive_count > 0"
                                   v-bind:value-count="item.votes.positive_count"
                                   v-bind:value-average="item.votes.positive_average"></vote-info>
                        <vote-info class="user-rating user-rating_down"
                                   v-if="item.votes.negative_count > 0"
                                   v-bind:value-count="item.votes.negative_count"
                                   v-bind:value-average="item.votes.negative_average"></vote-info>
                        <vote-info class="user-rating user-rating_important"
                                   v-if="item.votes.important_count > 0"
                                   v-bind:value-count="item.votes.important_count"
                                   v-bind:value-average="item.votes.important_average"></vote-info>
                    </div>
                </div>

                <div class="news-detail-share__wrap">
                    <share v-bind:item="item" v-bind:mobile-mode="mobileMode" style="margin-left: 0;"></share>
                </div>

                <div class="news-detail-vote">
                    <div class="news-detail-vote__title">{{ $t('content.WTF') }}</div>
                    <a class="button button_gray button_bullish"
                       href="#positive"
                       v-bind:data-active="item.votes.positive_active"
                       v-on:click.prevent="vote('positive', $event)"><div class="button__icon"></div>{{ $t('content.Positive') }}</a>
                    <a class="button button_gray button_bearish"
                       href="#negative"
                       v-bind:data-active="item.votes.negative_active"
                       v-on:click.prevent="vote('negative', $event)"><div class="button__icon"></div>{{ $t('content.Negative') }}</a>
                    <a class="button button_gray button_important"
                       href="#important"
                       v-bind:data-active="item.votes.important_active"
                       v-on:click.prevent="vote('important', $event)"><div class="button__icon"></div>{{ $t('content.Important') }}</a>
                </div>
            </div>
        </div>
        <div class="content__button-mobile">
            <a class="button button_back" href="#back" v-on:click.prevent="contentClose"><div class="button__icon"></div>{{ $t('global.Back') }}</a>
        </div>

    </div>
</template>
<script>
    import API from './../../api'
    import NotifyMixin from "./../../mixins/notify";
    import LoaderMixin from "./../../mixins/loader";
    import RouterMixin from "./../../mixins/router";
    import Share from "./share.vue"
    import VoteInfo from "./vote-info.vue"
    import EmbedContent from "./embed-content.vue"

    export default {
        name: 'content-detail',
        mixins: [NotifyMixin, LoaderMixin, RouterMixin],
        components: {
            VoteInfo,
            Share,
            EmbedContent
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            mobileMode: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            tagsSlice () {
                return this.item ? this.item.tags.slice(0, 10) : [];
            }
        },
        methods: {
            contentClose() {
                this.$emit('content-close')
            },
            vote(voteType, event) {
                this.loadingStart();
                API.content.vote({contentId: this.item.id, voteType: voteType}).then((response) => {
                    this.$emit('content-update', response.data.item)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.notifyError(this.$t('content.MustLoginToVote'))
                    }
                }).finally(() => {
                    this.loadingStop();
                });
                event.target.blur();
            },
            onScroll (delta) {
                this.$emit('scroll', delta);
            }
        }
    }
</script>