import {
    TAG_TOGGLE_SHOW_FAVORITES,
    TAG_TOP_SET,
    TAG_FAVORITES_SET,
    TAG_CLEAR,
} from "./../mutation-types";

const state = {
    showFavorites: false,
    tagFavorites: [],
    tagTop: [],
};

const getters = {

};

const mutations = {
    [TAG_TOGGLE_SHOW_FAVORITES] (state) {
        state.showFavorites = !state.showFavorites;
    },
    [TAG_TOP_SET] (state, tags) {
        state.tagTop = tags
    },
    [TAG_FAVORITES_SET] (state, tags) {
        state.tagFavorites = tags
    },
    [TAG_CLEAR] (state) {
        state.tagFavorites = [];
        state.showFavorites = false;
    }
};

const actions = {
    toggleShowFavorites (store) {
        store.commit(TAG_TOGGLE_SHOW_FAVORITES);
    },
    setTagTop (store, tags) {
        store.commit(TAG_TOP_SET, tags);
    },
    setTagFavorites (store, tags) {
        store.commit(TAG_FAVORITES_SET, tags);
    },
    clear (store) {
        store.commit(TAG_CLEAR);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}