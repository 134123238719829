<template>
    <div class="vuelidate-message" v-if="fieldIsInvalid">
        <slot/>
    </div>
</template>
<script>
    export default {
        name: 'field-message',
        props: {
            rule: { type: String, required: true },
        },
        computed: {
            fieldIsInvalid () {
                return this.$parent.field[this.rule] === false
                    || this.$parent.serverFieldErrors.indexOf(this.rule) != -1
            }
        }
    }
</script>
<style scoped>
    .vuelidate-message {
        display: none;
    }

    .vuelidate-message:first-child {
        display: block;
    }
</style>