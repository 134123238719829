<template>
    <layout v-bind:mobile-mode="mobileMode">
        <custom-scroll class="content__col content__col_wide" 
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <div class="field-set">
                <div class="field-set__row">
                    <div class="field-set__title">{{ $t('menu.About') }}</div>
                </div>
                <div class="field-set__row">
                    <div class="news-detail__text" v-html="$t('about.MainText')"></div>
                </div>
            </div>
        </custom-scroll>
    </layout>
</template>
<script>
    import {mapGetters} from 'vuex'
    import PageMixin from "./../mixins/page"

    export default {
        mixins: [PageMixin],
        computed: {
            ...mapGetters('locale', ['currentLocale']),
        }
    }
</script>