export default function () {
    this.portfolio = {
        data: () => {
            return this.post({
                to: {name: 'api_portfolio_data'},
                payload: {}
            });
        },
        table: () => {
            return this.post({
                to: {name: 'api_portfolio_table'},
                payload: {}
            });
        },
        create: ({currencyId, amount, title}) => {
            return this.post({
                to: {name: 'api_portfolio_create'},
                payload: {
                    currency_id: currencyId,
                    amount: amount,
                    title: title,
                }
            });
        },
        update: ({id, currencyId, amount, title}) => {
            return this.post({
                to: {name: 'api_portfolio_update'},
                payload: {
                    id: id,
                    currency_id: currencyId,
                    amount: amount,
                    title: title,
                }
            });
        },
        delete: ({id}) => {
            return this.post({
                to: {name: 'api_portfolio_delete'},
                payload: {id: id}
            });
        },
        historyClear: () => {
            return this.post({
                to: {name: 'api_portfolio_history_clear'},
            });
        }
    }
}