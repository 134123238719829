<template>
    <tr>
        <td v-bind:class="['currency-detail-price__percent', {'currency-detail-price__percent_red': negativePercent}]">{{ percent | withSign }}%</td>
        <td v-bind:class="['currency-detail-price__cost', {'currency-detail-price__cost_big': big}]"><slot></slot></td>
    </tr>
</template>
<script>
    export default {
        name: "currency-detail-price",
        props: {
            percent: {
                required: true
            },
            big: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            negativePercent () {
                return parseFloat(this.percent) < 0;
            }
        }
    }
</script>