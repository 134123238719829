<template>
    <div class="settings-item">
        <div class="settings-item__header">
            <div class="settings-item__row">

                <div v-bind:class="['settings-item__title', {'settings-item__title_toggle': selected.length > 0 }]"
                     v-on:click.prevent="toggleSelected"
                     v-bind:data-expand="this.mode == 'selected'">{{ this.itemsSelected.length > 0 ? labelSelected + ' (' + this.itemsSelected.length + ')' : labelSelectedAll }}</div>

                <label v-if="withTop"
                       class="settings-item__top input-checkbox">
                    <input type="checkbox" v-model="showTopVal"/>
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ labelTop }}</div>
                </label>

                <div class="dismiss-all"
                     v-if="selected.length > 0"
                     v-on:click.prevent="dismissAll">{{ $t('global.DismissAll') }}
                    <div class="dismiss-all__button"></div>
                </div>
            </div>
        </div>

        <div class="input-text">
            <input type="text"
                   v-model="searchQuery"
                   v-bind:placeholder="placeholderSearch">
            <div v-if="searchQuery.length > 0"
                 v-on:click="clearSearchQuery"
                 class="input-text__clear"></div>
        </div>

        <checkbox-list
                v-if="mode != 'hidden' && items.length > 0"
                name="filterName"
                class="settings-item-collapse"
                item-class="settings-item-collapse__item"
                v-bind:items="items"
                v-model="selected"
                data-expand
        ></checkbox-list>
    </div>
</template>
<script>
    import CheckboxList from './../ui/checkbox-list.vue'

    const MODE_HIDDEN = 'hidden';
    const MODE_SELECTED = 'selected';
    const MODE_TOP = 'top';
    const MODE_SEARCH = 'search';

    export default {
        name: 'filter-search-component',
        components: {
            CheckboxList
        },
        props: {
            userFeed: {
                type: Boolean,
                required: true
            },
            label: {
                type: String,
                default: 'Filter'
            },
            itemsSelected: {
                type: Array,
                required: true
            },
            itemsTop: {
                type: Array,
                default: () => []
            },
            itemsSearch: {
                type: Array,
                required: true
            },
            value: {
                type: Array,
                required: true
            },
            labelSelectedAll: {
                type: String,
                default: 'All currencies'
            },
            labelSelected: {
                type: String,
                default: 'Currencies'
            },
            labelTop: {
                type: String,
                default: 'Top'
            },
            placeholderSearch: {
                type: String,
                default: 'To find more use search'
            },
            messageSelectedEmpty: {
                type: String,
                default: 'No selected records'
            },
            messageSearchEmpty: {
                type: String,
                default: 'No results found'
            },
            withTop: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                mode: MODE_HIDDEN,
                searchQuery: '',
                showTopVal: false,
            }
        },
        computed: {
            items () {
                if (this.mode == MODE_TOP) {
                    return this.itemsTop;
                } else if (this.mode == MODE_SEARCH) {
                    return this.itemsSearch;
                } else if (this.mode == MODE_SELECTED) {
                    return this.itemsSelected;
                }
                return [];
            },
            messageItemsEmpty () {
                if (this.mode == MODE_SEARCH) {
                    return this.$t('global.SearchEmpty');
                } else if (this.mode == MODE_SELECTED) {
                    return this.$t('global.SelectedEmpty');
                }
                return '';
            },
            selected: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        watch: {
            userFeed () {
                this.cleanInput();
            },
            searchQuery(val) {
                if (val.length > 0) {
                    this.showSearch(val);
                    return;
                }
                this.showSelected();
            },
            showTopVal(val) {
                if (val) {
                    this.showTop();
                    return;
                }
                this.showSelected();
            }
        },
        methods: {
            showHidden() {
                this.searchQuery = '';
                this.showTopVal = false;
                this.mode = MODE_HIDDEN;
            },
            showSelected() {
                this.searchQuery = '';
                this.showTopVal = false;
                this.mode = MODE_SELECTED;
            },
            toggleSelected() {
                if (this.mode == MODE_SELECTED) {
                    this.showHidden();
                } else {
                    this.showSelected();
                }
            },
            showTop() {
                this.mode = MODE_TOP;
            },
            showSearch(query) {
                this.showTopVal = false;
                this.$emit('search', query);
                this.mode = MODE_SEARCH;
            },
            dismissAll() {
                this.showHidden();
                this.$emit('dismiss-all');
            },
            cleanInput() {
                this.showHidden();
            },
            clearSearchQuery() {
                this.searchQuery = '';
            }
        }
    }
</script>