import {
    FILTER_SET_SOURCE,
    FILTER_SELECT_SOURCE,
    FILTER_UNSELECT_SOURCE,
    FILTER_CLEAR_SOURCE,
    FILTER_SET_SOURCE_TYPE,
    FILTER_SELECT_SOURCE_TYPE,
    FILTER_UNSELECT_SOURCE_TYPE,
    FILTER_CLEAR_SOURCE_TYPE,
    FILTER_SET_VOTE_TYPE,
    FILTER_SELECT_VOTE_TYPE,
    FILTER_UNSELECT_VOTE_TYPE,
    FILTER_CLEAR_VOTE_TYPE,
    FILTER_SET_CURRENCY,
    FILTER_SELECT_CURRENCY,
    FILTER_UNSELECT_CURRENCY,
    FILTER_CLEAR_CURRENCY,
    FILTER_SET_TAG,
    FILTER_SELECT_TAG,
    FILTER_UNSELECT_TAG,
    FILTER_CLEAR_TAG,
    FILTER_SET_COUNTRY,
    FILTER_SELECT_COUNTRY,
    FILTER_UNSELECT_COUNTRY,
    FILTER_CLEAR_COUNTRY,
    FILTER_SET_LANG,
    FILTER_SELECT_LANG,
    FILTER_UNSELECT_LANG,
    FILTER_CLEAR_LANG,
    FILTER_TOGGLE_USER_FEED,
    FILTER_SET_SHOW_USER_FEED,
    FILTER_CLEAR,
    FILTER_SIGN_INITIAL
} from "./../mutation-types";
import loFindIndex from "lodash/findIndex";
import loMap from "lodash/map";

function factoryState () {
    return {
        sourceListSelected: [], // список объектов, выбранных источников
        currencyListSelected: [], // список объектов, выбранных валют
        tagListSelected: [], // список объектов, выбранных тегов
        countrySelected: [], // массив UD выбранных стран
        sourceTypeSelected: [], // массив ID выбранных типов источника
        langSelected: [], // массив ID выбранных типов языков
        voteTypeSelected: [], // массив ID выбранных типов новостей (голосование)
        showUserFeed: false,
        initialSignature: '', // отрожает сохраненное состояние фильтра
    }
}

function factoryGetters () {
    return {
        filterCurrencies (state) {
            return state.currencyListSelected ? loMap(state.currencyListSelected, 'id') : [];
        },
        filterTags (state) {
            return state.tagListSelected ? loMap(state.tagListSelected, 'id') : [];
        },
        filterSources (state) {
            return state.sourceListSelected ? loMap(state.sourceListSelected, 'id') : [];
        },
        filterSourceTypes (state) {
            return state.sourceTypeSelected || [];
        },
        filterVoteTypes (state) {
            return state.voteTypeSelected || [];
        },
        filterLangs (state) {
            return state.langSelected || [];
        },
        filterCountries (state) {
            return state.countrySelected || [];
        },
        savedFilter (state) {
            if (state.initialSignature != "") {
                try {
                    return JSON.parse(state.initialSignature);
                } catch (e) {
                    //
                }
            }
            return {};
        },
        params (state, getters) {
            return {
                currencies: getters.filterCurrencies,
                tags: getters.filterTags,
                countries: getters.filterCountries,
                sources: getters.filterSources,
                source_types: getters.filterSourceTypes,
                vote_types: getters.filterVoteTypes,
                langs: getters.filterLangs,
            };
        },
        signature (state) {
            if (state.sourceListSelected.length > 0
                || state.currencyListSelected.length > 0
                || state.tagListSelected.length > 0
                || state.countrySelected.length > 0
                || state.sourceTypeSelected.length > 0
                || state.voteTypeSelected.length > 0
                || state.langSelected.length > 0) {
                return JSON.stringify({
                    sourceListSelected: state.sourceListSelected,
                    currencyListSelected: state.currencyListSelected,
                    tagListSelected: state.tagListSelected,
                    countrySelected: state.countrySelected,
                    sourceTypeSelected: state.sourceTypeSelected,
                    voteTypeSelected: state.voteTypeSelected,
                    langSelected: state.langSelected,
                })
            }
            return "";
        }
    }
}

function factoryMutations() {
    return {
        [FILTER_SET_SOURCE] (state, value) {
            state.sourceListSelected = value;
        },
        [FILTER_SELECT_SOURCE] (state, source) {
            // проверяем, что источник выбран
            let found = state.sourceListSelected.find((item) => {
                return item.id == source.id;
            });
            // если источник не выбран, выбираем его
            if (!found) {
                state.sourceListSelected.push(source)
            }
        },
        [FILTER_UNSELECT_SOURCE] (state, source) {
            // проверяем, что источник выбран
            let found = loFindIndex(state.sourceListSelected, function(item) {
                return item.id == source.id;
            });
            // если источник выбран, удаляем его
            if (found > -1) {
                state.sourceListSelected.splice(found, 1)
            }
        },
        [FILTER_CLEAR_SOURCE] (state) {
            state.sourceListSelected = [];
        },
        [FILTER_SET_SOURCE_TYPE] (state, value) {
            state.sourceTypeSelected = value;
        },
        [FILTER_SELECT_SOURCE_TYPE] (state, sourceTypeId) {
            // проверяем, что тип источника выбран
            let found = state.sourceTypeSelected.find((itemSourceTypeId) => {
                return itemSourceTypeId == sourceTypeId;
            });
            // если тип источника не выбран, выбираем его
            if (!found) {
                state.sourceTypeSelected.push(sourceTypeId)
            }
        },
        [FILTER_UNSELECT_SOURCE_TYPE] (state, sourceTypeId) {
            // проверяем, что тип источника выбран
            let found = loFindIndex(state.sourceTypeSelected, function(itemSourceTypeId) {
                return itemSourceTypeId == sourceTypeId;
            });
            // если тип источника выбран, удаляем его
            if (found > -1) {
                state.sourceTypeSelected.splice(found, 1)
            }
        },
        [FILTER_CLEAR_SOURCE_TYPE] (state) {
            state.sourceTypeSelected = [];
        },
        [FILTER_SET_VOTE_TYPE] (state, value) {
            if (Array.isArray(value)) {
                state.voteTypeSelected = value;
            }
        },
        [FILTER_SELECT_VOTE_TYPE] (state, voteTypeId) {
            let found = state.voteTypeSelected.find((itemVoteTypeId) => {
                return itemVoteTypeId == voteTypeId;
            });
            if (!found) {
                state.voteTypeSelected.push(voteTypeId)
            }
        },
        [FILTER_UNSELECT_VOTE_TYPE] (state, voteTypeId) {
            let found = loFindIndex(state.voteTypeSelected, function(itemVoteTypeId) {
                return itemVoteTypeId == voteTypeId;
            });
            if (found > -1) {
                state.voteTypeSelected.splice(found, 1)
            }
        },
        [FILTER_CLEAR_VOTE_TYPE] (state) {
            state.voteTypeSelected = [];
        },
        [FILTER_SET_CURRENCY] (state, value) {
            state.currencyListSelected = value;
        },
        [FILTER_SELECT_CURRENCY] (state, currency) {
            // проверяем, что валюта выбрана
            let found = state.currencyListSelected.find((item) => {
                return item.id == currency.id;
            });

            // если валюта не выбрана, выбираем ее
            if (!found) {
                state.currencyListSelected.push(currency)
            }
        },
        [FILTER_UNSELECT_CURRENCY] (state, currency) {
            // проверяем, что валюта выбрана
            let found = loFindIndex(state.currencyListSelected, function(item) {
                return item.id == currency.id;
            });
            // если валюта выбрана, удаляем ее
            if (found > -1) {
                state.currencyListSelected.splice(found, 1)
            }
        },
        [FILTER_CLEAR_CURRENCY] (state) {
            state.currencyListSelected = [];
        },

        [FILTER_SET_TAG] (state, value) {
            state.tagListSelected = value;
        },
        [FILTER_SELECT_TAG] (state, tag) {
            // проверяем, что валюта выбрана
            let found = state.tagListSelected.find((item) => {
                return item.id == tag.id;
            });

            // если валюта не выбрана, выбираем ее
            if (!found) {
                state.tagListSelected.push(tag)
            }
        },
        [FILTER_UNSELECT_TAG] (state, tag) {
            // проверяем, что валюта выбрана
            let found = loFindIndex(state.tagListSelected, function(item) {
                return item.id == tag.id;
            });
            // если валюта выбрана, удаляем ее
            if (found > -1) {
                state.tagListSelected.splice(found, 1)
            }
        },
        [FILTER_CLEAR_TAG] (state) {
            state.tagListSelected = [];
        },

        [FILTER_SET_LANG] (state, value) {
            state.langSelected = value;
        },
        [FILTER_SELECT_LANG] (state, langId) {
            // проверяем, что тип источника выбран
            let found = state.langSelected.find((itemSourceTypeId) => {
                return itemSourceTypeId == langId;
            });
            // если тип источника не выбран, выбираем его
            if (!found) {
                state.langSelected.push(langId)
            }
        },
        [FILTER_UNSELECT_LANG] (state, langId) {
            // проверяем, что тип источника выбран
            let found = loFindIndex(state.langSelected, function(itemSourceTypeId) {
                return itemSourceTypeId == langId;
            });

            // если тип источника выбран, удаляем его
            if (found > -1) {
                state.langSelected.splice(found, 1)
            }
        },
        [FILTER_CLEAR_LANG] (state) {
            state.langSelected = [];
        },


        [FILTER_SET_COUNTRY] (state, value) {
            state.countrySelected = value;
        },
        [FILTER_SELECT_COUNTRY] (state, countryId) {
            let found = state.countrySelected.find((itemId) => {
                return itemId == countryId;
            });
            if (!found) {
                state.countrySelected.push(countryId)
            }
        },
        [FILTER_UNSELECT_COUNTRY] (state, countryId) {
            let found = loFindIndex(state.countrySelected, function(itemId) {
                return itemId == countryId;
            });
            if (found > -1) {
                state.countrySelected.splice(found, 1)
            }
        },
        [FILTER_CLEAR_COUNTRY] (state) {
            state.countrySelected = [];
        },


        [FILTER_TOGGLE_USER_FEED] (state) {
            // тумблер, включает/отключает использование настроек из ленты пользователя
            state.showUserFeed = !state.showUserFeed;
        },
        [FILTER_SET_SHOW_USER_FEED] (state, value) {
            state.showUserFeed = Boolean(value)
        },
        [FILTER_CLEAR] (state) {
            // сброс всех настроек фильтра
            state.sourceListSelected = state.sourceListSelected.splice();
            state.currencyListSelected = state.currencyListSelected.splice();
            state.tagListSelected = state.tagListSelected.splice();
            state.countrySelected = state.countrySelected.splice();
            state.sourceTypeSelected = state.sourceTypeSelected.splice();
            state.voteTypeSelected = state.voteTypeSelected.splice();
            state.langSelected = state.langSelected.splice();
            state.showUserFeed = false;
        },
        [FILTER_SIGN_INITIAL] (state, signature) {
            state.initialSignature = signature;
        }
    }
}

function factoryActions() {
    return {
        signInitial (context) {
            context.commit(FILTER_SIGN_INITIAL, context.getters.signature);
        },
        populate (context, store) {
            if ("object" == typeof store) {
                context.dispatch("populateSource", store);
                context.dispatch("populateSourceType", store);
                context.dispatch("populateVoteType", store);
                context.dispatch("populateCurrency", store);
                context.dispatch("populateTag", store);
                context.dispatch("populateCountry", store);
                context.dispatch("populateLang", store);
            }
        },
        clear (context) {
            context.commit(FILTER_CLEAR);
        },
        clearToInitialState (context) {
            context.dispatch("clear");
            context.dispatch("populate", context.getters.savedFilter);
        },
        populateSource (context, store) {
            if (Array.isArray(store.sourceListSelected)) {
                store.sourceListSelected.forEach(function(source) {
                    if ("object" == typeof source) {
                        context.commit(FILTER_SELECT_SOURCE, source)
                    }
                });
            }
        },
        populateSourceType (context, store) {
            if (Array.isArray(store.sourceTypeSelected)) {
                store.sourceTypeSelected.forEach(function(sourceTypeId) {
                    context.commit(FILTER_SELECT_SOURCE_TYPE, sourceTypeId)
                });
            }
        },
        populateVoteType (context, store) {
            if (Array.isArray(store.voteTypeSelected)) {
                store.voteTypeSelected.forEach(function(voteTypeId) {
                    context.commit(FILTER_SELECT_VOTE_TYPE, voteTypeId)
                });
            }
        },
        populateCurrency (context, store) {
            if (Array.isArray(store.currencyListSelected)) {
                store.currencyListSelected.forEach(function(currency) {
                    if ("object" == typeof currency) {
                        context.commit(FILTER_SELECT_CURRENCY, currency)
                    }
                });
            }
        },
        populateTag (context, store) {
            if (Array.isArray(store.tagListSelected)) {
                store.tagListSelected.forEach(function(tag) {
                    if ("object" == typeof tag) {
                        context.commit(FILTER_SELECT_TAG, tag)
                    }
                });
            }
        },

        populateCountry (context, store) {
            if (Array.isArray(store.countrySelected)) {
                store.countrySelected.forEach(function(country) {
                    context.commit(FILTER_SELECT_COUNTRY, country)
                });
            }
        },

        populateLang (context, store) {
            if (Array.isArray(store.langSelected)) {
                store.langSelected.forEach(function(lang) {
                    context.commit(FILTER_SELECT_LANG, lang)
                });
            }
        },

        // filter switcher
        setShowUserFeed (context, value) {
            context.commit(FILTER_SET_SHOW_USER_FEED, value);
        },

        // currencies
        setCurrency (context, value) {
            context.commit(FILTER_SET_CURRENCY, value);
        },
        selectCurrency (context, currency) {
            context.commit(FILTER_SELECT_CURRENCY, currency);
        },
        unselectCurrency (context, currency) {
            context.commit(FILTER_UNSELECT_CURRENCY, currency);
        },
        clearCurrency (context) {
            context.commit(FILTER_CLEAR_CURRENCY);
        },
        touchCurrency (context) {
            if ("undefined" == typeof context.state.currencyListSelected) {
                context.commit(FILTER_CLEAR_CURRENCY);
            }
        },

        // tags
        setTag (context, value) {
            context.commit(FILTER_SET_TAG, value);
        },
        selectTag (context, tag) {
            context.commit(FILTER_SELECT_TAG, tag);
        },
        unselectTag (context, tag) {
            context.commit(FILTER_UNSELECT_TAG, tag);
        },
        clearTag (context) {
            context.commit(FILTER_CLEAR_TAG);
        },
        touchTag (context) {
            if ("undefined" == typeof context.state.tagListSelected) {
                context.commit(FILTER_CLEAR_TAG);
            }
        },

        // sources
        setSource (context, value) {
            context.commit(FILTER_SET_SOURCE, value);
        },
        selectSource (context, source) {
            context.commit(FILTER_SELECT_SOURCE, source);
        },
        unselectSource (context, source) {
            context.commit(FILTER_UNSELECT_SOURCE, source);
        },
        clearSource (context) {
            context.commit(FILTER_CLEAR_SOURCE);
        },
        touchSource (context) {
            if ("undefined" == typeof context.state.sourceListSelected) {
                context.commit(FILTER_CLEAR_SOURCE);
            }
        },

        // source types
        setSourceType (context, value) {
            context.commit(FILTER_SET_SOURCE_TYPE, value);
        },
        selectSourceType (context, sourceType) {
            context.commit(FILTER_SELECT_SOURCE_TYPE, sourceType);
        },
        unselectSourceType (context, sourceType) {
            context.commit(FILTER_UNSELECT_SOURCE_TYPE, sourceType);
        },
        clearSourceType (context) {
            context.commit(FILTER_CLEAR_SOURCE_TYPE);
        },
        touchSourceType (context) {
            if ("undefined" == typeof context.state.sourceTypeSelected) {
                context.commit(FILTER_CLEAR_SOURCE_TYPE);
            }
        },

        // vote type
        setVoteType (context, value) {
            context.commit(FILTER_SET_VOTE_TYPE, value);
        },
        selectVoteType (context, sourceType) {
            context.commit(FILTER_SELECT_VOTE_TYPE, sourceType);
        },
        unselectVoteType (context, sourceType) {
            context.commit(FILTER_UNSELECT_VOTE_TYPE, sourceType);
        },
        clearVoteType (context) {
            context.commit(FILTER_CLEAR_VOTE_TYPE);
        },
        touchVoteType (context) {
            if ("undefined" == typeof context.state.voteTypeSelected) {
                context.commit(FILTER_CLEAR_VOTE_TYPE);
            }
        },

        // langs
        setLang (context, value) {
            context.commit(FILTER_SET_LANG, value);
        },
        selectLang (context, lang) {
            context.commit(FILTER_SELECT_LANG, lang);
        },
        unselectLang (context, lang) {
            context.commit(FILTER_UNSELECT_LANG, lang);
        },
        clearLang (context) {
            context.commit(FILTER_CLEAR_LANG);
        },
        touchLang (context) {
            if ("undefined" == typeof context.state.langSelected) {
                context.commit(FILTER_CLEAR_LANG);
            }
        },

        // countries
        setCountry (context, value) {
            context.commit(FILTER_SET_COUNTRY, value);
        },
        selectCountry (context, country) {
            context.commit(FILTER_SELECT_COUNTRY, country);
        },
        unselectCountry (context, country) {
            context.commit(FILTER_UNSELECT_COUNTRY, country);
        },
        clearCountry (context) {
            context.commit(FILTER_CLEAR_COUNTRY);
        },
        touchCountry (context) {
            if ("undefined" == typeof context.state.countrySelected) {
                context.commit(FILTER_CLEAR_COUNTRY);
            }
        },
    }
}

export default function () {
    return {
        namespaced: true,
        state: factoryState(),
        getters: factoryGetters(),
        mutations: factoryMutations(),
        actions: factoryActions(),
    }
}