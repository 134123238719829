import PageAccount from "./../../views/account.vue";
import PageAccountFeed from "./../../views/account-feed.vue"
import PageAccountPortfolio from "./../../views/account-portfolio.vue"

export default [
    {
        path: '/account',
        name: 'account',
        component: PageAccount,
    },
    {
        path: '/account/feed',
        name: 'account_feed',
        component: PageAccountFeed,
    },
    {
        path: '/account/portfolio',
        name: 'account_portfolio',
        component: PageAccountPortfolio,
    },
    {
        path: '/api/account/data',
        name: 'api_account_data'
    },
    {
        path: '/api/account/update',
        name: 'api_account_update'
    },
    {
        path: '/api/account/resend-confirmation',
        name: 'api_account_resend_confirmation'
    },
    {
        path: '/api/oauth/remove',
        name: 'api_oauth_remove'
    },
    {
        path: '/api/account/locale-save',
        name: 'api_account_locale_save'
    },
    {
        path: '/api/account/currency-toggle',
        name: 'api_account_currency_toggle'
    },
    {
        path: '/api/account/tag-toggle',
        name: 'api_account_tag_toggle'
    },
    {
        path: '/api/portfolio/data',
        name: 'api_portfolio_data'
    },
    {
        path: '/api/portfolio/table',
        name: 'api_portfolio_table'
    },
    {
        path: '/api/portfolio/create',
        name: 'api_portfolio_create'
    },
    {
        path: '/api/portfolio/update',
        name: 'api_portfolio_update'
    },
    {
        path: '/api/portfolio/delete',
        name: 'api_portfolio_delete'
    },
    {
        path: '/api/portfolio-history/clear',
        name: 'api_portfolio_history_clear'
    }
]