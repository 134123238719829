<template>
    <layout v-bind:mobile-mode="mobileMode">
        <template slot="header-line">
            <header-line></header-line>
        </template>
        <custom-scroll class="content__col content__col_left account_feed" 
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <filter-form
                    v-if="mobileMode"
                    v-on:update:user-feed="userFeed = $event"
                    v-bind:user-feed="true"
                    v-bind:show-user-feed-switcher="false"
                    v-bind:mobile-mode="mobileMode"
                    style="border-bottom: 1px solid #e1e2e7;"
            ></filter-form>
            <form-telegram-bot
                    ref="formTelegramBot"
            ></form-telegram-bot>
        </custom-scroll>
        <custom-scroll v-if="!mobileMode" 
            class="content__col content__col_right"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <filter-form
                    v-on:update:user-feed="userFeed = $event"
                    v-bind:user-feed="true"
                    v-bind:show-user-feed-switcher="false"
                    v-bind:mobile-mode="mobileMode"
            ></filter-form>
        </custom-scroll>
    </layout>
</template>
<script>
    import API from './../api'
    import PageMixin from "./../mixins/page"
    import HeaderLine from "./../components/account/header-line.vue"
    import FormTelegramBot from "./../components/account/form-telegram-bot.vue"
    import FilterForm from "./../components/filter/filter-form.vue"

    export default {
        mixins: [PageMixin],
        components: {
            HeaderLine,
            FilterForm,
            FormTelegramBot,
        },
        methods: {
            pageReady() {
                this.loadBotData();
            },
            loadBotData() {
                API.bot.dataTelegram().then((response) => {
                    if (response.data.bot) {
                        this.$refs.formTelegramBot.setData(response.data.bot);
                    }
                });
            },
        }
    }
</script>