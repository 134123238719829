<template>
    <div>
        <svg class="purse__icon" xmlns:xlink="http://www.w3.org/1999/xlink" width="23" height="21">
            <use xlink:href="#icon-purse"></use>
        </svg>
        <div class="purse__info">
            <div class="purse__cost">${{ portfolioTotalValueUSD | toCurrencyShort }}</div>
            <div v-bind:class="{
            'currency-trend': true,
            'currency-trend_down' : portfolioTotalPercentChange < 0,
            'currency-trend_up' : portfolioTotalPercentChange >= 0}">{{ portfolioTotalPercentChange | toPercent | withSign }} %</div>
        </div>
        <a class="purse__link" href="#portfolio" v-on:click.prevent="openPortfolio"></a>
    </div>
</template>
<script>
    import {mapState} from "vuex"
    import NotifyMixin from "./../mixins/notify"

    export default {
        name: "portfolio",
        mixins: [NotifyMixin],
        computed: {
            ...mapState("auth", [
                "loggedIn"
            ]),
            ...mapState("portfolio", [
                "totalValueUSD",
                "totalPercentChange24h"
            ]),
            portfolioTotalValueUSD () {
                return this.loggedIn ? this.totalValueUSD : 0;
            },
            portfolioTotalPercentChange () {
                return this.loggedIn ? this.totalPercentChange24h : 0.00;
            }
        },
        methods: {
            openPortfolio () {
                if (!this.loggedIn) {
                    this.notifyError(this.$t('portfolio.MustLoginToUsePortfolio'));
                    return;
                }
                this.$router.push({name: 'account_portfolio'})
            }
        }
    }
</script>