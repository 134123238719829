export default function () {
    this.currency = {
        item: ({slug}) => {
            return this.post({
                to: {name: 'api_currency_data', params: {slug: slug}},
                payload: {}
            });
        },
        list: (payload) => {
            return this.post({
                to: {name: 'api_currency_list'},
                payload: payload,
            });
        },
        listCommon: (payload) => {
            return this.post({
                to: {name: 'api_currency_list_common'},
                payload: payload,
            });
        },
        search: ({query}) => {
            return this.post({
                to: {name: 'api_currency_search'},
                payload: {query}
            });
        },
        chartData: ({currency, from, to}) => {
            return this.get({
                to: {name: 'api_currency_chart_data', params: {currency, from, to}},
                payload: {}
            });
        },
        chartDataAll: ({currency}) => {
            return this.get({
                to: {name: 'api_currency_chart_data_all', params: {currency}},
                payload: {}
            });
        },
    }
}