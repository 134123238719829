<template>
    <layout v-bind:country="country"
            v-bind:mobile-mode="mobileMode"
            v-bind:scrolled-down="scrolledDown">
        <template slot="header-line">
            <header-line
                    v-if="!!country"
                    v-bind:country="country"
                    v-on:close-country="closeCountry"
            ></header-line>
        </template>

        <custom-scroll
                v-if="visibleContentList"
                v-bind:class="{'content__col': true, 'content__col_left': visibleContentItem, 'content__col_wide': !visibleContentItem}"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>
            <content-list
                    ref="contentList"
                    v-bind:items="contentItems"
                    v-bind:mobile-mode="mobileMode"
                    v-bind:active-item="contentItem"
                    v-on:select-item="openContentItem"
                    v-on:load-more="contentListLoadMore"
            ></content-list>
        </custom-scroll>

        <custom-scroll
                v-if="visibleContentItem"
                class="content__col content__col_right"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>
            <content-detail v-if="visibleContentItem"
                            v-on:content-close="closeContentItem"
                            v-on:content-update="updateContentItem"
                            v-bind:item="contentItem"
                            v-bind:mobile-mode="mobileMode"
            ></content-detail>
        </custom-scroll>
    </layout>
</template>
<script>
    import {mapActions} from 'vuex'
    import API from './../api'
    import PageMixin from "./../mixins/page"
    import ContentListMixin from "./../mixins/content-list"
    import LoaderMixin from "./../mixins/loader"
    import HeaderLine from "./../components/country/header-line.vue"
    import ContentList from "./../components/content/content-list.vue"
    import ContentDetail from "./../components/content/content-detail.vue"

    export default {
        mixins: [PageMixin, ContentListMixin, LoaderMixin],
        components: {
            HeaderLine,
            ContentList,
            ContentDetail
        },
        computed: {
            visibleContentList () {
                return !!this.country && (!this.mobileMode || (!this.visibleCountryDetail && !this.visibleContentItem));
            },
            visibleContentItem () {
                return !!this.contentItem
            }
        },
        data() {
            return {
                country: null,
                showNews: false
            }
        },
        beforeRouteUpdate (to, from, next) {
            if (to.name == 'country') {
                this.contentItem = null;
                if (to.params.country != from.params.country) {
                    this.contentItems = [];
                    this.countryItemLoad({slug: to.params.country});
                }
            }
            next();
        },
        methods: {
            ...mapActions('portfolioData', [
                'setTableItems',
            ]),
            pageReady() {
                this.countryItemLoad({slug: this.$router.currentRoute.params.country});
                if (this.$router.currentRoute.name == 'country_content_item') {
                    this.contentItemLoad({slug: this.$router.currentRoute.params.slug});
                }
            },
            countryItemLoad(params) {
                this.contentListLoading = true;
                this.loadingStart();
                API.country.item(params).then((response) => {
                    // set country
                    this.country = response.data.country;

                    // set country news
                    if (response.data.items.length > 0) {
                        this.contentItems = this.contentItems.concat(response.data.items);
                        this.contentLoadMoreLoaded();
                    } else {
                        this.contentLoadMoreComplete();
                    }
                }).catch(() => {
                    this.contentLoadMoreComplete();
                }).finally(() => {
                    this.loadingStop();
                    this.contentListLoading = false;
                });
            },
            getContentListRequestParams() {
                return {
                    countrys: this.country ? [this.country.id] : []
                }
            },
            closeCountry() {
                this.contentItem = null;
                if (this.routeFrom != null && this.routeFrom.name == 'home') {
                    this.$router.go(-1);
                } else {
                    this.$router.replace({name: 'home'});
                }
            },
            openContentItem(item) {
                this.contentItem = item;
                let to = {
                    name: "country_content_item",
                    params: {
                        country: this.country.slug,
                        slug: this.contentItem.slug
                    }
                };
                if (this.$router.currentRoute.name == 'country_content_item') {
                    this.$router.replace(to);
                } else {
                    this.$router.push(to);
                }
            },
            closeContentItem() {
                this.contentItem = null;
                if (this.routeFrom != null && this.routeFrom.name == 'country') {
                    this.$router.go(-1);
                } else {
                    this.$router.replace({
                        name: 'country',
                        params: {country: this.country ? this.country.slug : null}
                    });
                }
            },
            openCountryNews() {
                this.showNews = true;
            },
        }
    }
</script>