<template>
    <div class="settings">
        <div class="settings__header settings-item">
            <div class="settings-item__row">
                <div class="settings__title">{{ $t('global.NewsFilter') }}</div>

                <input-switch
                    v-if="showUserFeedSwitcher"
                    v-model="userFeedProxy"
                    v-bind:disabled="!this.loggedIn"
                    v-on:input-disabled="touchFilterSwitch"
                >{{ $t('filter.MyFeed') }}</input-switch>

                <filter-button-save
                        v-if="hasFilterChanges"
                        v-on:save="saveFilter"
                        v-on:cancel="cancelFilter"
                ></filter-button-save>
            </div>
        </div>

        <template v-if="userFeed">

            <filter-search-tag
                    v-bind:user-feed="userFeed"
                    v-bind:tag-top="tagTop"
                    v-bind:tag-list-selected="filterFeedTagListSelected"
                    v-on:select="filterFeedSetTag"
                    v-on:clear="filterFeedClearTag"
            ></filter-search-tag>

            <filter-search-source
                    v-bind:user-feed="userFeed"
                    v-bind:source-list-selected="filterFeedSourceListSelected"
                    v-on:select="filterFeedSetSource"
                    v-on:clear="filterFeedClearSource"
            ></filter-search-source>

            <filter-select-country
                    v-bind:items="countries"
                    v-bind:country-selected="filterFeedCountrySelected"
                    v-on:select="filterFeedSetCountry"
                    v-on:clear="filterFeedClearCountry"
            ></filter-select-country>

            <filter-select-source-type
                    v-bind:source-type-selected="filterFeedSourceTypeSelected"
                    v-on:select="filterFeedSetSourceType"
                    v-on:clear="filterFeedClearSourceType"
            ></filter-select-source-type>

            <filter-select-lang
                    v-bind:lang-selected="filterFeedLangSelected"
                    v-on:select="filterFeedSetLang"
                    v-on:clear="filterFeedClearLang"
            ></filter-select-lang>

            <filter-select-vote-type
                    v-bind:vote-type-selected="filterFeedVoteTypeSelected"
                    v-on:select="filterFeedSetVoteType"
                    v-on:clear="filterFeedClearVoteType"
            ></filter-select-vote-type>
        </template>
        <template v-else>
            <filter-search-tag
                    v-bind:user-feed="userFeed"
                    v-bind:tag-top="tagTop"
                    v-bind:tag-list-selected="filterTagListSelected"
                    v-on:select="filterSetTag"
                    v-on:clear="filterClearTag"
            ></filter-search-tag>

            <filter-search-source
                    v-bind:user-feed="userFeed"
                    v-bind:source-list-selected="filterSourceListSelected"
                    v-on:select="filterSetSource"
                    v-on:clear="filterClearSource"
            ></filter-search-source>

            <filter-select-country
                    v-bind:items="countries"
                    v-bind:country-selected="filterCountrySelected"
                    v-on:select="filterSetCountry"
                    v-on:clear="filterClearCountry"
            ></filter-select-country>

            <filter-select-source-type
                    v-bind:source-type-selected="filterSourceTypeSelected"
                    v-on:select="filterSetSourceType"
                    v-on:clear="filterClearSourceType"
            ></filter-select-source-type>

            <filter-select-lang
                    v-bind:lang-selected="filterLangSelected"
                    v-on:select="filterSetLang"
                    v-on:clear="filterClearLang"
            ></filter-select-lang>

            <filter-select-vote-type
                    v-bind:vote-type-selected="filterVoteTypeSelected"
                    v-on:select="filterSetVoteType"
                    v-on:clear="filterClearVoteType"
            ></filter-select-vote-type>

        </template>
    </div>
</template>
<script>
    import API from './../../api'
    import {mapState, mapGetters, mapActions} from 'vuex'
    import NotifyMixin from './../../mixins/notify'
    import LoaderMixin from './../../mixins/loader'
    import loMap from 'lodash/map'
    import InputSwitch from './../ui/input-switch.vue'
    import FilterButtonSave from './filter-button-save.vue'
    import FilterSearchTag from './filter-search-tag.vue'
    import FilterSelectCountry from './filter-select-country.vue'
    import FilterSearchSource from './filter-search-source.vue'
    import FilterSelectSourceType from './filter-select-source-type.vue'
    import FilterSelectVoteType from './filter-select-vote-type.vue'
    import FilterSelectLang from './filter-select-lang.vue'

    export default {
        name: 'filter-form',
        mixins: [NotifyMixin, LoaderMixin],
        components: {
            InputSwitch,
            FilterButtonSave,
            FilterSearchTag,
            FilterSelectCountry,
            FilterSearchSource,
            FilterSelectSourceType,
            FilterSelectVoteType,
            FilterSelectLang
        },
        props: {
            mobileMode: {
                type: Boolean,
                require: true
            },
            userFeed: {
                type: Boolean,
                require: true
            },
            showUserFeedSwitcher: {
                type: Boolean,
                default: true
            },
            showMobileHeader: {
                type: Boolean,
                default: false,
            },
            showButtonOk: {
                type: Boolean,
                default: true
            },
            wrapClass: {
                type: Array,
                default () {
                    return ['main-news-filter-block'];
                }
            }
        },
        computed: {
            ...mapState('auth', [
                'loggedIn'
            ]),
            ...mapState('tag', [
                'tagTop'
            ]),
            ...mapState('country', [
                'countries'
            ]),
            ...mapState('filter', {
                filterSourceListSelected: state => state.sourceListSelected,
                filterTagListSelected: state => state.tagListSelected,
                filterCountrySelected: state => state.countrySelected,
                filterSourceTypeSelected: state => state.sourceTypeSelected,
                filterVoteTypeSelected: state => state.voteTypeSelected,
                filterLangSelected: state => state.langSelected,
            }),
            ...mapState('filterFeed', {
                filterFeedInitialSignature: state => state.initialSignature,
                filterFeedSourceListSelected: state => state.sourceListSelected,
                filterFeedTagListSelected: state => state.tagListSelected,
                filterFeedCountrySelected: state => state.countrySelected,
                filterFeedSourceTypeSelected: state => state.sourceTypeSelected,
                filterFeedVoteTypeSelected: state => state.voteTypeSelected,
                filterFeedLangSelected: state => state.langSelected,
            }),
            ...mapGetters('filter', {
                filterSignature : 'signature'
            }),
            ...mapGetters('filterFeed', {
                filterFeedSignature : 'signature'
            }),
            userFeedProxy: {
                get () {
                    return this.userFeed
                },
                set (val) {
                    this.$emit('update:user-feed', val);
                }
            },
            hasFilterChanges () {
                return this.userFeed
                    ? this.filterFeedSignature != this.filterFeedInitialSignature
                    : this.filterSignature.length > 0 && this.filterSignature != this.filterFeedInitialSignature;
            }
        },
        methods: {
            ...mapActions('filter', {
                'filterSetTag': 'setTag',
                'filterClearTag': 'clearTag',
                'filterSetCountry': 'setCountry',
                'filterClearCountry': 'clearCountry',
                'filterSetSource': 'setSource',
                'filterClearSource': 'clearSource',
                'filterSetVoteType': 'setVoteType',
                'filterClearVoteType': 'clearVoteType',
                'filterSetSourceType': 'setSourceType',
                'filterClearSourceType': 'clearSourceType',
                'filterSetLang': 'setLang',
                'filterClearLang': 'clearLang',
                'filterSetShowUserFeed': 'setShowUserFeed',
                'filterClear':'clear'
            }),
            ...mapActions('filterFeed', {
                'filterFeedSetTag': 'setTag',
                'filterFeedClearTag': 'clearTag',
                'filterFeedSetCountry': 'setCountry',
                'filterFeedClearCountry': 'clearCountry',
                'filterFeedSetSource': 'setSource',
                'filterFeedClearSource': 'clearSource',
                'filterFeedSetVoteType': 'setVoteType',
                'filterFeedClearVoteType': 'clearVoteType',
                'filterFeedSetSourceType': 'setSourceType',
                'filterFeedClearSourceType': 'clearSourceType',
                'filterFeedSetLang': 'setLang',
                'filterFeedClearLang': 'clearLang',
                'filterFeedPopulate': 'populate',
                'filterFeedSignInitial': 'signInitial',
                'filterFeedClear':'clear',
                'filterFeedClearToInitialState': 'clearToInitialState',
            }),
            touchFilterSwitch () {
                if (this.loggedIn == false) {
                    this.notifyError(this.$t('filter.MustLoginToUserMyFeed'));
                }
            },
            saveFilter () {
                if (this.loggedIn == false) {
                    this.notifyError(this.$t('filter.MustLoginToUserMyFeed'));
                    return;
                }
                this.loadingStart();
                if (this.userFeed) {
                    this.requestSaveFeedFilter().then((response) => {
                        this.filterFeedClear();
                        this.filterFeedPopulate(response.data.filter);
                        this.filterFeedSignInitial();
                        this.notifySuccess(this.$t('filter.Saved'))
                    }).finally(() => {
                        this.loadingStop();
                    });
                } else {
                    this.requestSaveFilter().then((response) => {
                        this.filterFeedClear();
                        this.filterFeedPopulate(response.data.filter);
                        this.filterFeedSignInitial();
                        this.notifySuccess(this.$t('filter.Saved'))
                    }).finally(() => {
                        this.loadingStop();
                    });
                }
            },
            cancelFilter () {
                if (this.userFeed) {
                    this.filterFeedClearToInitialState();
                } else {
                    this.filterClear();
                }
            },
            requestSaveFeedFilter () {
                return API.filter.save({
                    sources: loMap(this.filterFeedSourceListSelected, 'id'),
                    tags: loMap(this.filterFeedTagListSelected, 'id'),
                    countries: this.filterFeedCountrySelected,
                    sourceTypes: this.filterFeedSourceTypeSelected,
                    voteTypes: this.filterFeedVoteTypeSelected,
                    langs: this.filterFeedLangSelected,
                });
            },
            requestSaveFilter () {
                return API.filter.save({
                    sources: loMap(this.filterSourceListSelected, 'id'),
                    tags: loMap(this.filterTagListSelected, 'id'),
                    countries: this.filterCountrySelected,
                    sourceTypes: this.filterSourceTypeSelected,
                    voteTypes: this.filterVoteTypeSelected,
                    langs: this.filterLangSelected,
                });
            }
        }
    }
</script>