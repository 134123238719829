<template>
    <div class="field-set-social__item" style="background: #3a559f">
        <svg class="field-set-social__icon" xmlns:xlink="http://www.w3.org/1999/xlink" width="13" height="26">
            <use xlink:href="#icon-fb"></use>
        </svg>
        <a class="field-set-social__link" v-bind:href="link"></a>
    </div>
</template>
<script>
    export default {
        name: 'oauth-facebook',
        props: {
            link: {
                type: String,
                require: true
            }
        }
    }
</script>