<template>
    <div class="field-set">

        <div class="field-set__row">
            <div class="field-set__title">{{ $t('auth.TitleNewPassword') }}</div>
        </div>

        <form class="field-set-form" action="#">

            <field-password
                    server-field-name="Password"
                    v-model="$v.password.$model"
                    v-on:input="formTouch"
                    v-bind:label="$t('auth.LabelNewPassword')"
                    v-bind:vuelidate-field="$v.password"
                    v-bind:form-errors="formErrors"
                    v-bind:form-dirty="formDirty"
            ></field-password>

            <field-password
                    server-field-name="PasswordConfirm"
                    v-model="$v.passwordConfirm.$model"
                    v-on:input="formTouch"
                    v-bind:label="$t('auth.LabelNewPasswordConfirm')"
                    v-bind:vuelidate-field="$v.passwordConfirm"
                    v-bind:form-errors="formErrors"
                    v-bind:form-dirty="formDirty"
            ></field-password>

            <button v-on:click.prevent="formSubmit" class="button">{{ $t('auth.SubmitChangePassword') }}</button>
        </form>
    </div>
</template>
<script>
    import API from './../../api'
    import FormMixin from './../../mixins/form'
    import FieldPassword from './../ui/field-password.vue'
    import {required, minLength, sameAs} from 'vuelidate/lib/validators'

    export default {
        name: 'form-change-password',
        mixins: [FormMixin],
        components: {
            FieldPassword
        },
        validations: {
            password: {
                required,
                minLength: minLength(5)
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            },
            token: {}
        },
        props: {
            token: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                password: '',
                passwordConfirm: ''
            }
        },
        methods: {
            formRequest() {
                return API.account.changePassword({
                    token: this.token,
                    password: this.password,
                    passwordConfirm: this.passwordConfirm
                });
            },
            formProcessSuccessResponse() {
                this.notifySuccess(this.$t('auth.MessageChangePasswordSuccess')).on('destroyed', () => {
                    this.$router.push({name: 'auth'});
                });
            }
        }
    }
</script>