<template>
    <div class="portfolio-rates">
        <table class="currency-detail-price">
            <tbody><tr>
                <td class="currency-detail-price__percent">USD</td>
                <td class="currency-detail-price__cost">{{ summaryUSD | toCurrencySmart }}</td>
            </tr>
            <tr>
                <td class="currency-detail-price__percent">BTC</td>
                <td class="currency-detail-price__cost">{{ summaryBTC | toCurrencySmart }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    export default {
        name: "portfolio-summary",
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        computed: {
            summaryUSD () {
                let res = 0;
                for (let i = 0; i < this.items.length; i++) {
                    res += parseFloat(this.items[i].valueUSD);
                }
                return res;
            },
            summaryBTC () {
                let res = 0;
                for (let i = 0; i < this.items.length; i++) {
                    res += parseFloat(this.items[i].valueBTC);
                }
                return res;
            }
        }
    }
</script>