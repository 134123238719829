import {
    CURRENCY_TOGGLE_SHOW_FAVORITES,
    CURRENCY_TOP_SET,
    CURRENCY_FAVORITES_SET,
    CURRENCY_CLEAR,
} from "./../mutation-types";

const state = {
    showFavorites: false,
    currencyFavorites: [],
    currencyTop: [],
};

const getters = {

};

const mutations = {
    [CURRENCY_TOGGLE_SHOW_FAVORITES] (state) {
        state.showFavorites = !state.showFavorites;
    },
    [CURRENCY_TOP_SET] (state, currencies) {
        state.currencyTop = currencies
    },
    [CURRENCY_FAVORITES_SET] (state, currencies) {
        state.currencyFavorites = currencies
    },
    [CURRENCY_CLEAR] (state) {
        state.currencyFavorites = [];
        state.showFavorites = false;
    }
};

const actions = {
    toggleShowFavorites (store) {
        store.commit(CURRENCY_TOGGLE_SHOW_FAVORITES);
    },
    setCurrencyTop (store, currencies) {
        store.commit(CURRENCY_TOP_SET, currencies);
    },
    setCurrencyFavorites (store, currencies) {
        store.commit(CURRENCY_FAVORITES_SET, currencies);
    },
    clear (store) {
        store.commit(CURRENCY_CLEAR);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}