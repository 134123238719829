import {
    TAG_TABLE_SET_QUERY,
    TAG_TABLE_SET_ORDER,
    TAG_TABLE_SET_DESK,
    TAG_TABLE_TOGGLE_SHOW_FAVORITE,
} from "./../mutation-types";

const state = {
    tagTableQuery: "",
    tagTableOrder: "",
    tagTableDesk: 1,
    tagTableShowFavorite: 0,
};

const getters = {

};

const mutations = {
    [TAG_TABLE_TOGGLE_SHOW_FAVORITE] (state) {
        state.tagTableShowFavorite = !state.tagTableShowFavorite;
    },
    [TAG_TABLE_SET_QUERY] (state, value) {
        state.tagTableQuery = value
    },
    [TAG_TABLE_SET_ORDER] (state, value) {
        state.tagTableOrder = value
    },
    [TAG_TABLE_SET_DESK] (state, value) {
        state.tagTableDesk = value
    },
};

const actions = {
    tagTableToggleShowFavorite (store) {
        store.commit(TAG_TABLE_TOGGLE_SHOW_FAVORITE);
    },
    tagTableSetQuery (store, value) {
        store.commit(TAG_TABLE_SET_QUERY, value);
    },
    tagTableSetOrder (store, value) {
        store.commit(TAG_TABLE_SET_ORDER, value);
    },
    tagTableSetDesk (store, value) {
        store.commit(TAG_TABLE_SET_DESK, value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}