<template>
    <div>
        <div class="field-set-form__name">{{ label }}</div>
        <div class="input-text">
            <input type="text"
                   v-bind:name="fieldName"
                   v-model.trim="fieldValue" />
            <slot name="notes"></slot>
            <vuelidate-errors
                    v-if="formDirty"
                    v-bind:field="vuelidateField"
                    v-bind:server-errors="formErrors"
                    v-bind:server-field="serverFieldName">
                <slot name="errors">
                    <vuelidate-message rule="required">{{ $t('errors.Required') }}</vuelidate-message>
                    <vuelidate-message rule="email">{{ $t('errors.EmailInvalid') }}</vuelidate-message>
                    <vuelidate-message rule="emailNotFound">{{ $t('errors.EmailNotFound') }}</vuelidate-message>
                    <vuelidate-message rule="emailAlreadyExist">{{ $t('errors.EmailAlreadyExist') }}</vuelidate-message>
                    <vuelidate-message rule="invalidCredentials">{{ $t('errors.InvalidEmailOrPassword') }}</vuelidate-message>
                </slot>
            </vuelidate-errors>
            <div class="input-text__clear"
                 v-if="fieldValue != ''"
                 v-on:click="clear"></div>
        </div>
    </div>
</template>
<script>
    import FieldBaseMixin from './field-base'

    export default {
        mixins: [FieldBaseMixin],
        name: 'field-email',
    }
</script>