<template>
    <time class="news-list-item__time"
          v-bind:datetime="datetime"
          v-bind:title="datetime">{{ sinceFormat }}
    </time>
</template>
<script>
    export default {
        name: 'time-ago',
        props: {
            since: {
                required: true
            },
            timeAgo: {
                required: true
            }
        },
        computed: {
            datetime() {
                return new Date(this.since)
            },
            sinceFormat() {
                let res = "";

                if (this.timeAgo < 0) {
                    res = "0min"
                }
                else if (this.timeAgo < 3600) {
                    res = Math.floor(this.timeAgo / 60) + "min"
                }
                else if (this.timeAgo < 86400) { // 3600 * 24
                    res = Math.floor(this.timeAgo / 3600) + "h"
                }
                else if (this.timeAgo < 604800) { // 3600 * 24 * 7
                    res = Math.floor(this.timeAgo / 86400) + "d"
                }
                else if (this.timeAgo < 2592000) { // 3600 * 24 * 30
                    res = Math.floor(this.timeAgo / 604800) + "w"
                }
                else if (this.timeAgo < 31536000) { // 3600 * 24 * 365
                    res = Math.floor(this.timeAgo / 2592000) + "m"
                }
                else {
                    res = Math.floor(this.timeAgo / 31536000) + "y"
                }

                return res;
            }
        }
    }
</script>