<template>
    <div class="coins">
        <tag-table-filter></tag-table-filter>
        <custom-scroll class="table__scroll"
            v-bind:scroll-vertical="false"
            v-bind:scroll-horizontal="mobileMode"
            v-bind:mobile-mode="mobileMode">
            <table class="table">
                <tag-table-head></tag-table-head>
                <tag-table-item
                        v-for="(tag, index) in tags"
                        v-bind:key="index"
                        v-bind:tag="tag"
                        v-bind:active-tag="activeTag"
                        v-on:select-tag="selectTag(tag)"
                ></tag-table-item>
            </table>
        </custom-scroll>

        <load-more ref="loadMore"
                   v-bind:mobile-mode="mobileMode"
                   v-on:load-more="loadMoreHandler"></load-more>
    </div>
</template>
<script>
    import TagTableStoreMixin from './../../mixins/tag-table-store'
    import LoadMoreMixin from './../../mixins/load-more';
    import TagTableFilter from "./tag-table-filter.vue"
    import TagTableHead from "./tag-table-head.vue"
    import TagTableItem from "./tag-table-item.vue"
    import LoadMore from './../load-more.vue';

    export default {
        name: "tag-table",
        mixins: [LoadMoreMixin, TagTableStoreMixin],
        components: {
            TagTableFilter,
            TagTableHead,
            TagTableItem,
            LoadMore,
        },
        props: {
            mobileMode: {
                type: Boolean,
                required: true
            },
            activeTag: {
                required: true
            },
            tags: {
                type: Array,
                required: true
            }
        },
        methods: {
            selectTag (tag) {
                this.$emit('select-tag', tag);
            }
        }
    }
</script>