// NPM packages
import Vue from "vue"
import VueI18n from 'vue-i18n'
import VueSnotify from "vue-snotify"
import VueRecaptcha from "vue-recaptcha"
import Vuelidate from "vuelidate"
import Raven from "raven-js"
import RavenVue from "raven-js/plugins/vue"

// Required module
import App from "./App.vue"
import Router from "./router"
import VueFilters from "./utils/vue-filters"
import CustomScroll from "./components/ui/custom-scroll.vue"
import store from "./store"
import translateOptions from "./translate"

export default {
    isProd: null,
    router: null,
    i18n: null,

    /*
     * Configure Vue
     * http://vuejs.org/v2/api/#Global-Config
     */
    configureVue () {
        Vue.config.productionTip = false;
        Vue.config.performance = true;
        return this
    },

    initVars () {
        this.isProd = (process.env.NODE_ENV === "production");
        return this
    },

    initTranslate () {
        Vue.use(VueI18n);
        translateOptions.locale = store.state.locale;
        this.i18n = new VueI18n(translateOptions);
        return this;
    },

    registerGlobalComponents () {
        Vue.component("vue-recaptcha", VueRecaptcha);
        Vue.component("custom-scroll", CustomScroll);
        return this;
    },

    addRouter () {
        this.router = Router;
        return this
    },

    addVueFilters () {
        Vue.use(VueFilters);
        return this;
    },

    addVueSnotify() {
        Vue.use(VueSnotify);
        return this;
    },

    addVueildate() {
        Vue.use(Vuelidate);
        return this;
    },

    addSentry () {
        if (process.env.VUE_APP_SENTRY_DSN) {
            Raven.config(process.env.VUE_APP_SENTRY_DSN).addPlugin(RavenVue, Vue).install();
        }
        return this
    },

    run () {
        window.APP = new Vue({
            el: "#app",
            render: h => h(App),
            router: this.router,
            store: store,
            i18n: this.i18n,
        })
    }
}