<template>
    <router-link class="header-menu__item" v-bind:to="to" v-bind:data-active="active">
        <nav-icon v-bind:type="icon"></nav-icon>
        <span class="header-menu__name"><slot></slot></span>
    </router-link>
</template>
<script>
    import routerMixin from './../../mixins/router'
    import NavIcon from './../icons/nav.vue'

    export default {
        name: 'nav-item',
        mixins: [routerMixin],
        components: {
            NavIcon
        },
        props: {
            to: {
                required: true
            },
            icon: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            matches: {
                type: Array,
                default () {
                    return []
                }
            },
        },
        computed: {
            href () {
                return this.routeHref(this.to);
            },
            active () {
                return this.matches.indexOf(this.$route.name) !== -1
            }
        },
        methods: {
            open () {
                this.$router.push(this.to);
            }
        },
    }
</script>