<template>
    <div>
        <template v-for="(item, index) in items">
            <slot v-bind:item="item" v-bind:index="index" v-bind:selected="selected">
                <div v-bind:key="index" v-bind:class="itemClass">
                    <label class="input-checkbox">
                        <input type="checkbox"
                               v-bind:value="item.value"
                               v-model="selected"/>
                        <div class="input-checkbox__check"></div>

                        <div v-if="item.icon" class="input-checkbox__text">
                            <img v-bind:src="item.icon" v-bind:alt="$t(item.name)" v-bind:title="$t(item.name)" />
                        </div>
                        <div v-else class="input-checkbox__text">{{ $t(item.name) }}</div>
                    </label>
                </div>
            </slot>
        </template>
    </div>
</template>
<script>
    import uniqueId from 'lodash/uniqueId';

    export default {
        props: {
            name: {
                type: String,
                default: uniqueId('input')
            },
            itemClass: {
                type: String,
                default: ''
            },
            items: {
                type: Array,
                required: true
            },
            value: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            selected: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        methods: {
            key(index) {
                return this.name + '-' + index;
            },
        }
    }
</script>