<template>
    <div class="header-line">
        <div class="header-line__col" v-if="visibleCurrencyDetail">
            <div class="header-line-active-currency">{{ $t('global.Currency') }}: {{ currency.name }}
                <div class="header-line-active-currency__close" v-on:click.prevent="closeCurrency"></div>
            </div>
        </div>
        <div class="header-line__col" v-if="!visibleCurrencyDetail">
            <div class="header-line-active-currency">{{ $t('global.Currency') }}: {{ currency.name }} / {{ $t('global.News') }}
                <div class="header-line-active-currency__close" v-on:click.prevent="openCurrencyDetail"></div>
            </div>
        </div>
        <div class="header-line__col">
            <portfolio class="purse"></portfolio>
        </div>
    </div>
</template>
<script>
    import Portfolio from './../portfolio.vue'

    export default {
        name: 'header-line',
        components: {
            Portfolio
        },
        props: {
            currency: {
                type: Object,
                required: true
            },
            visibleCurrencyDetail: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            closeCurrency () {
                this.$emit('close-currency');
            },
            openCurrencyDetail () {
                this.$emit('open-currency-detail');
            }
        }
    }
</script>
<style>
    .header-line-active-currency {
        margin-left: 0;
    }
</style>