<template>
    <div class="form-telegram-bot field-set">
        <div class="field-set__row">
            <div class="field-set__title">{{ $t('account.TitleTelegramBotSettings') }}</div>
        </div>
        <form class="field-set-form" action="#">
            <field-input
                    server-field-name="WebhookURL"
                    v-model="$v.telegramId.$model"
                    v-on:input="formTouch"
                    label="Telegram ID"
                    v-bind:vuelidate-field="$v.telegramId"
                    v-bind:form-errors="formErrors"
                    v-bind:form-dirty="formDirty"
            >
                <template slot="notes">
                    <div class="notes">
                        <div class="notes__item" v-html="$t('account.BotManualStep1')"></div>
                        <div  class="notes__item" v-html="$t('account.BotManualStep2')"></div>
                    </div>
                </template>
            </field-input>

            <field-checkbox
                    v-if="id > 0"
                    server-field-name="Enabled"
                    v-model="$v.enabled.$model"
                    v-on:input="formTouch"
                    v-bind:label="$t('account.BotEnabled')"
                    v-bind:vuelidate-field="$v.enabled"
                    v-bind:form-errors="formErrors"
                    v-bind:form-dirty="formDirty"
            ></field-checkbox>

            <div class="field-set__row">
                <button class="button" v-on:click.prevent="formSubmit">{{ $t('global.Save') }}</button>
            </div>
        </form>
    </div>
</template>
<script>
    import API from './../../api'
    import FormMixin from './../../mixins/form'
    import FieldInput from './../ui/field-input.vue'
    import FieldCheckbox from './../ui/field-checkbox.vue'
    import {integer} from 'vuelidate/lib/validators'

    export default {
        name: 'form-telegram-bot',
        mixins: [FormMixin],
        components: {
            FieldInput,
            FieldCheckbox,
        },
        validations: {
            telegramId: {
                integer
            },
            enabled: {}
        },
        data() {
            return {
                id: 0,
                telegramId: "",
                enabled: false
            }
        },
        computed: {
            formData() {
                return {
                    id: this.id,
                    webhook_url: this.telegramId.toString(),
                    enabled: this.enabled
                }
            }
        },
        methods: {
            setData (data) {
                this.id = data.id;
                this.telegramId = data.webhook_url != 0 ? data.webhook_url : "";
                this.enabled = !!data.enabled;
            },
            formRequest () {
                if (this.id > 0) {
                    return API.bot.updateBot(this.formData);
                }
                return API.bot.createTelegram(this.formData);
            },
            formLoadData () {
                API.bot.dataTelegram().then((response) => {
                    if (response.data.bot) {
                        this.id = response.data.bot.id;
                        this.telegramId = response.data.bot.webhook_url != 0 ? response.data.bot.webhook_url : "";
                        this.enabled = !!response.data.bot.enabled;
                    }
                });
            },
            formProcessSuccessResponse() {
                this.formLoadData();
                this.notifySuccess(this.$t('global.MessageChangeSaved'));
            },
        }
    }
</script>