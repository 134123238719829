<template>
    <div class="vuelidate-errors" v-if="errorShouldBeDisplayed">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'field-errors',
        props: {
            field: {
                type: Object,
                required: true
            },
            serverField: {
                type: String,
                required: true
            },
            serverErrors: {
                type: Array,
                required: true
            },
        },
        computed: {
            errorShouldBeDisplayed() {
                return (this.field.$invalid && this.field.$dirty) || this.serverFieldErrors.length > 0;
            },
            serverFieldErrors () {
                let errors = [];
                this.serverErrors.forEach((err) => {
                    let errField = err[0];
                    let errTag = err[1];
                    if (errField == this.serverField) {
                        errors.push(errTag);
                    }
                });
                return errors;
            }
        }
    };
</script>

<style scoped>
    .vuelidate-errors {
        margin: 5px 0 0 15px;
        font-size: 12px;
        color: #d9534f;
    }
</style>