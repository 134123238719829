<template>
    <layout v-bind:mobile-mode="mobileMode">

        <custom-scroll class="content__col content__col_left"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <form-login
                    v-if="visibleFormLogin"
                    v-bind:mobile-mode="mobileMode"
                    v-on:show-form-create-account="showFormCreateAccount"
                    v-on:show-form-restore="showFormRestore"
            ></form-login>

            <form-restore
                    v-if="visibleFormRestore"
                    v-bind:mobile-mode="mobileMode"
                    v-on:show-form-create-account="showFormCreateAccount"
                    v-on:show-form-login="showFormLogin"
            ></form-restore>

        </custom-scroll>
        <custom-scroll class="content__col content__col_right"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <form-create-account
                    v-if="visibleFormCreateAccount"
                    v-bind:mobile-mode="mobileMode"
                    v-on:show-form-login="showFormLogin"
            ></form-create-account>
        </custom-scroll>

    </layout>
</template>
<script>
    import PageMixin from "./../mixins/page"
    import FormLogin from './../components/auth/form-login.vue'
    import FormCreateAccount from './../components/auth/form-create-account.vue'
    import FormRestore from './../components/auth/form-restore.vue'

    const FormCreateAccountName = 'formCreateAccount';
    const FormLoginName = 'formLogin';
    const FormRestoreName = 'formRestore';

    export default {
        mixins: [PageMixin],
        components: {
            FormLogin,
            FormCreateAccount,
            FormRestore
        },
        data () {
            return {
                activeForm: 'formLogin'
            }
        },
        computed: {
            visibleFormCreateAccount () {
                let showDesktop = !this.mobileMode;
                let showMobile = this.mobileMode && this.activeForm == FormCreateAccountName;
                return showDesktop || showMobile;
            },
            visibleFormLogin () {
                let showDesktop = !this.mobileMode && this.activeForm != FormRestoreName;
                let showMobile = this.mobileMode && this.activeForm == FormLoginName;
                return showDesktop || showMobile;
            },
            visibleFormRestore () {
                return this.activeForm == FormRestoreName;
            }
        },
        methods: {
            showFormLogin() {
                this.activeForm = FormLoginName;
            },
            showFormCreateAccount() {
                this.activeForm = FormCreateAccountName;
            },
            showFormRestore() {
                this.activeForm = FormRestoreName;
            }
        }
    }
</script>