<template>
    <div class="news-detail__text" v-html="item.text_headline"></div>
</template>
<style>
    .news-detail__text a {
        color: #999;
        text-decoration: none;
    }
    .news-detail__text a:hover {
        color: #999;
        text-decoration: underline;
    }
</style>
<script>
    export default {
        name: "embed-content-default",
        props: {
            item: {
                type: Object,
                required: true
            }
        }
    }
</script>