<template>
    <infinite-loading v-if="drawComponent"
                      spinner="waveDots"
                      v-on:infinite="infiniteHandler"
                      ref="infiniteLoading"
                      force-use-infinite-wrapper>
        <template slot="no-results">{{ $t("loadMore.NoResults") }}</template>
        <template slot="no-more">{{ $t("loadMore.NoMore") }}</template>
    </infinite-loading>
</template>
<script>
    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        name: 'load-more',
        props: {
            mobileMode: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                drawComponent: true
            };
        },
        components: {
            'infinite-loading': InfiniteLoading
        },
        methods: {
            complete () {
                this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete', {
                    target: this.$refs.infiniteLoading
                });
            },
            loaded () {
                this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded', {
                    target: this.$refs.infiniteLoading
                });
            },
            reset () {
                this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset', {
                    target: this.$refs.infiniteLoading
                });
            },
            infiniteHandler () {
                this.$emit('load-more');
            },
            forceReset () {
                this.drawComponent = false;
                this.$nextTick(() => {
                    this.drawComponent = true;
                });
            }
        }
    }
</script>