export default {
    ranges: {
        "day": "д",
        "month": "м",
        "year": "г",
        "all": "Все",
    },
    weekdays: {
        "Sunday": "Воскресенье",
        "Monday": "Понедельник",
        "Tuesday": "Вторник",
        "Wednesday": "Среда",
        "Thursday": "Четверг",
        "Friday": "Пятница",
        "Saturday": "Суббота"
    },
    shortWeekdays: {
        "Sunday": "Вс",
        "Monday": "Пн",
        "Tuesday": "Вт",
        "Wednesday": "ср",
        "Thursday": "Чт",
        "Friday": "Пт",
        "Saturday": "Сб"
    },
    months: {
        "January": "Январь",
        "February": "Февраль",
        "March": "Март",
        "April": "Апрель",
        "May": "Май",
        "June": "Июнь",
        "July": "Июль",
        "August": "Август",
        "September": "Сентябрь",
        "October": "Октябрь",
        "November": "Ноябрь",
        "December": "Декабрь"
    },
    shortMonths: {
        "Jan": "Янв",
        "Feb": "Фев",
        "Mar": "Мар",
        "Apr": "Апр",
        "May": "Май",
        "Jun": "Июн",
        "Jul": "Июл",
        "Aug": "Авг",
        "Sep": "Сен",
        "Oct": "Окт",
        "Nov": "Ноя",
        "Dec": "Дек"
    },
    menu: {
        "News": "Новости",
        "Coins": "Валюты",
        "Trends": "Тренды",
        "About": "О проекте",
        "Profile": "Профиль",
        "Login": "Вход",
        "Signup": "Регистрация",
    },
    global: {
        "Share": "Поделиться",
        "Currency": "Валюта",
        "Tag": "Тег",
        "Country": "Страна",
        "Countries": "Страны",
        "CountriesAll": "Все страны",
        "Trends": "Тренды",
        "TrendsAll": "Все тренды",
        "Filter": "Фильтр",
        "NewsFilter": "Фильтр",
        "NewsLanguage": "Язык новостей",
        "NewsVoteType": "Тип новостей",
        "NewsSourceType": "Тип источника",
        "Selected": "Выбранные",
        "SelectedEmpty": "Ничего не выбрано",
        "SearchEmpty": "Ничего не найдено",
        "Currencies": "Валюты",
        "CurrenciesAll": "Все валюты",
        "Sources": "Источники",
        "SourcesAll": "Все источники",
        "Search": "Найти",
        "Clear": "Очистить",
        "DismissAll": "Сбросить",
        "Back": "Назад",
        "Ok": "Готово",
        "Types": "Типы",
        "All": "Все",
        "News": "Новости",
        "Social": "Соцсети",
        "Twitter": "Twitter",
        "Reddit": "Reddit",
        "Facebook": "Facebook",
        "Instagram": "Instagram",
        "Langs": "Языки",
        "English": "Английский",
        "Russian": "Русский",
        "Chinese": "Китайский",
        "Top": "Топ",
        "ShowTop": "Показать топ",
        "SearchTagPlaceholder": "Используй поиск",
        "SearchCurrencyPlaceholder": "Используй поиск чтобы найти больше валют",
        "SearchSourcePlaceholder": "Используй поиск чтобы найти больше источников",
        "SearchCountryPlaceholder": "Используй поиск чтобы найти больше стран",
        "PriceUsd": "Цена USD",
        "PriceBtc": "Цена BTC",
        "VolumeUsd24h": "Объем USD 24ч",
        "LoadingData": "Загрузка данных ...",
        "DataSaved": "Данные сохранены",
        "MessageChangeSaved": "Изменения сохранены",
        "Save": "Сохранить",
        "Logout": "Выйти",
        "RequestFailed": "Не удалось выполнить запрос",
    },
    loadMore: {
        "NoResults": "Ничего не найдено, измените настройки фильтра!",
        "NoMore": "Больше ничего не найдено!",
    },
    filter: {
        "MyFeed": "Мой фильтр",
        "MustLoginToUserMyFeed": "Нужно авторизоваться, чтобы использовать мой фильтр",
        "Bullish": "Позитивные",
        "Bearish": "Негативные",
        "Positive": "Позитивные",
        "Negative": "Негативные",
        "Important": "Важные",
        "Trend": "Трендовые",
        "SaveToMyFeed": "Сохранить",
        "Saved": "Изменения сохранены",
    },
    account: {
        "MenuSettings": "Настройки",
        "MenuMyFeed": "Моя лента",
        "MenuPortfolio": "Портфолио",
        "MenuConnectedAccounts": "Связанные аккаунты",
        "TitleAccountSettings": "Настройки аккаунта",
        "TitleAccountConnections": "Связанные аккаунты",
        "TitleAddNewAccounts": "Добавить новые аккаунты",
        "TitleTelegramBotSettings": "Настройка Telegram бота для автоматической публикации новостей из вашей ленты в телеграм канал.",
        "TitleTelegramBotManual": "Руководство",
        "NoteYouCanUseSocialAccountForLogin": "Вы можете использовать аккаунты в социальных сетях для авторизации",
        "LabelEmailIsNotConfirmed": "Email не подтвержден!",
        "ButtonResendConfirmation": "Подтвердить Email",
        "MessageResendConfirmationSuccess": "Мы отправили вам электронное письмо для подтверждения",
        "MessageUserAccountRemoved": "Аккаунт удален",
        "MessageUserAccountRemoveFailed": "Не удалось удалить аккаунт",
        "Remove": "Удалить",
        "BotEnabled": "Включить",
        "BotManualStep1": '1. Пригласите бота <a href="https://t.me/IntwtComBot" target="_blank">@IntwtComBot</a> , как администратора с правами "Публикация сообщений", в ваш канал.',
        "BotManualStep2": '2. Используйте команду <code>/intwt id</code> в вашем канале, чтобы получить идентификатор чата для ввода в поле <code>Telegram ID</code>. Идентфикатор чата имеет формат: -100500.',
    },
    content: {
        "Source": "Оригинал",
        "Tags": "Теги",
        "WTF": "Как вы охарактеризуете эту новость?",
        "Bullish": "Позитивно",
        "Bearish": "Негативно",
        "Important": "Важно",
        "Trend": "Тренд",
        "MustLoginToVote": "Нужно авторизоваться, чтобы проголосовать",
    },
    tag: {
        "MustLoginToSaveFavorites": "Нужно авторизоваться, чтобы использовать избранное",
        "SearchPlaceholder": "Поиск тегов",
        "TableColumnName": "Название",
        "ShowNews": "Показать новости",
        "Close": "Закрыть",
    },
    currency: {
        "MustLoginToSaveFavorites": "Нужно авторизоваться, чтобы использовать избранное",
        "SearchPlaceholder": "Поиск валют",
        "TableColumnCoin": "Валюта",
        "TableColumnPrice": "Цена $",
        "TableColumnPriceBTC": "Цена BTC",
        "TableColumnChange24h": "Изм. (24ч)",
        "TableColumnMarketCap": "Капитализация",
        "TableColumnVolume24h": "Объем (24ч)",
        "ShowNews": "Показать новости",
        "Close": "Закрыть",
        "LabelMarketCap": "Капитализация",
        "LabelVolume24h": "Объем (24ч)",
        "LabelCirculatingSupply": "Циркулирующее предложение",
        "LabelTotalSupply": "Общее предложение",
        "LabelMaxSupply": "Максимальное предложение",
    },
    auth: {
        "TitleNewPassword": "Изменение пароля",
        "TitleLogin": "Авторизация",
        "TitleRestore": "Восстановление Пароля",
        "TitleCreateAccount": "Создание Аккаунта",
        "TitleVerify": "Проверка E-mail адреса",
        "TitleLoginWithSocialAccount": "Или используйте для входа аккант в социальной сети",
        "NoteSignup": "Зарегистрируйтесь используя электронную почту или одну из ваших учетных записей в социальной сети",
        "LinkLogin": "Войти",
        "LinkForgotPassword": "Забыли пароль?",
        "LinkCreateAccount": "Создать аккаунт",
        "LabelName": "Имя",
        "LabelEmail": "E-mail",
        "LabelPassword": "Пароль",
        "LabelPasswordConfirm": "Повторите пароль",
        "LabelNewPassword": "Новый пароль",
        "LabelNewPasswordConfirm": "Повторите пароль",
        "SubmitLogin": "Войти",
        "SubmitPasswordReset": "Сбросить пароль",
        "SubmitChangePassword": "Изменить пароль",
        "SubmitCreateAccount": "Создать",
        "MessageChangePasswordSuccess": "Новый пароль сохранен",
        "MessagePasswordResetSuccess": "Мы отправили вам электронное письмо с информацией для сброса пароля.",
        "MessageVerifySuccess": "Ваш e-mail подтвержден!",
    },
    errors: {
        "InvalidRequest": "Неверный запрос",
        "SystemError": "Не удалось выполнить запрос, попробуйте позже",
        "Required": "Поле обязательно для заполнения",
        "EmailInvalid": "Email имеет не верный формат",
        "EmailNotFound": "Email не найден",
        "EmailAlreadyExist": "Email уже используется в другом аккаунте",
        "PasswordLength": "Минимальная длинна пароля 5 символов",
        "PasswordConfirm": "Нужно ввести одинаковые пароли",
        "InvalidEmailOrPassword": "Не верный Email или Пароль",
        "EmailNotVerified": "Ваш аккаунт не подтвержден! Пожалуйста, проверьте вашу почту и подтвердите аккаунт.",
        "TokenInvalid": "Не валидный токен"
    },
    portfolio: {
        "MustLoginToUsePortfolio": "Нужно авторизоваться, чтобы использовать портфолио",
        "MyCryptoPortfolio": "Мое крипто портфолио",
        "Portfolio": "Портфолио",
        "Currency": "Валюта",
        "Amount": "Кол-во",
        "ValueUSD": "Стоимость USD",
        "PriceUSD": "Цена USD",
        "Change24hUSD": "Изм. 24ч USD",
        "ValueBTC": "Стоимость BTC",
        "PriceBTC": "Цена BTC",
        "Change24hBTC": "Изм. 24ч BTC",
        "Label": "Название",
        "NoRows": "В портфеле нет записей. Чтобы добавить валюты в портфель, нажмите «Добавить запись»",
        "AddNewRow": "Добавить запись",
        "SaveChanges": "Сохранить",
        "Cancel": "Отменить",
        "Chart": "График",
        "LinkShowChart": "Показать график",
        "LinkShowTable": "Показать таблицу",
        "DeleteConfirm": "Удалить запись?",
        "HistoryEmpty": "История пустая",
        "LinkClearHistory": "Очистить историю",
        "HistoryClearConfirm": "Вы хотите очистить историю?"
    },
    about: {
        "MainText": '<p>Основанный на временных линиях, сервис NWS.One будет предоставлять информацию, полученную от платформы One.Platform в простом и понятном виде. Используя Машинное Обучение, платформа будет способна самообучаться и анализировать потоки информации, что поможет в выявлении скрытых, неочевидных связей между полученными фактами и определять исторические предпосылки для их изменения. Также, основываясь на этом анализе, платформа будет способна предоставлять информацию о возможном развитии интересующих событий, либо будущем изменении данных. Эти алгоритмы, объединенные с современными технологиями, помогут прозрачно фильтровать полученную информацию от фальшивых данных. Таким образом, сервис NWS.One станет ежедневным помощником в чтении новостей, понимании их, обсуждении, а так же NWS.One предоставит доступ к широкому кругу экспертов и независимых наблюдателей для анализа полученных фактов.</p>',
    },
}