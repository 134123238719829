const locales = [
    {id: 1, name: 'en', fullName: 'English', shortName: 'Eng'},
    {id: 2, name: 'ru', fullName: 'Русский', shortName: 'Рус'},
    {id: 3, name: 'cn', fullName: '中文', shortName: '中文'},
];

export function findLocale(localeId) {
    return locales.find(item => item.id == localeId);
}

export function hasLocale(localeId) {
    return -1 < locales.findIndex(item => item.id == localeId);
}

export default locales;