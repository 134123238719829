import store from "./../store";
import axios from "axios";

// Add custom configuration here.
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL ? process.env.VUE_APP_API_BASE_URL : "https://live.nws.one",
});

// define Global axios defaults
axiosInstance.defaults.headers.get['Content-Type'] = 'application/json;charset=UTF-8';
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8';
axiosInstance.defaults.headers.delete['Content-Type'] = 'application/json;charset=UTF-8';

// 401 interceptor pushes to login page
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 401) {
            store.dispatch('auth/logOut');
        }
        return Promise.reject(error)
    }
);

export default axiosInstance;