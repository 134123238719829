import en from './en/index.js'
import ru from './ru/index.js'
import cn from './cn/index.js'

const messages = {
    en: en,
    ru: ru,
    cn: cn,
};

export default {
    locale: 'en',
    fallbackLocale: 'en',
    messages,
    silentTranslationWarn: true,
};