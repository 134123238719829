<template>
    <div class="input-text">
        <vue-recaptcha ref="elVueRecaptcha"
                       v-on:verify="onVerify"
                       v-bind:sitekey="siteKey"></vue-recaptcha>
        <vuelidate-errors
                v-if="formDirty"
                v-bind:field="vuelidateField"
                v-bind:server-errors="formErrors"
                v-bind:server-field="serverFieldName">
            <slot name="errors">
                <vuelidate-message rule="required">{{ $t('errors.Required') }}</vuelidate-message>
                <vuelidate-message rule="recaptchaInvalid">{{ $t('errors.RecaptchaInvalid') }}</vuelidate-message>
            </slot>
        </vuelidate-errors>
    </div>
</template>
<script>
    import FieldBaseMixin from './field-base'

    export default {
        mixins: [FieldBaseMixin],
        name: 'field-recaptcha',
        data() {
            return {
                siteKey: '6Le8GK0UAAAAAOibfYUUsU-FVCQ5lNCZUB4yqkFL'
            }
        },
        methods: {
            reset () {
                this.$refs.elVueRecaptcha.reset();
            },
            onVerify (response) {
                this.$emit('verify', response);
            },
        }
    }
</script>