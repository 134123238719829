import API from './../api'
import {mapState, mapActions} from 'vuex'

// requried loggedIn and NotfiyMixin

export default {
    computed: {
        ...mapState('tag', [
            'showFavorites',
            'tagTop',
            'tagFavorites'
        ]),
        favoritesMap () {
            let res = {};
            for (let i = 0; i < this.tagFavorites.length; i++) {
                res[this.tagFavorites[i].id] = true;
            }
            return res;
        }
    },
    methods: {
        ...mapActions('tag', [
            'toggleShowFavorites',
            'setTagFavorites',
        ]),
        isFavorite (tagId) {
            return this.favoritesMap[tagId] === true;
        },
        // показать/скрыть избранное
        toggleFavoritesMode () {
            if (this.loggedIn) {
                this.toggleShowFavorites();
            } else {
                this.notifyError(this.$t('tag.MustLoginToSaveFavorites'));
            }
        },
        // добавить/удалить валюту в избранном
        toggleTagFavorite (tagId) {
            if (this.loggedIn) {
                API.account.tagToggle(tagId).then((response) => {
                    if (response.data.favorites) {
                        this.setTagFavorites(response.data.favorites);
                    }
                })
            } else {
                this.notifyError(this.$t('tag.MustLoginToSaveFavorites'));
            }
        },
    }
}