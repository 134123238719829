<template>
    <div class="table-settings" v-bind:data-expand="visible">
        <svg v-on:click.prevent="toggle()"
             class="table-settings__icon"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             data-dropdown-opener="true">
            <use xlink:href="#icon-settings"></use>
        </svg>
        <div class="table-settings-list" ref="dropdownMenu">
            <div class="table-settings-list__item">
                <label class="input-checkbox input-checkbox_small">
                    <input type="checkbox" v-model="modelVisibleAmount">
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ $t('portfolio.Amount') }}</div>
                </label>
            </div>
            <div class="table-settings-list__item">
                <label class="input-checkbox input-checkbox_small">
                    <input type="checkbox" v-model="modelVisibleValueUsd">
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ $t('portfolio.ValueUSD') }}</div>
                </label>
            </div>
            <div class="table-settings-list__item">
                <label class="input-checkbox input-checkbox_small">
                    <input type="checkbox" v-model="modelVisiblePriceUsd">
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ $t('portfolio.PriceUSD') }}</div>
                </label>
            </div>
            <div class="table-settings-list__item">
                <label class="input-checkbox input-checkbox_small">
                    <input type="checkbox" v-model="modelVisibleChange24hUsd">
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ $t('portfolio.Change24hUSD') }}</div>
                </label>
            </div>
            <div class="table-settings-list__item">
                <label class="input-checkbox input-checkbox_small">
                    <input type="checkbox" v-model="modelVisibleValueBtc">
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ $t('portfolio.ValueBTC') }}</div>
                </label>
            </div>
            <div class="table-settings-list__item">
                <label class="input-checkbox input-checkbox_small">
                    <input type="checkbox" v-model="modelVisiblePriceBtc">
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ $t('portfolio.PriceBTC') }}</div>
                </label>
            </div>
            <div class="table-settings-list__item">
                <label class="input-checkbox input-checkbox_small">
                    <input type="checkbox" v-model="modelVisibleChange24hBtc">
                    <div class="input-checkbox__check"></div>
                    <div class="input-checkbox__text">{{ $t('portfolio.Change24hBTC') }}</div>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState, mapActions} from 'vuex'
    import DropDownMixin from "./../../mixins/dropdown"

    export default {
        name: 'portfolio-table-settings',
        mixins: [DropDownMixin],
        computed: {
            ...mapState('portfolioTable', [
                'tableVisibleAmount',
                'tableVisibleValueUsd',
                'tableVisiblePriceUsd',
                'tableVisibleChange24hUsd',
                'tableVisibleValueBtc',
                'tableVisiblePriceBtc',
                'tableVisibleChange24hBtc',
            ]),
            modelVisibleAmount: {
                get () {
                    return this.tableVisibleAmount;
                },
                set (newValue) {
                    this.setTableVisibleAmount(newValue)
                }
            },
            modelVisibleValueUsd: {
                get () {
                    return this.tableVisibleValueUsd;
                },
                set (newValue) {
                    this.setTableVisibleValueUsd(newValue);
                }
            },
            modelVisiblePriceUsd: {
                get () {
                    return this.tableVisiblePriceUsd;
                },
                set (newValue) {
                    this.setTableVisiblePriceUsd(newValue);
                }
            },
            modelVisibleChange24hUsd: {
                get () {
                    return this.tableVisibleChange24hUsd;
                },
                set (newValue) {
                    this.setTableVisibleChange24hUsd(newValue);
                }
            },
            modelVisibleValueBtc: {
                get () {
                    return this.tableVisibleValueBtc;
                },
                set (newValue) {
                    this.setTableVisibleValueBtc(newValue);
                }
            },
            modelVisiblePriceBtc: {
                get () {
                    return this.tableVisiblePriceBtc;
                },
                set (newValue) {
                    this.setTableVisiblePriceBtc(newValue);
                }
            },
            modelVisibleChange24hBtc: {
                get () {
                    return this.tableVisibleChange24hBtc;
                },
                set (newValue) {
                    this.setTableVisibleChange24hBtc(newValue);
                }
            }
        },
        methods: {
            ...mapActions('portfolioTable', [
                'setTableVisibleAmount',
                'setTableVisibleValueUsd',
                'setTableVisiblePriceUsd',
                'setTableVisibleChange24hUsd',
                'setTableVisibleValueBtc',
                'setTableVisiblePriceBtc',
                'setTableVisibleChange24hBtc',
            ]),
        }
    }
</script>