<template>
    <div class="currency-detail-indicator__cell">
        <div class="currency-detail-indicator__label"><slot></slot></div>
        <div class="currency-detail-indicator__value">
            <div class="currency-detail-indicator__item">{{ indicatorValue | toCurrencySmart }} {{ indicatorSymbol }}</div>
            <div v-if="hasSecond" class="currency-detail-indicator__item">{{ indicatorSecondValue | toCurrencySmart }} {{ indicatorSecondSymbol }}</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'currency-detail-indicator',
        props: {
            indicatorSymbol: {
                type: String,
                required: true
            },
            indicatorValue: {
                required: true,
                default: 0
            },
            indicatorSecondSymbol: {
                type: String,
                default: ""
            },
            indicatorSecondValue: {
                default: 0
            }
        },
        computed: {
            hasSecond () {
                return this.indicatorSecondSymbol.length > 0;
            }
        }
    }
</script>
<style>
    .currency-detail-indicator__cell {
        margin: 20px 20px 0 0;
    }
    .currency-detail-indicator__label {
        font-size: 16px;
        margin-bottom: 3px;
    }
    .currency-detail-indicator__value {
        font-size: 12px;
        line-height: 19px;
    }
</style>