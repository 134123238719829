<template>
    <div class="currency-detail__wrap">
        <div class="currency-detail">
            <div class="currency-detail__row">
                <div class="currency-detail__col">
                    <div class="currency-detail-title__wrap">
                        <div class="currency-detail-title">{{ item.name }}</div>
                        <div v-if="visibleCloseButton"
                             v-on:click.prevent="closeTag"
                             class="currency-detail-title__close"></div>
                        <a v-if="hasShowNewsButton"
                           v-on:click.prevent="showNews"
                           class="button"
                           href="#news">{{ $t('tag.ShowNews') }} {{ item.name }}</a>
                    </div>
                </div>
            </div>
        </div>

        <slot name="news"></slot>
    </div>
</template>
<script>
    export default {
        name: 'tag-detail',
        props: {
            item: {
                type: Object,
                require: true
            },
            mobileMode: {
                type: Boolean,
                required: true
            },
            hasShowNewsButton: {
                type: Boolean,
                default: false
            },
            hasCloseButton: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            visibleCloseButton() {
                return this.hasCloseButton && this.mobileMode;
            }
        },
        methods: {
            showNews() {
                this.$emit('show-news')
            },
            closeTag () {
                this.$emit('close-tag');
            },
        }
    }
</script>