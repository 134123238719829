export default [
    {
        type: "facebook",
        title: "Facebook",
        link: "/oauth/facebook/handle"
    },
    {
        type: "vk",
        title: "VKontakte",
        link: "/oauth/vk/handle"
    },
    {
        type: "twitter",
        title: "Twitter",
        link: "/oauth/twitter/handle"
    },
    {
        type: "gplus",
        title: "Google",
        link: "/oauth/gplus/handle"
    },
]