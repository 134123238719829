import ViewCountry from "./../../views/country.vue";

export default [
    {
        path: '/country/:country',
        name: 'country',
        component: ViewCountry,
        children: [
            {
                path: 'post/:slug',
                name: 'country_content_item',
                component: ViewCountry,
            },
        ]
    },
    {
        path: '/api/country/search',
        name: 'api_country_search',
    },
    {
        path: '/api/country/:slug',
        name: 'api_country_data',
    },
];