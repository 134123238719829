<template>
    <div class="coins__search">
        <div class="input-text">
            <input type="text" v-model="tagTableQueryModel"/>

            <div v-if="tagTableQuery.length > 0"
                 v-on:click="tagTableResetQuery"
                 class="input-text__clear"></div>

            <svg v-else xmlns:xlink="http://www.w3.org/1999/xlink">
                <use xlink:href="#icon-lens"></use>
            </svg>
        </div>
    </div>
</template>
<script>
    import TagFavoritesMixin from './../../mixins/tag-favorites'
    import TagTableStoreMixin from './../../mixins/tag-table-store'

    export default {
        name: "tag-table-filter",
        mixins: [TagTableStoreMixin, TagFavoritesMixin],
    }
</script>