import API from './../api'
import {mapState, mapActions} from 'vuex'

// requried loggedIn and NotfiyMixin

export default {
    computed: {
        ...mapState('currency', [
            'showFavorites',
            'currencyTop',
            'currencyFavorites'
        ]),
        favoritesMap () {
            let res = {};
            for (let i = 0; i < this.currencyFavorites.length; i++) {
                res[this.currencyFavorites[i].id] = true;
            }
            return res;
        }
    },
    methods: {
        ...mapActions('currency', [
            'toggleShowFavorites',
            'setCurrencyFavorites',
        ]),
        isFavorite (currencyId) {
            return this.favoritesMap[currencyId] === true;
        },
        // показать/скрыть избранное
        toggleFavoritesMode () {
            if (this.loggedIn) {
                this.toggleShowFavorites();
            } else {
                this.notifyError(this.$t('currency.MustLoginToSaveFavorites'));
            }
        },
        // добавить/удалить валюту в избранном
        toggleCurrencyFavorite (currencyId) {
            if (this.loggedIn) {
                API.account.currencyToggle(currencyId).then((response) => {
                    if (response.data.favorites) {
                        this.setCurrencyFavorites(response.data.favorites);
                    }
                })
            } else {
                this.notifyError(this.$t('currency.MustLoginToSaveFavorites'));
            }
        },
    }
}