<template>
    <div class="header-line header-line_mobile">
        <div class="header-line__col">
            <svg
                    v-on:click="toggleDetailFilter"
                    class="header-line__tools"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                <use xlink:href="#icon-tools"></use>
            </svg>

            <input-switch
                    v-model="userFeed"
                    v-on:input-disabled="userFeedNotAvailable"
                    v-bind:disabled="!this.loggedIn"
            >{{ $t('filter.MyFeed') }}</input-switch>
        </div>
    </div>
</template>
<script>
    import {mapState, mapGetters, mapActions} from 'vuex'
    import InputSwitch from './../ui/input-switch.vue'
    import NotifyMixin from './../../mixins/notify'

    export default {
        name: 'header-line',
        mixins: [NotifyMixin],
        components: {
            InputSwitch
        },
        computed: {
            ...mapState('auth', [
                'loggedIn',
            ]),
            ...mapState('filter', {
                filterSourceListSelected: state => state.sourceListSelected,
                filterCurrencyListSelected: state => state.currencyListSelected,
                filterSourceTypeSelected: state => state.sourceTypeSelected,
                filterVoteTypeSelected: state => state.voteTypeSelected,
                filterLangSelected: state => state.langSelected,
                filterShowUserFeed: state => state.showUserFeed,
            }),
            ...mapState('filterFeed', {
                filterFeedInitialSignature: state => state.initialSignature,
                filterFeedSourceListSelected: state => state.sourceListSelected,
                filterFeedCurrencyListSelected: state => state.currencyListSelected,
                filterFeedSourceTypeSelected: state => state.sourceTypeSelected,
                filterFeedVoteTypeSelected: state => state.voteTypeSelected,
                filterFeedLangSelected: state => state.langSelected,
            }),
            ...mapGetters('filter', {
                filterSignature : 'signature'
            }),
            ...mapGetters('filterFeed', {
                filterFeedSignature : 'signature'
            }),
            userFeed: {
                get () {
                    return this.filterShowUserFeed
                },
                set (val) {
                    this.filterSetShowUserFeed(val);
                }
            },
            hasFilterChanges () {
                return this.userFeed
                    ? this.filterFeedSignature != this.filterFeedInitialSignature
                    : this.filterSignature.length > 0 && this.filterSignature != this.filterFeedInitialSignature;
            }
        },
        methods: {
            ...mapActions('filter', {
                'filterSetShowUserFeed': 'setShowUserFeed',
            }),
            userFeedNotAvailable () {
                if (this.loggedIn == false) {
                    this.notifyError(this.$t('filter.MustLoginToUserMyFeed'));
                }
            },
            toggleDetailFilter() {
                this.$emit('toggle-detail-filter');
            },
        }
    }
</script>