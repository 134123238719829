<template>
    <label class="input-toggle" v-on:click="onTouch">
        <input type="checkbox"
               v-model="valueModel"
               v-bind:disabled="disabled">
        <div class="input-toggle__switch"></div>
        <div class="input-toggle__text"><slot></slot></div>
    </label>
</template>
<script>
    export default {
        name: "input-switch",
        props: {
            value: {
                type: Boolean,
                required: true
            },
            disabled: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            valueModel: {
                get () {
                    return this.value
                },
                set (val) {
                    this.$emit('input', val);
                }
            }
        },
        methods: {
            onTouch () {
                if (this.disabled) {
                    this.$emit('input-disabled');
                }
            }
        }
    }
</script>