import home from "./home";
import content from "./content";
import about from "./about";
import sale from "./sale";
import tag from "./tag";
import tags from "./tags";
import currency from "./currency";
import currencies from "./currencies";
import country from "./country";
import auth from "./auth";
import account from "./account";
import bot from "./bot";
import source from "./source";
import filter from "./filter";

let modules = [
    home,
    content,
    about,
    sale,
    tag,
    tags,
    currency,
    currencies,
    country,
    auth,
    account,
    bot,
    source,
    filter,
];

let routes = [
    {
        path: '/api/common',
        name: 'api_common_data',
    }
];

for (let i = 0; i < modules.length; i++) {
    for (let j = 0; j < modules[i].length; j++) {
        routes.push(modules[i][j]);
    }
}

export default routes;