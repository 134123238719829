<template>
    <router-view></router-view>
</template>
<script>
    import API from "./api"
    import {mapState, mapGetters, mapActions} from "vuex";
    import {setNumbroLocale} from "./utils/numbro"

    export default {
        name: "app",
        computed: {
            ...mapGetters("locale", ["currentLocale"]),
            ...mapState("auth", ["loggedIn"]),
        },
        created () {
            this.syncLocale();
            API.common.loadData().then((response) => {
                this.setCommonData(response.data);
            });
        },
        watch: {
            currentLocale () {
                this.syncLocale();
            },
            loggedIn (value) {
                if (value) {
                    API.common.loadData().then((response) => {
                        this.setCommonData(response.data);
                    });
                }
            }
        },
        methods: {
            ...mapActions(["setCommonData"]),
            syncLocale() {
                if (this.currentLocale) {
                    this.$i18n.locale = this.currentLocale.name;
                    setNumbroLocale(this.currentLocale.name);
                }
            },
        }
    }
</script>