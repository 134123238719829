<template>
    <filter-search-component
            v-on:search="search"
            v-on:dismiss-all="dismissAll"
            v-bind:user-feed="userFeed"
            v-bind:label="label"
            v-bind:labelSelectedAll="labelSelectedAll"
            v-bind:labelSelected="labelSelected"
            v-bind:labelTop="labelTop"
            v-bind:placeholderSearch="placeholderSearch"
            v-bind:messageSelectedEmpty="messageSelectedEmpty"
            v-bind:messageSearchEmpty="messageSearchEmpty"
            v-bind:items-selected="itemsSelected"
            v-bind:items-search="itemsSearch"
            v-bind:with-top="false"
            v-model="selected">
    </filter-search-component>
</template>
<script>
    import API from './../../api'
    import FilterSearchComponent from './filter-search-component.vue'
    import uniqBy from 'lodash/uniqBy'

    export default {
        name: 'filter-search-source',
        components: {
            FilterSearchComponent
        },
        props: {
            userFeed: {
                type: Boolean,
                required: true
            },
            sourceListSelected: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                sourceListSearch: [],
            }
        },
        computed: {
            /**
             * Список Id выбранных валют, интерфейс синхронизации в store
             */
            selected: {
                get() {
                    return this.sourceListSelected.map(item => item.id);
                },
                set(val) {

                    // основной список отмеченных валют
                    let items = this.sourceListSelected.filter((item) => {
                        return val.indexOf(item.id) != -1
                    });

                    // добавляем все что выбрано в поиске
                    this.sourceListSearch.forEach((item) => {
                        if (val.indexOf(item.id) != -1) {
                            items.push(item);
                        }
                    });

                    // удаляем дубли
                    items = uniqBy(items, 'id');

                    // сохраняем список выбранныхх валют
                    this.setSource(items);
                }
            },
            /**
             * Список выбранных валют
             */
            itemsSelected () {
                return this.sourceListSelected.map((item) => {
                    return {
                        value: item.id,
                        name: this.formatItemText(item),
                    }
                });
            },
            /**
             * Список найденных валют
             */
            itemsSearch () {
                return this.sourceListSearch.map((item) => {
                    return {
                        value: item.id,
                        name: this.formatItemText(item),
                    }
                });
            },
            label () {
                return this.$t('global.Filter');
            },
            labelSelected () {
                return this.$t('global.Sources');
            },
            labelSelectedAll () {
                return this.$t('global.SourcesAll');
            },
            labelTop () {
                return this.$t('global.ShowTop') + ' 20';
            },
            messageSelectedEmpty () {
                return this.$t('global.SelectedEmpty');
            },
            messageSearchEmpty () {
                return this.$t('global.SearchEmpty');
            },
            placeholderSearch () {
                return this.$t('global.SearchSourcePlaceholder');
            }
        },
        methods: {
            setSource (items) {
                this.$emit('select', items);
            },
            clearSource () {
                this.$emit('clear');
            },
            formatItemText (item) {
                return item.name;
            },
            dismissAll () {
                this.clearSource();
            },
            search (q) {
                API.source.search({query: q}).then(response => {
                    if (response.data.items) {
                        this.sourceListSearch = response.data.items;
                    } else {
                        this.sourceListSearch = [];
                    }
                })
            }
        }
    }
</script>