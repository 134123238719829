export default {
    data () {
        return {
            visible: false
        }
    },
    methods: {
        documentClick(e) {
            if (this.visible == false) {
                return;
            }
            let el = this.$refs.dropdownMenu;
            let target = e.target;
            if ((el !== target) && !el.contains(target) && !target.hasAttribute("data-dropdown-opener")) {
                this.visible = false;
            }
        },
        hide() {
            this.visible = false;
        },
        show() {
            this.visible = true;
        },
        toggle() {
            return this.visible ? this.hide() : this.show();
        },
    },
    created () {
        document.addEventListener('click', this.documentClick);
    },
    destroyed () {
        document.removeEventListener('click', this.documentClick);
    }
}