import Layout from "./../components/layout.vue"
import WindowResizeMixin from "./window-resize";
import WindowScrollMixin from "./window-scroll";
import {mapActions} from "vuex";

export default {
    mixins: [WindowResizeMixin, WindowScrollMixin],
    components: {
        Layout
    },
    created () {
        this.pageCreated();
    },
    beforeRouteUpdate (to, from, next) {
        this.routeFrom = from;
        next();
    },
    data () {
        return {
            routeFrom: null,
            bootstrap: true
        }
    },
    computed: {
        mobileMode () {
            return this.windowWidth <= 640;
        },
    },
    methods: {
        ...mapActions('auth', [
            'logIn',
            'logOut'
        ]),
        ...mapActions('currency', [
            'setCurrencyTop',
            'setCurrencyFavorites'
        ]),
        pageCreated() {
            this.listenWindowScroll();
            this.listenWindowResize();
            this.pageReady();
        },
        pageReady() {
            // default nothing
        },
        pageBootstrap() {
            this.bootstrap = true;
        },
        pageBootstrapped() {
            this.bootstrap = false;
        }
    }
}