export default function () {
    this.filter = {
        save: ({sources, tags, countries, sourceTypes, voteTypes, langs}) => {
            return this.post({
                to: {name: 'api_filter_save'},
                payload: {
                    sources: sources,
                    tags: tags,
                    countries: countries,
                    source_types: sourceTypes,
                    vote_types: voteTypes,
                    langs: langs,
                }
            });
        },
    }
}