import { render, staticRenderFns } from "./social-login-block.vue?vue&type=template&id=e79feab0&"
import script from "./social-login-block.vue?vue&type=script&lang=js&"
export * from "./social-login-block.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "social-login-block.vue"
export default component.exports