import uniqueId from 'lodash/uniqueId';
import VuelidateErrors from './vuelidate-errors.vue';
import VuelidateMessage from './vuelidate-message.vue';

let uuid = 0;

export default {
    components: {
        VuelidateErrors,
        VuelidateMessage,
    },
    data () {
        return {
            uuid: 0
        }
    },
    created() {
        this.uuid = uuid.toString();
        uuid += 1;
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
        },
        serverFieldName: {
            type: String,
            default: 'undefined'
        },
        vuelidateField: {
            type: Object,
            required: true
        },
        formDirty: {
            type: Boolean,
            default: false
        },
        formErrors: {
            type: Array,
            default () {
                return []
            }
        },
        fieldId: {
            type: String,
            default () {
                return uniqueId('field_');
            }
        },
    },
    computed: {
        fieldName () {
            return 'input' + this.uuid;
        },
        fieldValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        clear() {
            this.fieldValue = '';
        }
    }
}