<template>
    <div class="button-save">
        <a class="button"
           href="#filter-save"
           v-on:click.prevent="save">{{ $t('filter.SaveToMyFeed') }}</a>
        <a class="button-save__clear"
           href="#filter-cancel"
           v-on:click.prevent="cancel"></a>
    </div>
</template>
<script>
    export default {
        name: 'filter-button-save',
        methods: {
            save () {
                this.$emit('save')
            },
            cancel () {
                this.$emit('cancel')
            }
        }
    }
</script>