<template>
    <header v-bind:class="['header', {'header_hide':scrolledDown}]">
        <div class="header__logo">
            <router-link v-bind:to="{name: 'home'}" exact></router-link>
        </div>

        <nav-view></nav-view>

        <locale-view></locale-view>

        <div class="header-social">
            <a class="header-social__item" target="_blank" href="https://twitter.com/nwsoneplatform">
                <svg xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="20">
                    <use xlink:href="#icon-tw"></use>
                </svg>
            </a>
            <a class="header-social__item" target="_blank" href="https://t.me/joinchat/A3rr_BF0Pa8JL2WSWW92pA30m3">
                <svg xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="22">
                    <use xlink:href="#icon-tg"></use>
                </svg>
            </a>
        </div>
    </header>
</template>
<script>
    import NavView from './header/nav.vue'
    import LocaleView from './header/locale.vue'

    export default {
        name: 'header-view',
        props: {
            scrolledDown: {
                type: Boolean,
                required: true
            }
        },
        components: {
            NavView,
            LocaleView,
        }
    }
</script>