<script>
    import OAuthFB from "./oauth-facebook.vue"
    import OAuthTW from "./oauth-twitter.vue"
    import OAuthGPlus from "./oauth-gplus.vue"
    import OAuthTG from "./oauth-telegram.vue"
    import OAuthVK from "./oauth-vk.vue"

    export default {
        name: "icon-oauth",
        components: {
            'facebook': OAuthFB,
            'twitter': OAuthTW,
            'gplus': OAuthGPlus,
            'telegram': OAuthTG,
            'vk': OAuthVK,
        },
        props: {
            icon: {
                require: true
            },
            link: {
                type: String,
                require: true
            }
        }
    }
</script>
<template>
    <component v-bind:is="icon" v-bind:link="link"></component>
</template>