<template>
    <filter-select-component
            name="country"
            v-bind:label="label"
            v-bind:items="itemsOptions"
            v-model="selected"
            v-on:dismiss-all="clearCountry"
    ></filter-select-component>
</template>
<script>
    import FilterSelectComponent from './filter-select-component.vue'
    
    export default {
        name: 'filter-select-country',
        components: {
            FilterSelectComponent,
        },
        props: {
            countrySelected: {
                type: Array,
                required: true
            },
            items: {
                type: Array,
                required: true
            }
        },
        computed: {
            selected: {
                get() {
                    return this.countrySelected;
                },
                set(val) {
                    this.setCountry(val);
                }
            },
            label () {
                return this.$t('global.Countries');
            },
            itemsOptions () {
                let options = [];
                this.items.forEach((item) => {
                    options.push({name: item.title, value: item.id, icon: '/media/img/country/' + item.slug + '.png'});
                });
                return options;
            }
        },
        methods: {
            setCountry (val) {
                this.$emit('select', val);
            },
            clearCountry () {
                this.$emit('clear');
            }
        }
    }
</script>