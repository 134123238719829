<template>
    <layout v-bind:mobile-mode="mobileMode">
        <template slot="header-line">
            <header-line></header-line>
        </template>

        <custom-scroll class="content__col content__col_wide"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <portfolio-chart v-if="visibleChart" v-on:show-table="toggleVisibleChart"></portfolio-chart>
            <portfolio-widget v-if="!visibleChart"
                v-on:show-chart="toggleVisibleChart"
                v-bind:mobile-mode="mobileMode"
                ></portfolio-widget>
        </custom-scroll>
    </layout>
</template>
<script>
    import {mapActions} from 'vuex'
    import API from './../api'
    import HeaderLine from "./../components/account/header-line.vue"
    import PageMixin from "./../mixins/page"
    import PortfolioWidget from "./../components/portfolio/portfolio-widget.vue"
    import PortfolioChart from "./../components/portfolio/portfolio-chart.vue"

    export default {
        mixins: [PageMixin],
        components: {
            HeaderLine,
            PortfolioWidget,
            PortfolioChart
        },
        data () {
            return {
                visibleChart: false,
            }
        },
        methods: {
            ...mapActions('portfolioData', [
                'setTableItems',
                'setHistoryItems',
            ]),
            pageReady() {
                this.loadPortfolioData();
            },
            loadPortfolioData() {
                API.portfolio.data().then((response) => {
                    if (response.data.portfolioTable) {
                        this.setTableItems(response.data.portfolioTable);
                    }
                    if (response.data.portfolioHistory) {
                        this.setHistoryItems(response.data.portfolioHistory);
                    }
                });
            },
            toggleVisibleChart() {
                this.visibleChart = !this.visibleChart;
            }
        }
    }
</script>