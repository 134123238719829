<script>
    import NavNews from "./nav-news.vue"
    import NavCoins from "./nav-coins.vue"
    import NavAbout from "./nav-about.vue"
    import NavLogin from "./nav-login.vue"

    export default {
        name: "icon-nav",
        components: {
            NavNews,
            NavCoins,
            NavAbout,
            NavLogin,
        },
        props: {
            type: {
                require: true
            }
        }
    }
</script>
<template>
    <component :is="type"></component>
</template>