export default {
    data() {
        return {
            prevScrollTop: 0,
            scrolledDown: false,
            scrollDelta: 5,
            scrollOffset: 150
        }
    },
    methods: {
        listenWindowScroll() {
            window.addEventListener('scroll', this.onScroll);
        },
        unlistenWindowScroll() {
            window.removeEventListener('scroll', this.onScroll);
        },
        scrollTop() {
            return document.body.scrollTop || document.documentElement.scrollTop;
        },
        onScroll() {
            let currentScrollTop = this.scrollTop();
            if (this.prevScrollTop - currentScrollTop > this.scrollDelta) {
                this.scrolledDown = false;
            } else if (currentScrollTop - this.prevScrollTop > this.scrollDelta
                && currentScrollTop > this.scrollOffset) {
                this.scrolledDown = true;
            }
            this.prevScrollTop = currentScrollTop;
        },
    }
}