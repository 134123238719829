import { render, staticRenderFns } from "./form-change-password.vue?vue&type=template&id=0f143089&"
import script from "./form-change-password.vue?vue&type=script&lang=js&"
export * from "./form-change-password.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "form-change-password.vue"
export default component.exports