export default {
    ranges: {
        "day": "日",
        "month": "月",
        "year": "年",
        "all": "所有",
    },
    weekdays: {
        "Sunday": "星期日",
        "Monday": "星期一",
        "Tuesday": "星期二",
        "Wednesday": "星期三",
        "Thursday": "星期四",
        "Friday": "星期五",
        "Saturday": "星期六"
    },
    shortWeekdays: {
        "Sunday": "周日",
        "Monday": "周一",
        "Tuesday": "周二",
        "Wednesday": "周三",
        "Thursday": "周四",
        "Friday": "周五",
        "Saturday": "周六"
    },
    months: {
        "January": "一月",
        "February": "二月",
        "March": "三月",
        "April": "四月",
        "May": "五月",
        "June": "六月",
        "July": "七月",
        "August": "八月",
        "September": "九月",
        "October": "十月",
        "November": "十一月",
        "December": "十二月"
    },
    shortMonths: {
        "Jan": "一月",
        "Feb": "二月",
        "Mar": "三月",
        "Apr": "四月",
        "May": "五月",
        "Jun": "六月",
        "Jul": "七月",
        "Aug": "八月",
        "Sep": "九月",
        "Oct": "十月",
        "Nov": "十一月",
        "Dec": "十二月"
    },
    menu: {
        "News": "新闻",
        "Coins": "货币",
        "Trends": "Trends",
        "About": "关于",
        "Profile": "文件",
        "Login": "登陆",
        "Signup": "注册",
    },
    global: {
        "Share": "股票",
        "Currency": "货币",
        "Tag": "Tag",
        "Trends": "Trends",
        "TrendsAll": "All trends",
        "Filter": "筛选",
        "NewsFilter": "新闻筛选",
        "NewsLanguage": "新闻语言",
        "NewsVoteType": "新闻类型",
        "NewsSourceType": "数据类型",
        "Selected": "已选",
        "SelectedEmpty": "无选择记录",
        "SearchEmpty": "无结果",
        "Currencies": "货币",
        "CurrenciesAll": "所有的货币",
        "Sources": "数据",
        "SourcesAll": "所有数据",
        "Search": "查找",
        "Clear": "清除",
        "DismissAll": "取消所有",
        "Back": "后退",
        "Ok": "确定",
        "Types": "类型",
        "All": "所有",
        "News": "新闻",
        "Social": "社会",
        "Twitter": "Twitter",
        "Reddit": "Reddit",
        "Facebook": "Facebook",
        "Instagram": "Instagram",
        "Langs": "语言",
        "English": "英语",
        "Russian": "俄语",
        "Chinese": "中文",
        "Top": "首页",
        "ShowTop": "显示首页",
        "SearchTagPlaceholder": "使用查找功能搜索更多",
        "SearchCurrencyPlaceholder": "使用查找功能搜索更多",
        "SearchSourcePlaceholder": "使用查找功能搜索更多",
        "PriceUsd": "美金价格",
        "PriceBtc": "比特币价格",
        "VolumeUsd24h": "24小时美金数据",
        "LoadingData": "加载数据 ...",
        "DataSaved": "保存数据",
        "MessageChangeSaved": "更改设置保存",
        "Save": "保存",
        "Logout": "推出",
        "RequestFailed": "要求失败",
    },
    loadMore: {
        "NoResults": "按照筛选要求没有找到内容",
        "NoMore": "您已经到达搜索的尾页!",
    },
    filter: {
        "MyFeed": "我的页面",
        "MustLoginToUserMyFeed": "您必须登陆后使用我的页面",
        "Bullish": "垃圾",
        "Bearish": "还可以",
        "Positive": "positive",
        "Negative": "Negative",
        "Important": "很重要",
        "Trend": "趨勢",
        "SaveToMyFeed": "更改设置保存",
        "Saved": "保存",
    },
    account: {
        "MenuSettings": "设置",
        "MenuMyFeed": "我的页面",
        "MenuPortfolio": "文件",
        "MenuConnectedAccounts": "连接账号",
        "TitleAccountSettings": "账号设置",
        "TitleAccountConnections": "账号连接",
        "TitleAddNewAccounts": "添加新账号",
        "TitleTelegramBotSettings": "Telegram bot Settings for automatically publish news from your feed into your telegram channel.",
        "TitleTelegramBotManual": "Manual",
        "NoteYouCanUseSocialAccountForLogin": "您可以使用下面的第三方账号登陆后账号",
        "LabelEmailIsNotConfirmed": "邮件没有确认!",
        "ButtonResendConfirmation": "重新发送确认",
        "MessageResendConfirmationSuccess": "我们已给您邮件发送确认邮件",
        "MessageUserAccountRemoved": "用户账号注销",
        "MessageUserAccountRemoveFailed": "注销账号失败",
        "Remove": "删除",
        "BotEnabled": "Enabled",
        "BotManualStep1": '1. Invite bot <a href="https://t.me/IntwtComBot" target="_blank">@IntwtComBot</a> as admin with "Post Messages" rights into your channel.',
        "BotManualStep2": '2. Use <code>/intwt id</code> command in your channel to find out exact ID to use for <code>Telegram ID</code> field. Chat ID format will be something like: -100500.',
    },
    content: {
        "Source": "数据",
        "Tags": "Tags",
        "WTF": "您认为这个消息如何?",
        "Bullish": "垃圾",
        "Bearish": "还可以",
        "Important": "很重要",
        "Trend": "趨勢",
        "MustLoginToVote": "您必须先登陆后投票",
    },
    tag: {
        "MustLoginToSaveFavorites": "您必须登陆后保存最爱",
        "SearchPlaceholder": "Search currency",
        "TableColumnName": "Name",
        "ShowNews": "显示新闻",
        "Close": "关闭",
    },
    currency: {
        "MustLoginToSaveFavorites": "您必须登陆后保存最爱",
        "SearchPlaceholder": "查询汇率",
        "TableColumnCoin": "货币",
        "TableColumnPrice": "美金价格",
        "TableColumnPriceBTC": "比特币价格",
        "TableColumnChange24h": "24小时更新",
        "TableColumnMarketCap": "市场资本",
        "TableColumnVolume24h": "24小时量",
        "ShowNews": "显示新闻",
        "Close": "关闭",
        "LabelMarketCap": "市场资本",
        "LabelVolume24h": "24小时量",
        "LabelCirculatingSupply": "供应量",
        "LabelTotalSupply": "所有的供应量",
        "LabelMaxSupply": "最大供应量",
    },
    auth: {
        "TitleNewPassword": "更改密码",
        "TitleLogin": "登陆",
        "TitleRestore": "重设密码",
        "TitleCreateAccount": "创建账号",
        "TitleVerify": "邮件确认",
        "TitleLoginWithSocialAccount": "或者使用其他网络账号登陆",
        "NoteSignup": "注册使用邮件或者其他网络账号",
        "LinkLogin": "登陆",
        "LinkCreateAccount": "创建账号",
        "LinkForgotPassword": "忘记密码?",
        "LabelName": "姓名",
        "LabelEmail": "邮箱",
        "LabelPassword": "密码",
        "LabelPasswordConfirm": "密码确认",
        "LabelNewPassword": "新密码",
        "LabelNewPasswordConfirm": "再次确认新密码",
        "SubmitLogin": "登陆",
        "SubmitPasswordReset": "重设密码",
        "SubmitChangePassword": "更改密码",
        "SubmitCreateAccount": "登陆",
        "MessageChangePasswordSuccess": "保存新密码",
        "MessagePasswordResetSuccess": "我们已经给您邮箱发送重设密码信息",
        "MessageVerifySuccess": "认证您的邮箱!",
    },
    errors: {
        "InvalidRequest": "无效的请求",
        "SystemError": "请求失败，请稍候重试",
        "Required": "此栏目必填",
        "EmailInvalid": "邮箱有效",
        "EmailNotFound": "未找到邮件",
        "EmailAlreadyExist": "邮箱已经存在",
        "PasswordLength": "最短的密码长度为5个字符",
        "PasswordConfirm": "您必须每次输入相同的密码",
        "InvalidEmailOrPassword": "无效的邮箱或密码",
        "EmailNotVerified": "您的账号未确认！请查看您的邮件和认真账号.",
        "TokenInvalid": "Token 无效",
    },
    portfolio: {
        "MustLoginToUsePortfolio": "您必须登陆使用文件",
        "MyCryptoPortfolio": "我的货币文件",
        "Portfolio": "文件",
        "Currency": "货币",
        "Amount": "金额",
        "ValueUSD": "美金价值",
        "PriceUSD": "美金价格",
        "Change24hUSD": "24小时美金变化",
        "ValueBTC": "比特币价值",
        "PriceBTC": "比特币价格",
        "Change24hBTC": "24小时比特币变化",
        "Label": "标签",
        "NoRows": "您的文件里面没有货币. 添加新的货币入文件，请点击«添加新列»",
        "AddNewRow": "添加新列",
        "SaveChanges": "更改设置保存",
        "Cancel": "取消",
        "Chart": "图表",
        "LinkShowChart": "显示图表",
        "LinkShowTable": "显示表格",
        "DeleteConfirm": "清空?",
        "HistoryEmpty": "History is empty",
        "LinkClearHistory": "Clear history",
        "HistoryClearConfirm": "Clear history confirm?"
    },
    about: {
        "MainText": '<p>Presented in the form of timelines, NWS.One service will show information received from One.Platform in an easy and understandable form.</p>\
<p>Using Machine Learning, the platform will be capable of self-learning and analyzing information flows, which will help to show hidden connections between obtained facts and historical background for their change. Also, based on that analysis, the platform will be able to create predictions for interesting events and data.</p>\
<p>Those algorithms, joined with modern technologies, will allow to transparently filter obtained information from false facts, thus becoming a base to create an everyday tool for reading news, understand them, discuss and gain access for expert analysis and independent observers opinion.</p>',
    },
}