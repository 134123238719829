export default [
    {
        path: '/api/content/list',
        name: 'api_content_list',
    },
    {
        path: '/api/content/list/common',
        name: 'api_content_list_common',
    },
    {
        path: '/api/content/:slug',
        name: 'api_content_data',
    },
    {
        path: '/api/content/vote',
        name: 'api_content_vote',
    },
];