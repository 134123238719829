export default function () {
    this.tag = {
        item: ({slug}) => {
            return this.post({
                to: {name: 'api_tag_data', params: {slug: slug}},
                payload: {}
            });
        },
        list: (payload) => {
            return this.post({
                to: {name: 'api_tag_list'},
                payload: payload,
            });
        },
        listCommon: (payload) => {
            return this.post({
                to: {name: 'api_tag_list_common'},
                payload: payload,
            });
        },
        search: ({query}) => {
            return this.post({
                to: {name: 'api_tag_search'},
                payload: {query}
            });
        },
    }
}