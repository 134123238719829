<template>
    <layout v-bind:tag="tag"
            v-bind:mobile-mode="mobileMode"
            v-bind:scrolled-down="scrolledDown">
        <template slot="header-line">
            <header-line
                    v-if="!!tag"
                    v-bind:tag="tag"
                    v-on:close-tag="closeTag"
            ></header-line>
        </template>

        <custom-scroll
                v-if="visibleContentList"
                v-bind:class="{'content__col': true, 'content__col_left': visibleContentItem, 'content__col_wide': !visibleContentItem}"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>
            <content-list
                    ref="contentList"
                    v-bind:items="contentItems"
                    v-bind:mobile-mode="mobileMode"
                    v-bind:active-item="contentItem"
                    v-on:select-item="openContentItem"
                    v-on:load-more="contentListLoadMore"
            ></content-list>
        </custom-scroll>

        <custom-scroll
                v-if="visibleContentItem"
                class="content__col content__col_right"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>
            <content-detail v-if="visibleContentItem"
                            v-on:content-close="closeContentItem"
                            v-on:content-update="updateContentItem"
                            v-bind:item="contentItem"
                            v-bind:mobile-mode="mobileMode"
            ></content-detail>
        </custom-scroll>
    </layout>
</template>
<script>
    import {mapActions} from 'vuex'
    import API from './../api'
    import PageMixin from "./../mixins/page"
    import ContentListMixin from "./../mixins/content-list"
    import LoaderMixin from "./../mixins/loader"
    import HeaderLine from "./../components/tag/header-line.vue"
    import ContentList from "./../components/content/content-list.vue"
    import ContentDetail from "./../components/content/content-detail.vue"

    export default {
        mixins: [PageMixin, ContentListMixin, LoaderMixin],
        components: {
            HeaderLine,
            ContentList,
            ContentDetail
        },
        computed: {
            visibleContentList () {
                return !!this.tag && (!this.mobileMode || (!this.visibleTagDetail && !this.visibleContentItem));
            },
            visibleContentItem () {
                return !!this.contentItem
            }
        },
        data() {
            return {
                tag: null,
                showNews: false
            }
        },
        beforeRouteUpdate (to, from, next) {
            if (to.name == 'tag') {
                this.contentItem = null;
                if (to.params.tag != from.params.tag) {
                    this.contentItems = [];
                    this.tagItemLoad({slug: to.params.tag});
                }
            }
            next();
        },
        methods: {
            ...mapActions('portfolioData', [
                'setTableItems',
            ]),
            pageReady() {
                this.tagItemLoad({slug: this.$router.currentRoute.params.tag});
                if (this.$router.currentRoute.name == 'tag_content_item') {
                    this.contentItemLoad({slug: this.$router.currentRoute.params.slug});
                }
            },
            tagItemLoad(params) {
                this.contentListLoading = true;
                this.loadingStart();
                API.tag.item(params).then((response) => {
                    // set tag
                    this.tag = response.data.tag;

                    // set tag news
                    if (response.data.items.length > 0) {
                        this.contentItems = this.contentItems.concat(response.data.items);
                        this.contentLoadMoreLoaded();
                    } else {
                        this.contentLoadMoreComplete();
                    }
                }).catch(() => {
                    this.contentLoadMoreComplete();
                }).finally(() => {
                    this.loadingStop();
                    this.contentListLoading = false;
                });
            },
            getContentListRequestParams() {
                return {
                    tags: this.tag ? [this.tag.id] : []
                }
            },
            closeTag() {
                this.contentItem = null;
                if (this.routeFrom != null && this.routeFrom.name == 'home') {
                    this.$router.go(-1);
                } else {
                    this.$router.replace({name: 'home'});
                }
            },
            openContentItem(item) {
                this.contentItem = item;
                let to = {
                    name: "tag_content_item",
                    params: {
                        tag: this.tag.slug,
                        slug: this.contentItem.slug
                    }
                };
                if (this.$router.currentRoute.name == 'tag_content_item') {
                    this.$router.replace(to);
                } else {
                    this.$router.push(to);
                }
            },
            closeContentItem() {
                this.contentItem = null;
                if (this.routeFrom != null && this.routeFrom.name == 'tag') {
                    this.$router.go(-1);
                } else {
                    this.$router.replace({
                        name: 'tag',
                        params: {tag: this.tag ? this.tag.slug : null}
                    });
                }
            },
            openTagNews() {
                this.showNews = true;
            },
        }
    }
</script>