    import Vue from "vue"
import Router from "vue-router"
import routes from "./routes"

Vue.use(Router);

const router = new Router({
    routes,
    mode: "history",
    scrollBehavior (to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    }
});

router.afterEach(( to ) => {
    if (typeof window.gtag === "function") {
        window.gtag('config', window.GoogleAnalyticsTrackingId, { page_path: to.fullPath })
    }
});

export default router;