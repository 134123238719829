<template>
    <div class="coins">
        <currency-table-filter></currency-table-filter>
        <custom-scroll class="table__scroll"
            v-bind:scroll-horizontal="mobileMode"
            v-bind:mobile-mode="mobileMode">
            <table class="table">
                <currency-table-head></currency-table-head>
                <currency-table-item
                        v-for="(currency, index) in currencies"
                        v-bind:key="index"
                        v-bind:currency="currency"
                        v-bind:active-currency="activeCurrency"
                        v-on:select-currency="selectCurrency(currency)"
                ></currency-table-item>
            </table>
            <load-more ref="loadMore"
                       v-bind:mobile-mode="mobileMode"
                       v-on:load-more="loadMoreHandler"></load-more>
        </custom-scroll>
    </div>
</template>
<script>
    import LoadMoreMixin from './../../mixins/load-more';
    import CurrencyTableStoreMixin from "./../../mixins/currency-table-store"
    import CurrencyTableFilter from "./currency-table-filter.vue"
    import CurrencyTableHead from "./currency-table-head.vue"
    import CurrencyTableItem from "./currency-table-item.vue"
    import LoadMore from './../load-more.vue';

    export default {
        name: "currency-table",
        mixins: [CurrencyTableStoreMixin, LoadMoreMixin],
        components: {
            CurrencyTableFilter,
            CurrencyTableHead,
            CurrencyTableItem,
            LoadMore,
        },
        props: {
            mobileMode: {
                type: Boolean,
                required: true
            },
            activeCurrency: {
                required: true
            },
            currencies: {
                type: Array,
                required: true
            }
        },
        methods: {
            selectCurrency (currency) {
                this.$emit('select-currency', currency);
            }
        }
    }
</script>