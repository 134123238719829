<template>
    <tr class="table__nowrap">
        <th align="center">
            <star-icon v-bind:active="currencyTableShowFavorite == 1"
                       v-on:click.prevent.stop="currencyTableToggleShowFavorite"></star-icon>
        </th>
        <th>{{ $t('currency.TableColumnCoin') }}</th>
        <currency-table-head-sortable-item column-name="price">{{ $t('currency.TableColumnPrice') }}</currency-table-head-sortable-item>
        <currency-table-head-sortable-item column-name="price_btc">{{ $t('currency.TableColumnPriceBTC') }}</currency-table-head-sortable-item>
        <currency-table-head-sortable-item column-name="change_24h">{{ $t('currency.TableColumnChange24h') }}</currency-table-head-sortable-item>
        <currency-table-head-sortable-item column-name="market_cap">{{ $t('currency.TableColumnMarketCap') }}</currency-table-head-sortable-item>
        <currency-table-head-sortable-item column-name="volume_24h">{{ $t('currency.TableColumnVolume24h') }}</currency-table-head-sortable-item>
    </tr>
</template>
<script>
    import CurrencyTableStoreMixin from "./../../mixins/currency-table-store"
    import CurrencyTableHeadSortableItem from "./currency-table-head-sortable-item.vue"
    import StarIcon from './../../components/icons/star.vue'
    
    export default {
        name: "currency-table-head",
        mixins: [CurrencyTableStoreMixin],
        components: {
            CurrencyTableHeadSortableItem,
            StarIcon
        },
    }
</script>