<template>
    <div class="field-set-social__list">
        <icon-oauth v-for="(provider, index) in accountProviders"
               v-bind:key="index"
               v-bind:icon="provider.type"
               v-bind:link="provider.link"></icon-oauth>
    </div>
</template>
<script>
    import IconOauth from './../icons/oauth.vue'
    import accountProviders from "./../../store/directory/account-providers"

    export default {
        name: 'social-login-block',
        components: {
            IconOauth
        },
        data() {
            return {
                accountProviders: accountProviders
            }
        },
    }
</script>