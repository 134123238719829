const items = [
    {value: 1, name: 'global.Russian'},
    {value: 2, name: 'global.English'},
    {value: 3, name: 'global.Chinese'}
];

export default {
    all() {
        return items;
    }
}