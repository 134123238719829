<template>
    <div class="header-line">
        <div class="header-line__col">
            <div class="header-line-active-currency">{{ $t('global.Tag') }}: {{ tag.name }}
                <div class="header-line-active-currency__close" v-on:click.prevent="closeTag"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'header-line',
        props: {
            tag: {
                type: Object,
                required: true
            },
        },
        methods: {
            closeTag () {
                this.$emit('close-tag');
            }
        }
    }
</script>
<style>
    .header-line-active-currency {
        margin-left: 0;
    }
</style>