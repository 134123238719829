<template>
    <div class="header-line">
        <div class="header-line__col">
            <div class="header-line-menu">
                <router-link v-for="(item, index) in menu"
                             v-bind:key="index"
                             v-bind:to="item.to"
                             v-bind:active-class="$style.active"
                             class="header-line-menu__item"
                             exact>{{ $t(item.title) }}
                </router-link>
            </div>
        </div>
        <div class="header-line__col">
            <portfolio class="purse"></portfolio>
        </div>
    </div>
</template>
<script>
    import Portfolio from './../portfolio.vue'

    export default {
        name: 'header-line',
        components: {
            Portfolio
        },
        data () {
            return {
                menu: [
                    {to: {"name": "account"}, title: "account.MenuSettings"},
                    {to: {"name": "account_feed"}, title: "account.MenuMyFeed"},
                    {to: {"name": "account_portfolio"}, title: "account.MenuPortfolio"},
                ]
            }
        }
    }
</script>
<style module>
    .active {
        color: #f3ba2d;
        text-decoration: none;
    }
</style>