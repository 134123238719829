import ViewAuth from "./../../views/auth.vue";
import ViewAuthVerify from "./../../views/auth-verify.vue";
import ViewAuthChangePassword from "./../../views/auth-change-password.vue";

export default [
    {
        path: '/auth',
        name: 'auth',
        component: ViewAuth,
    },
    {
        path: '/auth/verify/:result',
        name: 'auth_verify_result',
        component: ViewAuthVerify
    },
    {
        path: '/auth/change-password/:token',
        name: 'auth_change_password',
        component: ViewAuthChangePassword
    },
    {
        path: '/api/auth/login',
        name: 'api_auth_login',
    },
    {
        path: '/api/auth/logout',
        name: 'api_auth_logout',
    },
    {
        path: '/api/auth/signup',
        name: 'api_auth_signup',
    },
    {
        path: '/api/auth/restore',
        name: 'api_auth_restore',
    },
    {
        path: '/api/auth/change-password',
        name: 'api_auth_change_password'
    }
];