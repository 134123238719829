<template>
    <div class="chart-container"
         style="height: 400px; min-width: 310px;"></div>
</template>
<script>
    /* global Highcharts */

    import API from './../../api'

    const KEY_TIMESTAMP = 0;
    const KEY_PRICE_USD = 1;
    const KEY_PRICE_BTC = 2;
    const KEY_VOLUME_USD = 3;

    export default {
        name: 'currency-detail-chart',
        props: {
            item: {
                type: Object,
                require: true,
            }
        },
        watch: {
            item: function () {
                this.loadAll();
            }
        },
        data() {
            return {
                chart: null
            }
        },
        created() {
            this.loadAll();
        },
        destroyed() {
            this.chart && this.chart.destroy();
            this.chart = null;
        },
        methods: {
            refreshChart(rawData) {
                this.chart && this.chart.destroy();
                this.chart = null;
                this.createChart(rawData)
            },
            loadAll() {
                API.currency.chartDataAll({
                    currency: this.item.slug
                }).then((response) => {
                    let rawData = this.parseJsonData(response.data);
                    this.refreshChart(rawData)
                })
            },
            loadByRange(firstSelected, lastSelected) {
                this.progressShow();
                API.currency.chartData({
                    currency: this.item.slug,
                    from: firstSelected,
                    to: lastSelected,
                }).then((response) => {
                    let rawData = this.parseJsonData(response.data);
                    this.updateChart(rawData);
                }).finally(() => {
                    this.progressHide();
                });
            },
            parseJsonData(jsonData) {
                let data = [];
                if ('object' == typeof jsonData && jsonData.price_usd.length) {
                    for (let i = 0; i < jsonData.price_usd.length; i++) {
                        data.push([
                            jsonData.price_usd[i][0],
                            jsonData.price_usd[i][1],
                            jsonData.price_btc[i][1],
                            jsonData.volume_usd[i][1],
                        ]);
                    }
                }
                return data;
            },
            createChart(rawData) {
                let self = this;
                if (this.chart == null) {
                    let seriesOptions = this.prepareSeriesOptions(rawData);

                    Highcharts.setOptions({
                        time: {
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                        lang: {
                            months: [
                                this.$t('months.January'),
                                this.$t('months.February'),
                                this.$t('months.March'),
                                this.$t('months.April'),
                                this.$t('months.May'),
                                this.$t('months.June'),
                                this.$t('months.July'),
                                this.$t('months.August'),
                                this.$t('months.September'),
                                this.$t('months.October'),
                                this.$t('months.November'),
                                this.$t('months.December')
                            ],
                            shortMonths: [
                                this.$t('shortMonths.Jan'),
                                this.$t('shortMonths.Feb'),
                                this.$t('shortMonths.Mar'),
                                this.$t('shortMonths.Apr'),
                                this.$t('shortMonths.May'),
                                this.$t('shortMonths.Jun'),
                                this.$t('shortMonths.Jul'),
                                this.$t('shortMonths.Aug'),
                                this.$t('shortMonths.Sep'),
                                this.$t('shortMonths.Oct'),
                                this.$t('shortMonths.Nov'),
                                this.$t('shortMonths.Dec')
                            ],
                            weekdays: [
                                this.$t('weekdays.Sunday'),
                                this.$t('weekdays.Monday'),
                                this.$t('weekdays.Tuesday'),
                                this.$t('weekdays.Wednesday'),
                                this.$t('weekdays.Thursday'),
                                this.$t('weekdays.Friday'),
                                this.$t('weekdays.Saturday'),
                            ],
                            shortWeekdays: [
                                this.$t('shortWeekdays.Sunday'),
                                this.$t('shortWeekdays.Monday'),
                                this.$t('shortWeekdays.Tuesday'),
                                this.$t('shortWeekdays.Wednesday'),
                                this.$t('shortWeekdays.Thursday'),
                                this.$t('shortWeekdays.Friday'),
                                this.$t('shortWeekdays.Saturday'),
                            ],
                            rangeSelectorZoom: ''
                        }
                    });

                    this.chart = Highcharts.stockChart(this.$el, {
                        series: seriesOptions,
                        tooltip: {
                            shared: true,
                            split: false,
                            crosshairs: true,
                            shadow: false,
                            padding: 5,
                            borderWidth: 0,
                            borderRadius: 0,
                            snap: "1/2"
                        },
                        scrollbar: {
                            enabled: false
                        },
                        rangeSelector: {
                            enabled: true,
                            verticalAlign: "top",
                            y: 12,
                            x: -20,
                            buttonPosition: {
                                align: "right"
                            },
                            buttons: [{
                                type: "day",
                                count: 1,
                                text: '1' + this.$t('ranges.day')
                            }, {
                                type: "day",
                                count: 7,
                                text: '7' + this.$t('ranges.day')
                            }, {
                                type: "month",
                                count: 1,
                                text: '1' + this.$t('ranges.month')
                            }, {
                                type: "month",
                                count: 3,
                                text: '3' + this.$t('ranges.month')
                            }, {
                                type: "month",
                                count: 6,
                                text: '6' + this.$t('ranges.month')
                            }, {
                                type: "year",
                                count: 1,
                                text: '1' + this.$t('ranges.year')
                            }, {
                                type: "all",
                                text: this.$t('ranges.all')
                            }],
                            inputEnabled: false,
                            selected: 3
                        },
                        navigator: {
                            adaptToUpdatedData: false,
                        },
                        plotOptions: {
                            series: {
                                animation: false,
                                threshold: null,
                                lineOpacity: .2,
                                fillOpacity: 1,
                                lineWidth: 1.5,
                                states: {
                                    hover: {
                                        enabled: false
                                    }
                                },
                                dataGrouping: {
                                    enabled: false
                                }
                            },
                            area: {
                                boostThreshold: 0,
                                turboThreshold: 0,
                                getExtremesFromAll: true
                            },
                            column: {
                                grouping: false
                            }
                        },
                        xAxis: {
                            gridLineWidth: 1,
                            events: {
                                afterSetExtremes (e) {
                                    let from = Math.round(e.min);
                                    let to = Math.round(e.max);
                                    self.loadByRange(from, to);
                                }
                            },
                            minRange: 864e5
                        },
                        yAxis: [
                            {
                                reversedStacks: false,
                                floor: 0,
                                maxPadding: 0,
                                labels: {
                                    align: "right",
                                    x: -5
                                }
                            },
                            {
                                floor: 0,
                                maxPadding: 0,
                                opposite: false,
                                labels: {
                                    align: "left",
                                    x: 5
                                }
                            },
                            {
                                gridLineWidth: 0,
                                floor: 0,
                                maxPadding: 0,
                                opposite: false,
                                top: "80%",
                                height: "20%",
                                labels: {
                                    enabled: false
                                }
                            }
                        ],
                    });
                }
            },
            updateChart(rawData) {
                this.chart.series[0].setData( this.parseData(rawData, KEY_TIMESTAMP, KEY_PRICE_USD) );
                this.chart.series[1].setData( this.parseData(rawData, KEY_TIMESTAMP, KEY_PRICE_BTC) );
                this.chart.series[2].setData( this.parseData(rawData, KEY_TIMESTAMP, KEY_VOLUME_USD) );
            },
            prepareSeriesOptions(rawData) {
                return [
                    {
                        name: this.$t('global.PriceUsd'),
                        data: this.parseData(rawData, KEY_TIMESTAMP, KEY_PRICE_USD),
                        fillOpacity: 0.5
                    },
                    {
                        name: this.$t('global.PriceBtc'),
                        data: this.parseData(rawData, KEY_TIMESTAMP, KEY_PRICE_BTC),
                        lineWidth: 1,
                        yAxis: 1,
                        fillOpacity: 0,
                    },
                    {
                        name: this.$t('global.VolumeUsd24h'),
                        data: this.parseData(rawData, KEY_TIMESTAMP, KEY_VOLUME_USD),
                        type: "area",
                        step: true,
                        yAxis: 2,
                        lineWidth: 0,
                        grouping: false,
                        showEmpty: false,
                        offset: 2,
                    },
                ];
            },
            parseData(rawData, timeCol, valCol) {
                let data = [];
                for (let i = 0; i < rawData.length; i++) {
                    data.push([
                        rawData[i][timeCol],
                        rawData[i][valCol]
                    ]);
                }
                return data;
            },
            progressShow() {
                if (this.chart) {
                    this.chart.showLoading(this.$t('global.LoadingData'));
                }
            },
            progressHide() {
                if (this.chart) {
                    this.chart.hideLoading();
                }
            }
        }
    }
</script>