<template>
    <div>
        <div v-if="isLoading" class="loading-view">
            <vue-simple-spinner class="loading-view-spinner"></vue-simple-spinner>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex'
    import VueSimpleSpinner from "vue-simple-spinner"

    export default {
        name: 'loader-view',
        components: {
            VueSimpleSpinner
        },
        computed: {
            ...mapGetters('page', ['isLoading'])
        }
    }
</script>
<style>
    .loading-view {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
        display: block;
        opacity: 0.7;
        background-color: #fff;
        z-index: 99;
        text-align: center;
    }

    .loading-view-spinner {
        position: absolute;
        top: 100px;
        z-index: 100;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
</style>