<template>
    <div class="currency-widget-item" v-bind:data-active="active" v-bind:data-star="favorite">
        <div class="currency-widget-item__row">
            <div class="currency-widget-item__name">{{ tag.name }}</div>
        </div>
        <router-link class="currency-widget-item__link" v-bind:to="{name: 'tag', params: {tag: tag.slug}}"></router-link>
        <star-small-icon v-bind:active="favorite" v-on:click="toggleTagFavorite(tag.id)"></star-small-icon>
    </div>
</template>
<script>
    import StarSmallIcon from './../icons/star-small.vue'

    export default {
        name: 'tag-item',
        components: {
            StarSmallIcon
        },
        props: {
            tag: {
                type: Object,
                required: true
            },
            favorite: {
                type: Boolean,
                required: true
            },
            active: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            toggleTagFavorite (tagId) {
                this.$emit('toggle-tag-favorite', tagId);
            }
        }
    }
</script>