<template>
    <layout v-bind:tag="tagItem"
            v-bind:mobile-mode="mobileMode"
            v-bind:sidebar-visible="false">

        <custom-scroll
                v-if="visibleTagTable || visibleContentDetail"
                ref="leftCustomScroll"
                class="content__col content__col_left"
                v-bind:mobile-mode="mobileMode"
                scroll-vertical
                fix-scroll>

            <tag-table
                    v-show="visibleTagTable"
                    ref="tagTable"
                    v-bind:mobile-mode="mobileMode"
                    v-bind:active-tag="tagItem"
                    v-bind:tags="tagItems"
                    v-on:select-tag="openPageTag"
                    v-on:load-more="tagTableLoadMore"
            ></tag-table>

            <content-detail v-if="visibleContentDetail"
                            v-on:content-close="closePageContent"
                            v-on:content-update="updateContentItem"
                            v-on:scroll="onScrollContent"
                            v-bind:item="contentItem"
                            v-bind:mobile-mode="mobileMode"
            ></content-detail>
        </custom-scroll>
        <custom-scroll v-if="visibleTagDetail"
                       v-bind:mobile-mode="mobileMode"
                       class="content__col content__col_right"
                       scroll-vertical
                       fix-scroll>
            <tag-detail
                    v-on:close-tag="closePageTag"
                    v-bind:item="tagItem"
                    v-bind:mobile-mode="mobileMode"
                    v-bind:has-close-button="true">
                <template slot="news">
                    <content-list
                            ref="contentList"
                            v-bind:items="contentItems"
                            v-bind:active-item="contentItem"
                            v-bind:mobile-mode="mobileMode"
                            v-on:select-item="openPageContent"
                            v-on:load-more="contentListLoadMore">
                    </content-list>
                </template>
            </tag-detail>
        </custom-scroll>

    </layout>
</template>
<script>
    const PAGINATOR_PERPAGE = 40;

    import API from './../api'
    import {mapActions} from 'vuex'
    import loDebounce from 'lodash/debounce'
    import PageMixin from "./../mixins/page"
    import NotifyMixin from "./../mixins/notify"
    import LoaderMixin from "./../mixins/loader"
    import ContentListMixin from "./../mixins/content-list"
    import TagTableStoreMixin from './../mixins/tag-table-store'
    import TagTable from "./../components/tag-table/tag-table.vue"
    import TagDetail from "./../components/tag/tag-detail.vue"
    import ContentList from "./../components/content/content-list.vue"
    import ContentDetail from "./../components/content/content-detail.vue"

    export default {
        name: "tags",
        mixins: [PageMixin, NotifyMixin, LoaderMixin, ContentListMixin, TagTableStoreMixin],
        components: {
            TagTable,
            TagDetail,
            ContentDetail,
            ContentList
        },
        data () {
            return {
                tagItems: [],
                tagItem: null,
                tagTableOffset: 0,
                tagTableLoading: false,
            }
        },
        computed: {
            visibleContentDetail () {
                return !this.bootstrap && !!this.contentItem;
            },
            visibleTagDetail () {
                return this.mobileMode
                    ? !this.bootstrap && !this.visibleContentDetail && !!this.tagItem
                    : !this.bootstrap && !!this.tagItem;
            },
            visibleTagTable () {
                return this.mobileMode
                    ? !this.bootstrap && !this.visibleTagDetail && !this.visibleContentDetail
                    : !this.bootstrap && !this.visibleContentDetail;
            }
        },
        watch: {
            mobileMode: function () {
                this.tagTableLoadMoreForceReset();
            },
            tagTableQuery () {
                if (this.tagTableLoading) {
                    return;
                }
                this.onChangeTagFilter();
            },
            tagTableOrder () {
                if (this.tagTableLoading) {
                    return;
                }
                this.onChangeTagFilter();
            },
            tagTableDesk () {
                if (this.tagTableLoading) {
                    return;
                }
                this.onChangeTagFilter();
            },
            tagTableShowFavorite () {
                if (this.tagTableLoading) {
                    return;
                }
                this.onChangeTagFilter();
            },
        },
        beforeRouteUpdate (to, from, next) {
            if (to.name == 'tags') {
                this.contentItem = null;
                if (this.mobileMode) {
                    this.tagItem = null;
                }
            } else if (to.name == 'tags_item') {
                this.contentItem = null;
            }
            next();
        },
        methods: {
            ...mapActions('portfolioData', [
                'setTableItems'
            ]),
            pageReady() {
                switch (this.$router.currentRoute.name) {
                    case 'tags':
                        this.tagCommonLoad("", "");
                        break;
                    case 'tags_item':
                        this.tagCommonLoad(this.$router.currentRoute.params.tag);
                        break;
                    case 'tags_content_item':
                        this.tagCommonLoad(
                            this.$router.currentRoute.params.tag,
                            this.$router.currentRoute.params.slug);
                        break;
                }
            },
            openPageTag(tag) {
                this.tagItem = tag;

                // set history
                let to = {name: 'tags_item', params: {"tag": this.tagItem.slug}};
                if (this.$router.currentRoute.name == 'tags_item') {
                    this.$router.replace(to);
                } else {
                    this.$router.push(to);
                }

                // load tag content list
                this.contentItems = [];
                this.contentListLoad();
            },
            closePageTag() {
                this.tagItem = null;
                // set history
                if (this.routeFrom != null && this.routeFrom.name == 'tags') {
                    this.$router.go(-1)
                } else {
                    this.$router.replace({name: 'tags'});
                }
            },
            openPageContent(content) {
                this.contentItem = content;
                // set history
                let to = {
                    name: "tags_content_item",
                    params: {tag: this.tagItem.slug, slug: this.contentItem.slug}
                };
                if (this.$router.currentRoute.name == 'tags_content_item') {
                    this.$router.replace(to)
                } else {
                    this.$router.push(to);
                }
            },
            closePageContent() {
                this.contentItem = null;
                // set history
                if (this.routeFrom != null && this.routeFrom.name == 'tags_item') {
                    this.$router.go(-1)
                } else {
                    this.$router.replace({name: 'tags_item', params: {"tag": this.tagItem.slug}});
                }
            },
            getTagTableRequestParams() {
                return {
                    query: this.tagTableQuery,
                    order: this.tagTableOrder,
                    desk: Number(this.tagTableDesk),
                    favorites: Number(this.tagTableShowFavorite),
                    offset: this.tagTableOffset,
                }
            },
            tagCommonLoad(tagSlug, contentSlug) {
                this.pageBootstrap();
                if (this.tagTableLoading) {
                    return;
                }
                this.tagTableLoading = true;
                this.tagTableOffset = 0;
                this.loadingStart();

                let params = this.getTagTableRequestParams();
                params['tag_slug'] = tagSlug;
                params['content_slug'] = contentSlug;

                API.tag.listCommon(params).then((response) => {
                    // set tag items
                    this.tagItems = response.data.items;

                    // set selected content
                    if (response.data.content) {
                        this.contentItem = response.data.content;
                    }

                    // set selected tag
                    if (response.data.tag) {
                        this.tagItem = response.data.tag;
                    }

                    // set content items of selected tag
                    if (response.data.tag_content) {
                        this.contentItems = response.data.tag_content;
                    }

                    // set portfolio of selected tag
                    if (response.data.portfolioTable) {
                        this.setTableItems(response.data.portfolioTable);
                    }

                    // in desktop page tags select first tag and load content list
                    if (!this.mobileMode && !this.tagItem && !!this.tagItems[0]) {
                        this.tagItem = this.tagItems[0];
                        this.contentItems = [];
                        this.contentListLoad();
                    }
                }).finally(() => {
                    this.tagTableLoading = false;
                    this.loadingStop();
                    this.pageBootstrapped();
                    this.$nextTick(() => {
                        this.tagTableLoadMoreLoaded();
                        if (this.tagItems.length < PAGINATOR_PERPAGE) {
                            this.tagTableLoadMoreComplete();
                        }
                    });
                });
            },
            tagTableLoad(offset) {
                if (this.tagTableLoading) {
                    return;
                }
                this.tagTableLoading = true;
                this.tagTableOffset = offset;

                let params = this.getTagTableRequestParams();
                API.tag.list(params).then((response) => {
                    if (offset > 0) {
                        // append new items
                        this.tagItems = this.tagItems.concat(response.data.items);
                    } else {
                        // set new items
                        this.tagItems = response.data.items;
                        this.tagTableLoadMoreReset();
                    }

                    // set loadMore plugin params
                    this.tagTableLoadMoreLoaded();
                    if (response.data.items.length < PAGINATOR_PERPAGE) {
                        this.tagTableLoadMoreComplete();
                    }
                }).catch(() => {
                    this.tagTableLoadMoreComplete();
                }).finally(() => {
                    this.tagTableLoading = false;
                });
            },
            tagTableLoadMore() {
                let offset = this.tagTableOffset + PAGINATOR_PERPAGE;
                this.tagTableLoad(offset);
            },
            tagTableLoadMoreComplete() {
                if (this.$refs.tagTable) {
                    this.$refs.tagTable.loadMoreComplete();
                }
            },
            tagTableLoadMoreLoaded() {
                if (this.$refs.tagTable) {
                    this.$refs.tagTable.loadMoreLoaded();
                }
            },
            tagTableLoadMoreReset() {
                if (this.$refs.tagTable) {
                    this.$refs.tagTable.loadMoreReset();
                }
            },
            tagTableLoadMoreForceReset() {
                if (this.$refs.tagTable) {
                    this.$refs.tagTable.loadMoreForceReset();
                }
            },
            getContentListRequestParams() {
                return {tags: this.tagItem ? [this.tagItem.id] : []};
            },
            onChangeTagFilter: loDebounce(function () {
                this.tagTableLoad(0);
            }, 300),
            onScrollContent (delta) {
                if (this.$refs.leftCustomScroll) {
                    this.$refs.leftCustomScroll.moveScroll(delta);
                }
            }
        }
    }
</script>