export default function () {
    this.bot = {
        data: ({id}) => {
            return this.post({
                to: {name: 'api_bot_data'},
                payload: {id: id}
            });
        },
        dataTelegram: () => {
            return this.post({
                to: {name: 'api_bot_data_telegram'},
                payload: {}
            });
        },
        createTelegram: ({webhook_url}) => {
            return this.post({
                to: {name: 'api_bot_create_telegram'},
                payload: {
                    webhook_url: webhook_url,
                }
            });
        },
        updateBot: ({id, webhook_url, enabled}) => {
            return this.post({
                to: {name: 'api_bot_update'},
                payload: {
                    id: id,
                    webhook_url: webhook_url,
                    enabled: enabled
                }
            });
        }
    }
}