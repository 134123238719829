import "./assets/scss/app.scss"
import Init from "./init.js";

Init
    .configureVue()
    .initVars()
    .initTranslate()
    .addRouter()
    .addVueFilters()
    .addVueSnotify()
    .addVueildate()
    .registerGlobalComponents()
    .addSentry()
    .run();