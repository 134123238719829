import {AUTH_LOGIN, AUTH_LOGOUT, LOCALE_SET} from "./../mutation-types";

const state = {
    loggedIn: false,
};

const getters = {
};

const mutations = {
    [AUTH_LOGIN] (state) {
        state.loggedIn = true;
    },
    [AUTH_LOGOUT] (state) {
        state.loggedIn = false;
    }
};

const actions = {
    logIn (store) {
        store.commit(AUTH_LOGIN);
    },
    logOut (store) {
        store.commit(AUTH_LOGOUT);
    },
    setUserData (store, payload) {
        store.commit("locale/" + LOCALE_SET, payload.locale_id, {root: true});
    },
    clear (store) {
        store.dispatch('logOut');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};