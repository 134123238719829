<template>
    <layout v-bind:mobile-mode="mobileMode">
        <custom-scroll class="content__col content__col_wide"
            v-bind:mobile-mode="mobileMode"
            scroll-vertical>
            <form-change-password v-bind:token="token"></form-change-password>
        </custom-scroll>
    </layout>
</template>
<script>
    import PageMixin from "./../mixins/page"
    import FormChangePassword from "./../components/auth/form-change-password.vue"

    export default {
        mixins: [PageMixin],
        components: {
            FormChangePassword
        },
        data () {
            return {
                token: ''
            }
        },
        methods: {
            pageReady () {
                this.token = this.$router.currentRoute.params.token;
            }
        }
    }
</script>